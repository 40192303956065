import { useEffect, useState } from 'react'

import { humanizeFieldName } from '@utils/string-utils'

import { DataPill, getIconForDataType } from '@components/component-management/DataPill/DataPill'
import { Separator } from '@components/core/separator'
import { Text } from '@components/core/text'
import { Icon } from '@components/icons'

import { findPreviousPeriodHeader } from '@pages/component-management/utils/header-utils'

import { useQueryResultContext } from '../../contexts/query-result-context'
import { IHeader } from '../../types/component-types'
import { OverflowMenu } from '../overflow-menu'
import { ParameterizedFieldConfig } from './parameterized-field-config'
import { PreviousPeriodConnection } from './previous-period-connection'

export function NewHeaderSection() {
  const { headers, isFetching } = useQueryResultContext()
  const [newHeaders, setNewHeaders] = useState<IHeader[]>([])

  useEffect(() => {
    if (isFetching) return

    setNewHeaders(_.filter(headers, { new_header: true }))
  }, [headers, isFetching])

  if (!newHeaders.length) return null

  return (
    <div className='flex flex-col gap-2'>
      {_.reject(newHeaders, { previous_period: true }).map((header) => {
        const previousPeriodHeader = findPreviousPeriodHeader(header.name, newHeaders)

        return (
          <div key={header.name}>
            <div className='flex items-center justify-between'>
              <DataPill label={humanizeFieldName(header.name)} dataType={header.data_type} />
              <OverflowMenu>
                <>
                  <div className='flex items-center gap-1'>
                    <Icon icon={getIconForDataType(header.data_type)} />
                    <Text variant='submenu' weight='semibold' className='text-blue-dark'>
                      {humanizeFieldName(header.name)}
                    </Text>
                  </div>
                  <Separator className='my-2' />

                  {/* Parameterize Field */}
                  <ParameterizedFieldConfig headerName={header.name} />
                  <Separator className='mt-2' />
                </>
              </OverflowMenu>
            </div>
            {previousPeriodHeader && (
              <PreviousPeriodConnection>
                <div className='ml-5 flex items-center justify-between'>
                  <DataPill
                    label={humanizeFieldName(previousPeriodHeader.name)}
                    dataType={previousPeriodHeader.data_type}
                  />
                  <OverflowMenu>
                    <>
                      <div className='flex items-center gap-1'>
                        <Icon icon={getIconForDataType(header.data_type)} />
                        <Text variant='submenu' weight='semibold' className='text-blue-dark'>
                          {humanizeFieldName(header.name)}
                        </Text>
                      </div>
                      <Separator className='my-2' />

                      {/* Parameterize Field */}
                      <ParameterizedFieldConfig headerName={previousPeriodHeader.name} />
                      <Separator className='mt-2' />
                    </>
                  </OverflowMenu>
                </div>
              </PreviousPeriodConnection>
            )}
          </div>
        )
      })}
    </div>
  )
}
