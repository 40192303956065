import { useMemo } from 'react'

import { getDisplayName } from '@utils/schema-renaming'
import { humanizeFieldName } from '@utils/string-utils'

import { Separator } from '@components/core/separator'
import { Skeleton } from '@components/core/skeleton'
import { Text } from '@components/core/text'

import { useSelector } from '@store/index'
import { selectPending } from '@store/slices/component/basic-config'
import { selectCurrentSelectedModel } from '@store/slices/component/query-config'

import { useFetchModelsDetail } from '@pages/component-management/queries/fetch-models-detail'
import { useFetchRenamings } from '@pages/configuration/queries/fetch-renamings'

import { PanelSkeleton } from '../panel-skeleton'
import { FormulaSection } from './formula-section'
import { ModelSection } from './model-section'
import { NewHeaderSection } from './new-header-section'

export function PreviewPanelBody() {
  const currentSelectedModel = useSelector(selectCurrentSelectedModel()) || ''
  const { data, isLoading } = useFetchModelsDetail(currentSelectedModel)
  const isPendingComponentData = useSelector(selectPending)

  const columns = useMemo(() => data?.columns || [], [data])
  const associations = useMemo(() => data?.associations || [], [data])

  const { data: renamingsData } = useFetchRenamings(true)
  const renamings = renamingsData || {}

  const renamedModel = getDisplayName(renamings, currentSelectedModel, undefined, false)

  const loading = isLoading || isPendingComponentData

  return (
    <div>
      {!loading && <FormulaSection />}
      {isPendingComponentData ? (
        <Skeleton className='h-5 w-48' />
      ) : (
        <Text variant='button' weight='semibold'>
          {humanizeFieldName(renamedModel || currentSelectedModel)}
        </Text>
      )}
      <Separator className='my-2' />
      {loading ? (
        <PanelSkeleton />
      ) : (
        <div className='flex flex-col gap-2'>
          <NewHeaderSection />
          <ModelSection
            modelName={currentSelectedModel}
            columns={columns}
            associations={associations}
          />
        </div>
      )}
    </div>
  )
}
