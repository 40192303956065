import { CSSProperties } from 'react'

import { useTheme } from '@mui/material'

import {
  Announcements,
  MeasuringStrategy,
  SensorDescriptor,
  SensorOptions,
  type UniqueIdentifier,
  closestCenter,
  useDroppable
} from '@dnd-kit/core'
import { AnimateLayoutChanges, useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'

import { TreeItemIdentifier } from '@components/tree/types'

import { iOS } from '../../utilities'
import { TreeItem, Props as TreeItemProps } from './tree-item'

interface Props extends TreeItemProps {
  id: UniqueIdentifier
  identifier?: TreeItemIdentifier
  sensors?: SensorDescriptor<SensorOptions>[]
  announcements?: Announcements
  editOptions?: ('add' | 'edit' | 'delete')[]
}

const animateLayoutChanges: AnimateLayoutChanges = ({ isSorting, wasDragging }) =>
  !(isSorting || wasDragging)

const measuring = {
  droppable: {
    strategy: MeasuringStrategy.Always
  }
}

export function SortableTreeItem({
  id,
  selectedNodeId,
  onItemClick,
  depth,
  identifier,
  sensors,
  announcements,
  ...props
}: Props) {
  const theme = useTheme()
  const { isOver, setNodeRef } = useDroppable({ id: id, data: { identifier: identifier } })

  const {
    attributes,
    isDragging,
    isSorting,
    listeners,
    setDraggableNodeRef,
    setDroppableNodeRef,
    transform,
    transition
  } = useSortable({
    id,
    data: {
      type: 'sort',
      sensors,
      measuring,
      announcements,
      collisionDetection: closestCenter
    },
    animateLayoutChanges
  })

  const style: CSSProperties = {
    transform: CSS.Translate.toString(transform),
    border: isOver ? '3px dashed green' : '',
    transition
  }

  if (selectedNodeId) {
    style.cursor = 'pointer'
    style.background = id === selectedNodeId ? theme.palette.primary.lighter : ''
  }

  return (
    <div ref={setNodeRef}>
      <TreeItem
        id={id}
        onItemClick={onItemClick}
        ref={setDraggableNodeRef}
        wrapperRef={setDroppableNodeRef}
        style={style}
        depth={depth}
        ghost={isDragging}
        identifier={identifier}
        disableSelection={iOS}
        disableInteraction={isSorting}
        handleProps={{
          ...attributes,
          ...listeners
        }}
        {...props}
      />
    </div>
  )
}
