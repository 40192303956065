import React, { useMemo, useRef } from 'react'
import { useSelector } from 'react-redux'

import { ColDef } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'

import AgGrid from '@components/ag-grid'
import { applyHighlightRenderer } from '@components/ag-grid/custom-cell-renderer/highlight-cell-renderer'
import OpenInNewTabCellRenderer from '@components/ag-grid/custom-cell-renderer/open-in-new-tab-cell-renderer'

import { RootState } from '@store/index'
import { selectGridSearchTerm } from '@store/slices/grid'
import { selectUserBusinessTimeZone } from '@store/slices/user-preferences'

import { useRegisterAgGridRefEffect } from 'contexts/grid-ref-context'

import { useFetchConnectedSourceSystems } from './queries/fetch-connected-source-systems'
import { DataIntegrityCheckType, PulseStatus, getPulseStatus } from './types'
import {
  lastSyncStatusColumnDef,
  multiTextColumnDef,
  openInNewTabColumnDef,
  staticSwitchColumnDef,
  textSearchColumnDef,
  timeZoneDateColumnDef
} from './utils/col-defs'

export const ConnectedSourceSystemList = () => {
  const { isPending, data } = useFetchConnectedSourceSystems()
  const connectedSourceSystems = useMemo(() => data ?? [], [data])

  const timeZone = useSelector(selectUserBusinessTimeZone)

  const wrapperRef = useRef<HTMLDivElement>(null)
  const gridRef = useRef<AgGridReact>(null)

  const searchTerm = useSelector((state: RootState) => selectGridSearchTerm(state, undefined))

  useRegisterAgGridRefEffect(gridRef)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const columnDefs: ColDef[] = [
    openInNewTabColumnDef(),
    textSearchColumnDef('name', 'Connection Name'),
    multiTextColumnDef('sourceSystemName', 'Source System'),
    textSearchColumnDef('destinationSchema', 'Destination Schema'),
    timeZoneDateColumnDef('lastHeartbeatAt', 'Last Heartbeat Time', timeZone),
    staticSwitchColumnDef('connector.runQaChecks', 'Data Integrity Check', true, {
      maxWidth: 150,
      minWidth: 150,
      filterParams: {
        values: [DataIntegrityCheckType.TRUE, DataIntegrityCheckType.FALSE]
      },
      filterValueGetter: (params) => {
        const value = params.data.connector?.runQaChecks
        return value ? DataIntegrityCheckType.TRUE : DataIntegrityCheckType.FALSE
      },
      filter: 'agMultiColumnFilter'
    }),
    staticSwitchColumnDef('pulseSupported', 'Pulse', PulseStatus.ENABLED, {
      maxWidth: 80,
      minWidth: 80,
      filterParams: {
        values: [PulseStatus.ENABLED, PulseStatus.NOT_ENABLED]
      },
      valueGetter: (params) => getPulseStatus(params.data.pulseSupported),
      filterValueGetter: (params) => getPulseStatus(params.data.pulseSupported),
      filter: 'agMultiColumnFilter'
    }),
    timeZoneDateColumnDef('lastSyncTime', 'Last Sync Time', timeZone),
    lastSyncStatusColumnDef({
      filter: 'agMultiColumnFilter'
    })
  ]

  const finalColumnDefs = useMemo(
    () => applyHighlightRenderer(columnDefs, searchTerm, ['openInNewTab']),
    [columnDefs, searchTerm]
  )

  return (
    <div className='h-[calc(100vh-12rem)] w-full' ref={wrapperRef}>
      <AgGrid
        style={{ height: '100%' }}
        ref={gridRef}
        rowData={connectedSourceSystems}
        columnDefs={finalColumnDefs}
        autoSizeStrategy={{ type: 'fitCellContents' }}
        defaultColDef={{
          resizable: true,
          filter: 'agTextColumnFilter',
          floatingFilter: true,
          filterParams: {
            buttons: ['clear']
          }
        }}
        editType={'fullRow'}
        suppressRowClickSelection
        loading={isPending}
        suppressContextMenu={false}
        gridOptions={{
          components: {
            openInNewTabCellRenderer: OpenInNewTabCellRenderer
          }
        }}
        persistFilterState={true}
      />
    </div>
  )
}
