import { cn } from '@utils/style-utils'

import { Icon, WordWrap } from '@components/icons'

interface LineWrapToggleProps {
  isEnabled: boolean
  onToggle: () => void
}

const LineWrapToggle = ({ isEnabled, onToggle }: LineWrapToggleProps) => (
  <div
    className='inline-flex cursor-pointer border border-solid border-grey-light'
    onClick={onToggle}
  >
    <Icon icon={<WordWrap />} className={cn('size-6', isEnabled && 'text-primary')} />
  </div>
)

export default LineWrapToggle
