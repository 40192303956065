import { useMemo } from 'react'

import { ICellRendererParams } from 'ag-grid-community'

import { DEFAULT_DATE_TIME_FORMAT, TimeZoneDefault } from '@components/control-panel/types'

import { format } from 'date-fns'
import moment from 'moment-timezone'

interface DateCellRendererProps extends ICellRendererParams {
  format: string
}

export const DateCellRenderer = (props: DateCellRendererProps) => {
  const formattedDate = useMemo(() => {
    if (!props.value) return ''

    try {
      // eslint-disable-next-line no-date-parsing/no-date-parsing -- params.value contains full date and time
      const dateInUserTimezone = new Date(props.value)
      const formatString = props.format || DEFAULT_DATE_TIME_FORMAT
      return format(dateInUserTimezone, formatString)
    } catch (_e) {
      return props.value
    }
  }, [props.value, props.format])

  return <>{formattedDate}</>
}

interface TimezoneDateCellRendererProps extends DateCellRendererProps {
  format: string
  timezone?: string
}

export const TimezoneDateCellRenderer = (props: TimezoneDateCellRendererProps) => {
  const { value, format: formatString, timezone = TimeZoneDefault } = props

  const formattedDate = useMemo(() => {
    if (!value) return ''

    try {
      const dateInUserTimezone = moment.tz(value, timezone)
      const finalFormatString = formatString || DEFAULT_DATE_TIME_FORMAT
      return dateInUserTimezone.format(finalFormatString)
    } catch (_e) {
      return value
    }
  }, [value, timezone, formatString])

  return <>{formattedDate}</>
}
