import { useQuery } from '@tanstack/react-query'

import axiosServices from '@utils/axios'
import { camelCaseKeys } from '@utils/case-conversion'

import useAuth from '@hooks/useAuth'

import { QUERY_STALE_TIME_DEFAULT } from 'queries/constants'

export interface IJobSchedule {
  id: number
  name: string
  replicationJobId?: number
  awsEventbridgeScheduleArn: string
  awsEventbridgeTargetArn: string
  connectedSourceSystemId?: number
  groupName: string
  scheduleExpression: string
  scheduleExpressionTimezone: string
  state: ScheduleStateEnum
  businessTimezone: string
  scheduleType: string
  targetInput: string
  parallelism?: number
  replicationJobIdFromTargetInput?: number
}

export enum JobScheduleSelectType {
  Rust = 'rust',
  NonRust = 'non-rust'
}

export enum ScheduleStateEnum {
  Enabled = 'ENABLED',
  Disabled = 'DISABLED'
}

const fetchJobSchedules = async (
  businessId: number | undefined,
  connectedSourceSystemId?: string | undefined
): Promise<IJobSchedule[]> => {
  const endpoint = connectedSourceSystemId
    ? `/businesses/${businessId}/data_replication_schedule?connected_source_system_id=${connectedSourceSystemId}`
    : `/businesses/${businessId}/data_replication_schedule`

  const response = await axiosServices.get(endpoint)

  return camelCaseKeys(response.data) as IJobSchedule[]
}

export const useFetchJobSchedules = (connectedSourceSystemId?: string | undefined) => {
  const { user } = useAuth()

  return useQuery({
    queryKey: ['fetch-job-schedules', user?.business_id, connectedSourceSystemId],
    enabled: !!user?.business_id,
    queryFn: () => fetchJobSchedules(user?.business_id, connectedSourceSystemId),
    staleTime: QUERY_STALE_TIME_DEFAULT
  })
}
