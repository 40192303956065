import { useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router'

import { Box, Grid, MenuItem, Select, Stack, TextField, Typography } from '@mui/material'

import { ColDef, GetRowIdParams, GridReadyEvent, ICellRendererParams } from 'ag-grid-community'
import { CellValueChangedEvent } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'

import { useQueryClient } from '@tanstack/react-query'

import { useDebounce } from '@uidotdev/usehooks'
import { enumToOptions } from '@utils/obj-utils'

import { DateCellRenderer } from '@components/ag-grid/custom-cell-renderer/date-cell-renderer'

import useAuth from 'hooks/useAuth'
import { DEFAULT_COL_DEF } from 'utils/ag_grid/grid-utils'

import { useCreateComponent } from '../queries/create-component'
import { useDraftComponent } from '../queries/draft-component'
import { usePublishComponent } from '../queries/publish-component'
import { useUpdateComponent } from '../queries/update-component'
import {
  ComponentGenre,
  ComponentScope,
  ComponentSource,
  ComponentStatus
} from '../types/component-types'
import ActionCell from './action-cell'
import ServerSideDatasource from './serverside-datasource'
import { generalCellRenderer, getValues } from './value-cell-renderer'

const scopes = enumToOptions(ComponentScope)
const statuses = enumToOptions(ComponentStatus)
const genres = enumToOptions(ComponentGenre)
const sources = enumToOptions(ComponentSource)

export default function List() {
  const gridRef = useRef<AgGridReact>(null)
  const queryClient = useQueryClient()
  const [searchText, setSearchText] = useState('')
  const debouncedSearchText = useDebounce(searchText, 300)
  const { user } = useAuth()
  const businessId = user?.business_id!
  const initialFilterParams = useRef({ businessId, search: searchText })
  const { mutateAsync: updateAsync } = useUpdateComponent()
  const { mutateAsync: draftAsync } = useDraftComponent()
  const { mutateAsync: publishAsync } = usePublishComponent()
  const { mutate: createComponent, isPending } = useCreateComponent()
  const navigate = useNavigate()

  const handleActionChange = (selectedAction: string) => {
    if (selectedAction === 'register') {
      navigate('register-component')
    } else if (selectedAction === 'create') {
      onCreate()
    }
  }

  const onCreate = () => {
    createComponent(
      { body: { genre: ComponentGenre.chart } },
      {
        onSuccess: ({ data }) => navigate(`${data.id}`)
      }
    )
  }

  const [columnDefs] = useState<ColDef[]>(() => [
    {
      field: 'title',
      headerName: 'Title',
      width: 300,
      cellRenderer: (props: ICellRendererParams) => {
        return (
          <Stack direction='column' spacing={0}>
            <Typography variant='body'>{props.value}</Typography>
            <Typography
              variant='websiteDetails'
              style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
            >
              {props.data?.description || 'No description'}
            </Typography>
          </Stack>
        )
      },
      sortable: true
    },
    {
      field: 'id',
      headerName: 'Actions',
      width: 180,
      cellRenderer: ActionCell
    },
    {
      field: 'genre',
      headerName: 'Type',
      width: 90,
      flex: 1,
      cellRenderer: generalCellRenderer({ main: true, options: genres })
    },
    {
      field: 'source',
      headerName: 'Source',
      width: 90,
      flex: 1,
      cellRenderer: generalCellRenderer({ main: true, options: sources })
    },
    {
      field: 'scope',
      headerName: 'Scope',
      width: 120,
      flex: 1,
      cellRenderer: generalCellRenderer({ main: true, options: scopes })
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 90,
      flex: 1,
      cellRenderer: generalCellRenderer({ main: true, options: statuses }),
      editable: true,
      cellEditor: 'agRichSelectCellEditor',
      cellEditorParams: {
        values: getValues(statuses),
        cellRenderer: generalCellRenderer({ main: false, options: statuses })
      }
    },
    { field: 'owner.full_name', headerName: 'Created By', width: 120, flex: 1 },
    {
      field: 'updated_at',
      headerName: 'Updated On',
      width: 120,
      flex: 1,
      sortable: true,
      cellRenderer: DateCellRenderer
    },
    {
      field: 'search',
      hide: true,
      filter: 'agTextColumnFilter',
      lockVisible: true // disable visibility in menu
    },
    {
      field: 'businessId',
      hide: true,
      filter: 'agTextColumnFilter',
      lockVisible: true // disable visibility in menu
    }
  ])

  const onGridReady = useCallback(
    (params: GridReadyEvent) => {
      const { businessId, search } = initialFilterParams.current
      const initialFilterModel = { businessId: { filter: businessId }, search: { filter: search } }
      const datasource = new ServerSideDatasource(queryClient, initialFilterModel)
      params.api.setGridOption('serverSideDatasource', datasource)
    },
    [queryClient]
  )

  useEffect(() => {
    async function triggerFilterChange() {
      const gridApi = gridRef.current?.api
      if (!gridApi) return
      const businessIdFilterInstance = await gridApi.getColumnFilterInstance('businessId')
      businessIdFilterInstance?.setModel({ filter: businessId })
      const searchFilterInstance = await gridApi.getColumnFilterInstance('search')
      searchFilterInstance?.setModel({ filter: debouncedSearchText })
      gridApi.onFilterChanged()
    }
    triggerFilterChange()
  }, [businessId, debouncedSearchText])

  const onCellValueChanged = useCallback(
    (event: CellValueChangedEvent) => {
      const {
        api,
        newValue,
        oldValue,
        colDef: { field },
        data
      } = event

      api.applyServerSideTransaction({ update: [{ ...data, [field!]: `${newValue}__editing` }] })

      let updateFunction: typeof updateAsync | typeof publishAsync | typeof draftAsync = updateAsync

      if (field === 'status') {
        if (newValue === 'published') updateFunction = publishAsync
        else updateFunction = draftAsync
      }

      updateFunction({
        id: data.id,
        body: { [field!]: newValue }
      })
        .then((res) => {
          api.applyServerSideTransaction({ update: [{ ...res.data }] })
        })
        .catch(() => {
          api.applyServerSideTransaction({ update: [{ ...data, [field!]: oldValue }] })
        })
    },
    [updateAsync, draftAsync, publishAsync]
  )

  const getRowId = useCallback((params: GetRowIdParams) => `${params.data.id}`, [])

  return (
    <Grid container spacing={2} pt={1}>
      <Grid item xs={12} container justifyContent='center' spacing={2} style={{ paddingTop: 8 }}>
        <Grid item xs={12} md={6} lg={2}>
          <Box display='flex'>
            <TextField
              fullWidth
              placeholder='Search for a chart...'
              variant='outlined'
              type='search'
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              InputProps={{
                style: { height: 32 }
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6} lg={10}>
          <Select
            value=''
            onChange={(e) => handleActionChange(e.target.value)}
            displayEmpty
            inputProps={{ 'aria-label': 'Select an action' }}
          >
            <MenuItem value='' disabled>
              Setup a Chart
            </MenuItem>
            <MenuItem value='register'>Register</MenuItem>
            <MenuItem value='create'>{isPending ? 'Creating...' : 'Create'}</MenuItem>
          </Select>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Box className='ag-theme-alpine' style={{ height: 'calc(-124px + 100vh)', width: '100%' }}>
          <AgGridReact
            ref={gridRef}
            rowHeight={50}
            columnDefs={columnDefs}
            rowModelType='serverSide'
            rowBuffer={0}
            cacheBlockSize={100}
            defaultColDef={DEFAULT_COL_DEF}
            cacheOverflowSize={5}
            maxConcurrentDatasourceRequests={1}
            infiniteInitialRowCount={200}
            maxBlocksInCache={10}
            onGridReady={onGridReady}
            getRowId={getRowId}
            onCellValueChanged={onCellValueChanged}
            autoSizeStrategy={{
              type: 'fitCellContents'
            }}
            columnMenu='legacy'
          />
        </Box>
      </Grid>
    </Grid>
  )
}
