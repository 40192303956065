import { replaceSlashWithDash } from './string-utils'

export function isComponentManagementRoute(path: string) {
  return path.startsWith('/charts')
}

export function unifiedRouteIdForComponentManagement(path: string) {
  return replaceSlashWithDash(path)
    .replace('-query', '')
    .replace('-builder', '')
    .replace('-visualize', '')
    .replace('-data-preview', '')
    .replace('-data-selection', '')
}

export function uniquePageIdFromRoute(path: string) {
  if (isComponentManagementRoute(path)) {
    return unifiedRouteIdForComponentManagement(path)
  }
  return replaceSlashWithDash(path)
}
