export type DisplayNameSchema = Record<string, { newName?: string; columns?: DisplayName }>
export type DisplayName = Record<string, string>

export const RENAMING_DEFAULTS: Record<string, { default?: string; columns?: DisplayName }> = {
  Department: { default: 'Department' },
  Vertical: { default: 'Vertical' },
  BusinessLocation: { default: 'Business Location' },
  GeneralLedgerAccount: {
    columns: {
      classification: 'Classification'
    }
  },
  GeneralLedgerClassification: { default: 'GL Classification' },
  Subsidiary: { default: 'Subsidiary' },
  Project: { default: 'Project' }
}

export function getDisplayName(
  schemaRenamings: DisplayNameSchema,
  modelName: string,
  columnName?: string,
  includeDefaults: boolean = true
): string {
  const modelRenamings = schemaRenamings[modelName]

  if (columnName) {
    if (includeDefaults) {
      return (
        modelRenamings?.columns?.[columnName] ||
        RENAMING_DEFAULTS[modelName]?.columns?.[columnName] ||
        columnName
      )
    }
    return modelRenamings?.columns?.[columnName] || columnName
  }

  if (includeDefaults) {
    return modelRenamings?.newName || RENAMING_DEFAULTS[modelName]?.default || modelName
  }
  return modelRenamings?.newName || modelName
}
