import { useMemo, useRef } from 'react'
import { useSelector } from 'react-redux'

import { ColDef } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'

import AgGrid from '@components/ag-grid'
import { applyHighlightRenderer } from '@components/ag-grid/custom-cell-renderer/highlight-cell-renderer'
import { JSONCellRenderer } from '@components/ag-grid/custom-cell-renderer/json-cell-renderer'
import { SQLCellRenderer } from '@components/ag-grid/custom-cell-renderer/sql-cell-renderer'
import { RIGHT_ALIGN_COLUMN_DATA } from '@components/ag-grid/types'
import { autoFitAllColumns } from '@components/ag-grid/utils'

import {
  multiTextColumnDef,
  numericColumnDef,
  textSearchColumnDef,
  timeZoneDateColumnDef
} from '@layout-components/general/data-connections/utils/col-defs'

import { RootState } from '@store/index'
import { selectGridSearchTerm } from '@store/slices/grid'
import { selectUserBusinessTimeZone } from '@store/slices/user-preferences'

import { useRegisterAgGridRefEffect } from 'contexts/grid-ref-context'

import { IActiveConnections, TokenProvider } from './types'

const getColumnDefsByProvider = (provider?: TokenProvider, timeZone?: string | undefined) => {
  if (!provider) {
    return []
  }

  switch (provider) {
    case TokenProvider.POSTGRESQL:
      return [
        textSearchColumnDef('application_name', 'Application Name'),
        timeZoneDateColumnDef('backend_start', 'Backend Start', timeZone),
        textSearchColumnDef('client_addr', 'Client Address'),
        multiTextColumnDef('datname', 'Database Name'),
        textSearchColumnDef('pid', 'Process ID'),
        textSearchColumnDef('query', 'Query', {
          minWidth: 200,
          cellRenderer: SQLCellRenderer,
          cellRendererParams: {
            title: 'Query'
          }
        }),
        multiTextColumnDef('state', 'State'),
        multiTextColumnDef('usename', 'Username')
      ]

    case TokenProvider.MS_SQL:
      return [
        textSearchColumnDef('session_id', 'Session ID'),
        timeZoneDateColumnDef('login_time', 'Login Time', timeZone),
        multiTextColumnDef('login_name', 'Login Name'),
        textSearchColumnDef('program_name', 'Program Name'),
        textSearchColumnDef('host_name', 'Host Name'),
        multiTextColumnDef('status', 'Status'),
        numericColumnDef('cpu_time', 'CPU Time', {
          ...RIGHT_ALIGN_COLUMN_DATA
        }),
        numericColumnDef('memory_usage', 'Memory Usage', {
          ...RIGHT_ALIGN_COLUMN_DATA
        }),
        numericColumnDef('total_scheduled_time', 'Scheduled Time', {
          ...RIGHT_ALIGN_COLUMN_DATA
        }),
        numericColumnDef('total_elapsed_time', 'Elapsed Time', {
          ...RIGHT_ALIGN_COLUMN_DATA
        }),
        numericColumnDef('reads', 'Reads', {
          ...RIGHT_ALIGN_COLUMN_DATA
        }),
        numericColumnDef('writes', 'Writes', {
          ...RIGHT_ALIGN_COLUMN_DATA
        })
      ]

    case TokenProvider.ORACLE_DB:
      return [
        textSearchColumnDef('SID', 'Session ID'),
        numericColumnDef('SERIAL#', 'Serial Number', {
          ...RIGHT_ALIGN_COLUMN_DATA
        }),
        multiTextColumnDef('STATUS', 'Status'),
        multiTextColumnDef('USERNAME', 'Username'),
        textSearchColumnDef('SERVICE_NAME', 'Service Name'),
        textSearchColumnDef('PROGRAM', 'Program'),
        multiTextColumnDef('SCHEMANAME', 'Schema Name'),
        textSearchColumnDef('EVENT', 'Event'),
        multiTextColumnDef('OSUSER', 'OS User'),
        textSearchColumnDef('MACHINE', 'Machine'),
        multiTextColumnDef('STATE', 'State'),
        multiTextColumnDef('WAIT_CLASS', 'Wait Class'),
        numericColumnDef('WAIT_TIME_MICRO', 'Wait Time (Microseconds)', {
          ...RIGHT_ALIGN_COLUMN_DATA
        })
      ]

    default:
      return []
  }
}

export function ActiveConnectionsList({
  data,
  isPending,
  tokenProvider
}: {
  data?: IActiveConnections
  isPending: boolean
  tokenProvider?: TokenProvider
}) {
  const activeConnections = useMemo(() => data ?? [], [data])
  const wrapperRef = useRef<HTMLDivElement>(null)
  const gridRef = useRef<AgGridReact>(null)
  const searchTerm = useSelector((state: RootState) => selectGridSearchTerm(state, undefined))
  const timeZone = useSelector(selectUserBusinessTimeZone)

  useRegisterAgGridRefEffect(gridRef)

  const columnDefs: ColDef[] = useMemo(
    () => [
      ...getColumnDefsByProvider(tokenProvider, timeZone?.toString()),
      textSearchColumnDef('fullJSON', 'Full JSON', {
        minWidth: 200,
        cellRenderer: JSONCellRenderer,
        cellRendererParams: {
          showFullData: true,
          title: 'Full JSON'
        }
      })
    ],
    [tokenProvider, timeZone]
  )

  const finalColumnDefs = useMemo(
    () => applyHighlightRenderer(columnDefs, searchTerm, ['openInNewTab']),
    [columnDefs, searchTerm]
  )

  return (
    <div className='h-[calc(100vh-250px)] w-full' ref={wrapperRef}>
      <AgGrid
        style={{ height: '100%' }}
        ref={gridRef}
        autoSizeStrategy={{ type: 'fitCellContents' }}
        onGridSizeChanged={autoFitAllColumns}
        rowData={activeConnections}
        columnDefs={finalColumnDefs}
        loading={isPending}
        defaultColDef={{
          resizable: true,
          minWidth: 50,
          filter: 'agTextColumnFilter',
          floatingFilter: true
        }}
      />
    </div>
  )
}
