import { useMemo, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'

import { ColDef, ValueGetterParams } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'

import AgGrid from '@components/ag-grid'
import StatusBar from '@components/ag-grid/status-bar'

import { MismatchFoundCellRenderer } from '@layout-components/general/data-connections/mismatch-found-cell-renderer'
import {
  durationColumnDef,
  numericCommaSeparatedColumnDef,
  textSearchColumnDef,
  timeZoneDateColumnDef
} from '@layout-components/general/data-connections/utils/col-defs'

import { UNDO_REDO_CELL_EDITING_LIMIT } from '@hooks/grid/useKeyboardDetection'

import { selectUserBusinessTimeZone } from '@store/slices/user-preferences'

import { useFetchDataIntegrityJobs } from './queries/fetch-data-integrity-jobs'
import { IConnectedSourceSystem } from './types'

const CACHE_BLOCK_SIZE = 100000

export function DataIntegrityJobsList({
  connectedSourceSystem
}: {
  connectedSourceSystem?: IConnectedSourceSystem
}) {
  const wrapperRef = useRef<HTMLDivElement>(null)
  const gridRef = useRef<AgGridReact>(null)
  const timeZone = useSelector(selectUserBusinessTimeZone)

  const gridContext = useMemo(() => {
    return {
      validationState: {}
    }
  }, [])

  const { id } = useParams<{ id: string }>()

  const { data: dataIntegrityJobList, isLoading } = useFetchDataIntegrityJobs(
    connectedSourceSystem?.businessId!,
    id!,
    {
      page: 1,
      limit: CACHE_BLOCK_SIZE,
      filterModel: null,
      sortModel: [],
      includeDeleted: false
    }
  )

  const columnDefs: ColDef[] = useMemo<ColDef[]>(() => {
    const generateColumnDefs = () => {
      const columnGroups = []
      let children: ColDef[] = []

      // checksum
      children = [
        numericCommaSeparatedColumnDef('checksumSourceRowCount', 'Source Row Count', {
          filter: 'agTextColumnFilter',
          valueGetter: (params: ValueGetterParams) =>
            params.data?.checksumMetadata?.sourceRowCount ?? null
        }),
        numericCommaSeparatedColumnDef('checksumDestinationRowCount', 'Destination Row Count', {
          filter: 'agTextColumnFilter',
          valueGetter: (params: ValueGetterParams) =>
            params.data?.checksumMetadata?.destinationRowCount ?? null
        }),
        numericCommaSeparatedColumnDef('checksumAdded', 'Added', {
          filter: 'agTextColumnFilter',
          valueGetter: (params: ValueGetterParams) => params.data?.checksumMetadata?.added ?? null
        }),
        numericCommaSeparatedColumnDef('checksumUpdated', 'Updated', {
          filter: 'agTextColumnFilter',
          valueGetter: (params: ValueGetterParams) => params.data?.checksumMetadata?.updated ?? null
        }),
        numericCommaSeparatedColumnDef('checksumRemoved', 'Removed', {
          filter: 'agTextColumnFilter',
          valueGetter: (params: ValueGetterParams) => params.data?.checksumMetadata?.removed ?? null
        }),
        textSearchColumnDef('checksumMismatchFound', 'Mismatch Found', {
          valueGetter: (params: ValueGetterParams) =>
            params.data?.checksumMetadata?.mismatchFound ?? null,
          cellRenderer: MismatchFoundCellRenderer
        })
      ]
      columnGroups.push({ headerName: 'checksum', children: children })

      // count
      children = [
        numericCommaSeparatedColumnDef('countSourceRowCount', 'Source Row Count', {
          filter: 'agTextColumnFilter',
          valueGetter: (params: ValueGetterParams) =>
            params.data?.countMetadata?.sourceRowCount ?? null
        }),
        numericCommaSeparatedColumnDef('countDestinationRowCount', 'Dest Row Count', {
          filter: 'agTextColumnFilter',
          valueGetter: (params: ValueGetterParams) =>
            params.data?.countMetadata?.destinationRowCount ?? null
        }),
        numericCommaSeparatedColumnDef('countRowCountDiff', 'Row Count Diff', {
          filter: 'agTextColumnFilter',
          valueGetter: (params: ValueGetterParams) =>
            params.data?.countMetadata?.rowCountDiff ?? null
        }),
        textSearchColumnDef('countMismatchFound', 'Mismatch Found', {
          valueGetter: (params: ValueGetterParams) =>
            params.data?.countMetadata?.mismatchFound ?? null,
          cellRenderer: MismatchFoundCellRenderer
        })
      ]
      columnGroups.push({ headerName: 'count', children: children })

      // cursor
      children = [
        textSearchColumnDef('cursorCursorFields', 'Cursor Fields', {
          filter: 'agTextColumnFilter',
          valueGetter: (params: ValueGetterParams) =>
            params.data?.cursorMetadata?.cursorFields ?? null
        }),
        textSearchColumnDef('cursorSourceCursorValues', 'Source Cursor Values', {
          filter: 'agTextColumnFilter',
          valueGetter: (params: ValueGetterParams) =>
            params.data?.cursorMetadata?.sourceCursorValues ?? null
        }),
        textSearchColumnDef('cursorDestCursorValues', 'Dest Cursor Values', {
          filter: 'agTextColumnFilter',
          valueGetter: (params: ValueGetterParams) =>
            params.data?.cursorMetadata?.destCursorValues ?? null
        }),
        textSearchColumnDef('cursorMismatchFound', 'Mismatch Found', {
          valueGetter: (params: ValueGetterParams) =>
            params.data?.cursorMetadata?.mismatchFound ?? null,
          cellRenderer: MismatchFoundCellRenderer
        })
      ]
      columnGroups.push({ headerName: 'cursor', children: children })

      // initialCount
      children = [
        numericCommaSeparatedColumnDef('initialCountSourceRowCount', 'Source Row Count', {
          filter: 'agTextColumnFilter',
          valueGetter: (params: ValueGetterParams) =>
            params.data?.initialCountMetadata?.sourceRowCount ?? null
        }),
        numericCommaSeparatedColumnDef('initialCountDestinationRowCount', 'Destination Row Count', {
          filter: 'agTextColumnFilter',
          valueGetter: (params: ValueGetterParams) =>
            params.data?.initialCountMetadata?.destinationRowCount ?? null
        }),
        numericCommaSeparatedColumnDef('initialCountRowCountDiff', 'Row Count Diff', {
          filter: 'agTextColumnFilter',
          valueGetter: (params: ValueGetterParams) =>
            params.data?.initialCountMetadata?.rowCountDiff ?? null
        }),
        textSearchColumnDef('initialCountMismatchFound', 'Mismatch Found', {
          valueGetter: (params: ValueGetterParams) =>
            params.data?.initialCountMetadata?.mismatchFound ?? null,
          cellRenderer: MismatchFoundCellRenderer
        })
      ]
      columnGroups.push({ headerName: 'initialCount', children: children })

      // pkCount
      children = [
        numericCommaSeparatedColumnDef('pkCountSourcePkRowCount', 'Source PK Row Count', {
          filter: 'agTextColumnFilter',
          valueGetter: (params: ValueGetterParams) =>
            params.data?.pkCountMetadata?.sourcePkRowCount ?? null
        }),
        numericCommaSeparatedColumnDef('pkCountSourceRowCount', 'Source Row Count', {
          filter: 'agTextColumnFilter',
          valueGetter: (params: ValueGetterParams) =>
            params.data?.pkCountMetadata?.sourceRowCount ?? null
        }),
        numericCommaSeparatedColumnDef('pkCountRowCountDiff', 'Row Count Diff', {
          filter: 'agTextColumnFilter',
          valueGetter: (params: ValueGetterParams) =>
            params.data?.pkCountMetadata?.rowCountDiff ?? null
        }),
        textSearchColumnDef('pkCountMismatchFound', 'Mismatch Found', {
          valueGetter: (params: ValueGetterParams) =>
            params.data?.pkCountMetadata?.mismatchFound ?? null,
          cellRenderer: MismatchFoundCellRenderer
        }),
        textSearchColumnDef('pkCountPrimaryKeys', 'Primary keys', {
          valueGetter: (params: ValueGetterParams) =>
            params.data?.pkCountMetadata?.primaryKeys ?? null
        })
      ]
      columnGroups.push({ headerName: 'pkCount', children: children })

      // pkNull
      children = [
        numericCommaSeparatedColumnDef('pkNullSourcePkNullCount', 'Source PK Null Count', {
          filter: 'agTextColumnFilter',
          valueGetter: (params: ValueGetterParams) =>
            params.data?.pkNullMetadata?.sourcePkNullCount ?? null
        }),
        textSearchColumnDef('pkNullSourceHasNullPk', 'Source Has Null Pk', {
          valueGetter: (params: ValueGetterParams) =>
            params.data?.pkNullMetadata?.sourceHasNullPk ?? null,
          cellRenderer: MismatchFoundCellRenderer
        }),
        textSearchColumnDef('pkNullMismatchFound', 'Mismatch Found', {
          valueGetter: (params: ValueGetterParams) =>
            params.data?.pkNullMetadata?.mismatchFound ?? null,
          cellRenderer: MismatchFoundCellRenderer
        }),
        textSearchColumnDef('pkNullPrimaryKeys', 'Primary keys', {
          valueGetter: (params: ValueGetterParams) =>
            params.data?.pkNullMetadata?.primaryKeys ?? null
        })
      ]
      columnGroups.push({ headerName: 'pkNull', children: children })

      // initialCursor
      children = [
        textSearchColumnDef('initialCursorCursorFields', 'Cursor Fields', {
          valueGetter: (params: ValueGetterParams) =>
            params.data?.initialCursorMetadata?.cursorFields ?? null
        }),
        textSearchColumnDef('initialCursorSourceCursorValues', 'Source Cursor Values', {
          valueGetter: (params: ValueGetterParams) =>
            params.data?.initialCursorMetadata?.sourceCursorValues ?? null
        }),
        textSearchColumnDef('initialCursorDestCursorValues', 'Dest Cursor Values', {
          valueGetter: (params: ValueGetterParams) =>
            params.data?.initialCursorMetadata?.destCursorValues ?? null
        }),
        textSearchColumnDef('initialCursorMismatchFound', 'Mismatch Found', {
          valueGetter: (params: ValueGetterParams) =>
            params.data?.initialCursorMetadata?.mismatchFound ?? null,
          cellRenderer: MismatchFoundCellRenderer
        })
      ]
      columnGroups.push({ headerName: 'initialCursor', children: children })

      // pkCountDestination
      children = [
        numericCommaSeparatedColumnDef(
          'pkCountDestinationDestinationPkRowCount',
          'Dest PK Row Count',
          {
            filter: 'agTextColumnFilter',
            valueGetter: (params: ValueGetterParams) =>
              params.data?.pkCountDestinationMetadata?.destinationPkRowCount ?? null
          }
        ),
        numericCommaSeparatedColumnDef(
          'pkCountDestinationDestinationRowCount',
          'Destination Row Count',
          {
            filter: 'agTextColumnFilter',
            valueGetter: (params: ValueGetterParams) =>
              params.data?.pkCountDestinationMetadata?.destinationRowCount ?? null
          }
        ),
        numericCommaSeparatedColumnDef('pkCountDestinationRowCountDiff', 'Row Count Diff', {
          filter: 'agTextColumnFilter',
          valueGetter: (params: ValueGetterParams) =>
            params.data?.pkCountDestinationMetadata?.rowCountDiff ?? null
        }),
        textSearchColumnDef('pkCountDestinationMismatchFound', 'Mismatch Found', {
          valueGetter: (params: ValueGetterParams) =>
            params.data?.pkCountDestinationMetadata?.mismatchFound ?? null,
          cellRenderer: MismatchFoundCellRenderer
        }),
        textSearchColumnDef('pkCountDestinationPrimaryKeys', 'Primary keys', {
          valueGetter: (params: ValueGetterParams) =>
            params.data?.pkCountDestinationMetadata?.primaryKeys ?? null
        })
      ]
      columnGroups.push({ headerName: 'pkCountDestination', children: children })

      // pkNullAny
      children = [
        textSearchColumnDef('pkNullAnyNullPkColumns', 'Null Pk Columns', {
          valueGetter: (params: ValueGetterParams) =>
            params.data?.pkNullAnyMetadata?.nullPkColumns ?? null
        }),
        numericCommaSeparatedColumnDef('pkNullAnyPkNullAnyRowCount', 'Pk Null Any Row Count', {
          filter: 'agTextColumnFilter',
          valueGetter: (params: ValueGetterParams) =>
            params.data?.pkNullAnyMetadata?.pkNullAnyRowCount ?? null
        }),
        textSearchColumnDef('pkNullAnyMismatchFound', 'Mismatch Found', {
          valueGetter: (params: ValueGetterParams) =>
            params.data?.pkNullAnyMetadata?.mismatchFound ?? null,
          cellRenderer: MismatchFoundCellRenderer
        }),
        textSearchColumnDef('pkNullAnyPrimaryKeys', 'Primary keys', {
          valueGetter: (params: ValueGetterParams) =>
            params.data?.pkNullAnyMetadata?.primaryKeys ?? null
        })
      ]
      columnGroups.push({ headerName: 'pkNullAny', children: children })

      const staticColumns: ColDef[] = [
        textSearchColumnDef('stream', 'Stream', { pinned: 'left' }),
        textSearchColumnDef('uuid', 'Job ID'),
        timeZoneDateColumnDef('startedAt', 'Started At', timeZone),
        timeZoneDateColumnDef('endedAt', 'Ended At', timeZone),
        durationColumnDef('duration', 'Duration', 'startedAt', 'endedAt', false)
      ]

      return [...staticColumns, ...columnGroups]
    }

    return generateColumnDefs()
  }, [timeZone])

  return (
    <div className='h-[calc(100vh-13rem)] w-full' ref={wrapperRef}>
      <AgGrid
        style={{ height: 'calc(100vh - 12rem)', width: '100%' }}
        ref={gridRef}
        className='zebra-stripe'
        rowData={dataIntegrityJobList?.records}
        loading={isLoading}
        columnDefs={columnDefs}
        defaultColDef={{
          resizable: true,
          minWidth: 50,
          getQuickFilterText: () => '',
          filter: true,
          floatingFilter: true,
          suppressHeaderContextMenu: true
        }}
        autoSizeStrategy={{ type: 'fitCellContents' }}
        rowSelection='multiple'
        suppressRowClickSelection={true}
        wrapperRef={wrapperRef}
        undoRedoCellEditing={true}
        undoRedoCellEditingLimit={UNDO_REDO_CELL_EDITING_LIMIT}
        enableFillHandle={true}
        enableRangeSelection={true}
        enterNavigatesVertically={true}
        enterNavigatesVerticallyAfterEdit={false}
        context={gridContext}
        loadingOverlayComponentParams={{
          showHeader: true,
          topOffset: '49px'
        }}
        statusBar={{
          statusPanels: [
            {
              statusPanel: StatusBar,
              statusPanelParams: { aggFuncs: ['sum', 'avg', 'count', 'totalDuration'] }
            }
          ]
        }}
      />
    </div>
  )
}
