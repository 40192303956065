import type { MutableRefObject } from 'react'

import type { UniqueIdentifier } from '@dnd-kit/core'

import { EnumValueUnion } from '@utils/obj-utils'

import { GeneralLedgerAccountNodeIdentifier } from 'components/financial/types'

import { PageIdentifier } from '../../pages/admin/layouts/types'

export interface TreeItem {
  id: UniqueIdentifier
  label: string
  children: TreeItem[]
  collapsed?: boolean
  identifier?: TreeItemIdentifier
  showOnReport?: boolean
  referencedNodeId?: number
  [key: string]: any
}

export type TreeItems = TreeItem[]

export interface FlattenedItem extends TreeItem {
  parentId: UniqueIdentifier | null
  depth: number
  index: number
}

export type SensorContext = MutableRefObject<{
  items: FlattenedItem[]
  offset: number
}>

export enum ActionTypeEnum {
  createRootNode = 'createRootNode',
  createChildNode = 'createChildNode',
  editNode = 'editNode'
}
export type ActionType = EnumValueUnion<typeof ActionTypeEnum>

export enum TreeSourceEnum {
  GeneralLedgerAccount = 'GeneralLedgerAccount',
  Page = 'Page',
  FinancialReport = 'FinancialReport',
  SubsidiaryDataRenaming = 'SubsidiaryDataRenaming'
}
export const TreeSourceEnumTypes: string[] = Object.values(TreeSourceEnum)
export type TreeSource = EnumValueUnion<typeof TreeSourceEnum>

// export type TreeSource = 'GeneralLedgerAccount' | 'Page' | 'FinancialReport';

export type TreeItemIdentifier = GeneralLedgerAccountNodeIdentifier | PageIdentifier
