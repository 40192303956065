import { useMemo, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'

import { ColDef } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'

import { humanizeFieldName, stringToCommaSeparated } from '@utils/string-utils'

import AgGrid from '@components/ag-grid'

import { selectUserBusinessTimeZone } from '@store/slices/user-preferences'

import { PkValidCellRenderer } from './pk-valid-cell-rendere'
import { useFetchCatalogStreams } from './queries/fetch-catalog-stream'
import { DataConnectionMode, IConnectedSourceSystem } from './types'
import { multiTextColumnDef, textSearchColumnDef, timeZoneDateColumnDef } from './utils/col-defs'

export function CatalogList({
  mode,
  connectedSourceSystem,
  isPending
}: {
  mode: DataConnectionMode
  connectedSourceSystem?: IConnectedSourceSystem
  isPending: boolean
}) {
  const wrapperRef = useRef<HTMLDivElement>(null)
  const gridRef = useRef<AgGridReact>(null)
  const timeZone = useSelector(selectUserBusinessTimeZone)

  const { id } = useParams<{ id: string }>()
  const { data, isLoading } = useFetchCatalogStreams({
    businessId: connectedSourceSystem?.businessId,
    id
  })

  const columnDefs: ColDef[] = useMemo<ColDef[]>(() => {
    const baseColumns: ColDef[] = [
      textSearchColumnDef('streamName', 'Name'),
      multiTextColumnDef('streamNamespace', 'Namespace'),
      multiTextColumnDef('syncMode', 'Sync Mode', {
        valueFormatter: ({ value }) => humanizeFieldName(value),
        filterValueGetter: (params) => humanizeFieldName(params.data.syncMode)
      }),
      textSearchColumnDef('primaryKey', 'Primary Key', {
        valueFormatter: ({ value }) => stringToCommaSeparated(value),
        maxWidth: 700,
        minWidth: 100
      }),
      // NOTE: Mismatch found is the opposite of PK Valid (i.e mismatch found is true when PK Valid is false)
      multiTextColumnDef('pkMismatchFound', 'PK Valid', {
        maxWidth: 80,
        minWidth: 80,
        cellRenderer: PkValidCellRenderer,
        filterValueGetter: (params) => !params.data.pkMismatchFound
      }),
      timeZoneDateColumnDef('lastDataIntegrityCheck', `Last Data Integrity Check`, timeZone, {
        initialWidth: 250
      }),
      textSearchColumnDef('cursorField', 'Cursor Field', {
        valueFormatter: ({ value }) => stringToCommaSeparated(value)
      }),
      textSearchColumnDef('cursor', 'Cursor')
    ]

    if (mode !== DataConnectionMode.CLIENT) {
      baseColumns.splice(7, 0, textSearchColumnDef('stateType', 'State Type'))
    }

    return baseColumns
  }, [mode, timeZone])

  return (
    <div className='h-[calc(100vh-12rem)] w-full' ref={wrapperRef}>
      <AgGrid
        style={{ height: '100%' }}
        ref={gridRef}
        rowData={data}
        columnDefs={columnDefs}
        autoSizeStrategy={{ type: 'fitCellContents' }}
        defaultColDef={{
          minWidth: 100,
          getQuickFilterText: () => '',
          filter: true,
          floatingFilter: true,
          filterParams: {
            buttons: ['clear']
          }
        }}
        loading={isLoading}
        persistFilterState={true}
      />
    </div>
  )
}
