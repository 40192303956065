import { useState } from 'react'

import { motion } from 'framer-motion'

import { humanizeFieldName } from '@utils/string-utils'
import { cn } from '@utils/style-utils'

import { ColumnSelector } from '@components/component-management/ColumnSelector'
import { DataPill } from '@components/component-management/DataPill'

import { associationsPath } from '@store/slices/component/query-config'

import { SelectionMode } from 'pages/component-management/data-selection'
import {
  IAssociationData,
  IColumnData,
  IDataTab,
  IQueryData
} from 'pages/component-management/types/query-builder-types'

const ANIMATION_PROPS = {
  initial: { x: -16, opacity: 0 },
  animate: { x: 0, opacity: 1 },
  transition: { type: 'spring', stiffness: 100, damping: 15 }
}

interface AssociationSelectorProps {
  associations: IAssociationData[]
  renderName?: (modelName: string, columnName?: string) => string | undefined // render prop for schema renaming
  foreignKeys?: string[]
  showForeignKeys?: boolean
  hideUnusedTables?: boolean
  hideUnusedColumns?: boolean
  parent?: string
  onTableExpand?: (dataTab: IDataTab) => void
  onSelect?: (column: IColumnData, parent: string) => void
  onColumnEdit?: (modelName: string, columnId?: number) => void
  selectedData?: IQueryData
  selectedCustomData?: IQueryData
  selectionMode?: SelectionMode
  customFieldCreationParent?: string
  selectedCustomFieldId?: number
  searchText?: string
}

export function AssociationSelector(props: AssociationSelectorProps) {
  const {
    associations,
    renderName,
    foreignKeys = [],
    showForeignKeys = false,
    hideUnusedTables = false,
    hideUnusedColumns = false,
    parent = '',
    onTableExpand = _.noop,
    onSelect = _.noop,
    onColumnEdit = _.noop,
    selectedData,
    selectedCustomData,
    selectionMode = SelectionMode.Normal,
    customFieldCreationParent,
    selectedCustomFieldId,
    searchText = ''
  } = props

  const [userExpanded, setUserExpanded] = useState<string[]>([])
  const [userCollapsed, setUserCollapsed] = useState<string[]>([])

  const toggleExpansion = (associationName: string, expand: boolean) => {
    const addTo = expand ? setUserExpanded : setUserCollapsed
    const removeFrom = expand ? setUserCollapsed : setUserExpanded
    addTo((prev) => [...prev, associationName])
    removeFrom((prev) => prev.filter((name) => name !== associationName))
  }

  const containsSelections = (association: IAssociationData) =>
    !!_.get(
      customFieldCreationParent ? selectedCustomData : selectedData,
      `${associationsPath(parent)}.${association.name}`
    )

  const filteredAssociations = _.filter(associations, (association) =>
    hideUnusedTables ? containsSelections(association) : true
  )

  return (
    <motion.div className='relative' {...ANIMATION_PROPS}>
      <div className='space-y-4'>
        {filteredAssociations.map((association, index) => {
          const isFirst = index === 0
          const isLast = index === filteredAssociations.length - 1
          const hasSelections = containsSelections(association)
          const shouldExpand =
            userExpanded.includes(association.name) ||
            (!userCollapsed.includes(association.name) && hasSelections)
          const pillLabel = humanizeFieldName(renderName?.(association.name) || association.name)
          return (
            <div key={association.name} className='relative flex items-start'>
              {!isLast && (
                <div
                  className={cn('absolute -bottom-8 left-5 top-0 w-px bg-grey', isFirst && 'top-4')}
                />
              )}
              <div className={cn('mr-4 mt-4 h-px bg-grey', isFirst ? 'w-10' : 'ml-5 w-5')} />
              {shouldExpand ? (
                <ColumnSelector
                  model={association}
                  modelName={association.model}
                  renderName={renderName}
                  onClick={() => toggleExpansion(association.name, false)}
                  showForeignKeys={showForeignKeys}
                  hideUnusedTables={hideUnusedTables}
                  hideUnusedColumns={hideUnusedColumns}
                  parent={`${associationsPath(parent)}.${association.name}`}
                  onTableExpand={onTableExpand}
                  onSelect={onSelect}
                  onColumnEdit={onColumnEdit}
                  selectedData={selectedData}
                  selectionMode={selectionMode}
                  selectedCustomData={selectedCustomData}
                  customFieldCreationParent={customFieldCreationParent}
                  selectedCustomFieldId={selectedCustomFieldId}
                  searchText={searchText}
                />
              ) : (
                <DataPill
                  className='mr-4' // extra space so that it doesn't touch the edge of Data Panel
                  label={pillLabel}
                  onClick={() => {
                    toggleExpansion(association.name, true)
                    onTableExpand({
                      associationName: association.name,
                      modelName: association.model
                    })
                  }}
                  selected={hasSelections}
                  isForeignKey={showForeignKeys && foreignKeys.includes(association.name)}
                  highlight={matchesOrContainsSearchText(
                    searchText,
                    pillLabel,
                    association,
                    showForeignKeys,
                    renderName
                  )}
                />
              )}
            </div>
          )
        })}
      </div>
    </motion.div>
  )
}

function matchesOrContainsSearchText(
  searchText: string,
  pillLabel: string,
  association: IAssociationData,
  showForeignKeys: boolean,
  renderName?: (modelName: string, columnName?: string) => string | undefined
): boolean {
  if (!searchText) return false

  // return true if the pill label itself matches search text
  const matchesPillLabel = pillLabel.toLowerCase().includes(searchText.toLowerCase())
  if (matchesPillLabel) return true

  // filter columns to include/exclude foreign keys against search, based on 'showForeignKeys' state
  const columnsToMatch = _.filter(association.columns, (column) => {
    const isForeignKey = !!column.association_name
    const isId = column.name === 'id'
    const isForeignKeyOrId = isForeignKey || isId
    if (!isForeignKeyOrId) return true
    return showForeignKeys
  })

  // return true if any column name matches search text
  return _.some(columnsToMatch, (column) => {
    const columnLabel = humanizeFieldName(
      renderName?.(association.name, column.name) || column.name
    )
    return columnLabel.toLowerCase().includes(searchText.toLowerCase())
  })
}
