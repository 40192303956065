import { EnumValueUnion } from '@utils/obj-utils'

import { SelectedDimensions } from 'components/control-panel/types'
import { IModelSelection } from 'components/financial/model-plus-actual/types'
import { TreeItem } from 'components/tree/types'
import {
  IComponentFilters,
  IComponentSorts
} from 'pages/component-management/types/component-types'
import { FinancialStatementType } from 'pages/financial-statements/types'
import { ExpandedDateFilterInfo } from 'types/filter'

import { ACTUALS } from './model-plus-actual/const'

export interface FinancialHierarchy {
  id: number
  label: string
  purpose: HierarchyPurpose
  is_archived: boolean
  path?: string
  nodes?: FinancialHierarchyNode[]
  identifier: 'hierarchy'
  type: FinancialHierarchyTypeEnum
  is_report_published?: boolean
}

export enum NodeFormatType {
  Number = 'Number',
  Percentage = 'Percentage'
}

export enum NodeFormatStyle {
  Normal = 'Normal',
  Bold = 'Bold'
}

export interface NodeFormatting {
  type?: NodeFormatType
  style?: NodeFormatStyle
  decimals?: number
}

export interface INodeFormattingExpanded extends NodeFormatting {
  fontWeight: string
}

export interface FinancialHierarchyNode extends TreeItem {
  id: number
  label: string
  identifier: GeneralLedgerAccountNodeIdentifier
  sort_order: number
  path: string
  is_general_ledger_account_confirmed: boolean
  formula: string
  hierarchy_purpose?: HierarchyPurpose
  key_name?: string
  dimension_filters?: Record<string, string[]>
  metadata?: {
    formatting?: NodeFormatting
  }
  tags: Record<string, any>
}

export interface FinancialHierarchyTree extends FinancialHierarchyNode {
  id: number
  financial_hierarchy_id: number
  children: FinancialHierarchyTree[]
}

export interface FinancialHierarchyGlAccountNode extends FinancialHierarchyNode {
  general_ledger_account: GeneralLedgerAccount
}

export interface GeneralLedgerAccount {
  id: number
  name: string
  connected_source_system_name: string
  connected_source_system_id: number
  source_system_unique_id: number | string
  source_system_account_id: number | string
  classification_source: GeneralLedgerAccountClassification
  classification?: GeneralLedgerAccountClassification
  is_posting: boolean
  ag_grid_row_id: number
  is_adjustment?: boolean
}

export interface GeneralLedgerAccountTag {
  id: number
  name: string
  identifier: 'boolean' | 'string'
  is_mutable: boolean
}

export interface FinancialKpi {
  id: number
  name: string
  identifiers: GeneralLedgerAccountNodeIdentifier[]
  purpose: HierarchyPurpose
}

export enum FinancialHierarchyTypeEnum {
  REPORT = 'report',
  MAPPING = 'mapping'
}

export enum ChartOfAccountDisplayModes {
  mapped = 'mapped',
  unmapped = 'unmapped',
  automapped = 'automapped',
  nonposting = 'nonposting'
}
export type HierarchyPurpose = 'income_statement' | 'balance_sheet' | 'cash_flow'
export type FinancialHierarchyType = 'report' | 'mapping'

export enum GeneralLedgerAccountClassificationEnum {
  expense = 'expense',
  revenue = 'revenue',
  asset = 'asset',
  liability = 'liability',
  equity = 'equity'
}

export type GeneralLedgerAccountClassification = EnumValueUnion<
  typeof GeneralLedgerAccountClassificationEnum
>

export enum GlAccountNodeIdentifierEnum {
  heading = 'heading',
  formula = 'formula',
  general_ledger_account = 'general_ledger_account',
  pointer = 'pointer',
  spacer = 'spacer',
  total = 'total',
  hierarchy = 'hierarchy'
}

export type GeneralLedgerAccountNodeIdentifier = EnumValueUnion<typeof GlAccountNodeIdentifierEnum>
export const GlAccountNodeIdentifierEnumTypes: string[] = Object.values(GlAccountNodeIdentifierEnum)

export interface FinancialAdjustment {
  id: number
  unique_id: string
  ref_unique_id: string
  amount: number
  accounting_period: string
  general_ledger_account_id: number
  general_ledger_account_name: string
  ag_grid_row_id: string
  description: string
  department_id: number
  vertical_id: number
  business_location_id: number
  general_ledger_classification_id: number
  project_id: number
  subsidiary_id: number
}

export enum GeneralLedgerModelType {
  ACTUALS = 'actuals',
  BUDGET = 'budget',
  FORECAST = 'forecast'
}

export interface GeneralLedgerModel {
  id: number | typeof ACTUALS
  name: string
  type: GeneralLedgerModelType
}

export enum DefaultGLMappingHierarchyPath {
  IS_MAP = 'is_map',
  BS_MAP = 'bs_map'
}

export const DEFAULT_INCOME_STATEMENT_LABEL = 'Income Statement'

export enum TrialBalanceViewMode {
  SINGLE_ENTRY = 'single_entry',
  DOUBLE_ENTRY = 'double_entry'
}

export const isTrialBalanceSingleEntryView = (viewMode: TrialBalanceViewMode) =>
  _.isEqual(viewMode, TrialBalanceViewMode.SINGLE_ENTRY)
export const isTrialBalanceDoubleEntryView = (viewMode: TrialBalanceViewMode) =>
  _.isEqual(viewMode, TrialBalanceViewMode.DOUBLE_ENTRY)

export interface FinancialReportExportFilters {
  dateFilter: ExpandedDateFilterInfo
  showDuplicateAccounts?: boolean
  selectedDimensionFilters?: Partial<SelectedDimensions>
  selectedYear?: number
  selectedHierarchyId?: number
  monthSelections?: IModelSelection
}

export interface FilterModels {
  [key: number]: IComponentFilters | undefined
}

export interface SortModels {
  [key: number]: IComponentSorts | undefined
}

export enum TrialBalanceEnum {
  TrialBalance = 'trial-balance'
}

export type TrialBalance = EnumValueUnion<typeof TrialBalanceEnum>

export enum ModelPlusActualEnum {
  ModelPlusActual = 'model-plus-actual'
}

export type ModelPlusActual = EnumValueUnion<typeof ModelPlusActualEnum>

export enum ModelToActualEnum {
  ModelToActual = 'model-to-actual'
}

export type ModelToActual = EnumValueUnion<typeof ModelToActualEnum>

export enum PageBuilderEnum {
  PageBuilder = 'page-builder'
}

export type PageBuilder = EnumValueUnion<typeof PageBuilderEnum>

export type FinancialReportType =
  | FinancialStatementType
  | TrialBalance
  | ModelPlusActual
  | ModelToActual
  | PageBuilder

export const isModelPlusActualType = (type: FinancialReportType) => {
  return type === ModelPlusActualEnum.ModelPlusActual
}

export const isModelToActualType = (type: FinancialReportType) => {
  return type === ModelToActualEnum.ModelToActual
}

export const isTrialBalanceType = (type: FinancialReportType) => {
  return type === TrialBalanceEnum.TrialBalance
}
