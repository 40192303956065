import { useMemo, useState } from 'react'

import { ICellRendererParams } from 'ag-grid-community'

import ResizablePopover from '@core/custom/resizable-popover'

import { JSONEditor } from '@components/code-editors'
import LineWrapToggle from '@components/core/custom/line-wrap-toggle'

interface JSONCellRendererProps extends ICellRendererParams {
  title?: string
  showFullData: boolean
  hideEmpty: boolean
}

export const JSONCellRenderer = (params: JSONCellRendererProps) => {
  const [enableLineWrapping, setEnableLineWrapping] = useState(false)

  const fullJSON = useMemo(() => {
    const data = params.showFullData ? params.data : params.value
    return JSON.stringify(data, null, 2)
  }, [params.showFullData, params.data, params.value])

  const isEmpty = fullJSON === '{}' || fullJSON === '[]'
  if (isEmpty && params.hideEmpty) {
    return null
  }

  const toggleLineWrapping = () => {
    setEnableLineWrapping((prevState) => !prevState)
  }

  const headerTools = (
    <LineWrapToggle isEnabled={enableLineWrapping} onToggle={toggleLineWrapping} />
  )

  return (
    <ResizablePopover
      headerTools={headerTools}
      title={params.title || 'JSON Data'}
      content={<JSONEditor value={fullJSON} readOnly enableLineWrapping={enableLineWrapping} />}
    >
      {fullJSON}
    </ResizablePopover>
  )
}
