import React, { useRef, useState } from 'react'

import { Popover, PopoverContent, PopoverTrigger } from '@core/popover'
import { Separator } from '@core/separator'

interface ResizablePopoverProps {
  children: React.ReactNode
  content: React.ReactNode
  headerTools?: React.ReactNode
  title: string
}

const MIN_POPOVER_HEIGHT = 40
const POPOVER_PADDING_HEIGHT = 70

const ResizablePopover = ({ children, content, title, headerTools }: ResizablePopoverProps) => {
  const [dimensions, setDimensions] = useState({ width: 400, height: 200 })
  const [position, setPosition] = useState({ top: -10, left: -200 })
  const [isDragging, setIsDragging] = useState(false)
  const [startPos, setStartPos] = useState({ x: 0, y: 0 })
  const [isOpen, setIsOpen] = useState(false)

  const contentRef = useRef<HTMLDivElement>(null)

  const updateHeight = () => {
    if (contentRef.current) {
      const contentHeight = contentRef.current.scrollHeight
      const nextHeight = Math.min(
        Math.max(contentHeight + POPOVER_PADDING_HEIGHT, MIN_POPOVER_HEIGHT),
        600
      ) // Clamp height between minHeight and 600px
      setDimensions((prev) => ({ ...prev, height: nextHeight }))
    }
  }

  const startDragging = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    setIsDragging(true)
    setStartPos({ x: e.clientX - position.left, y: e.clientY - position.top })
  }

  const stopDragging = () => setIsDragging(false)

  const onPopoverContentClick = () => {
    if (isOpen) return

    // Adding a little delay to let the popover content render before calculating the height
    setTimeout(() => {
      updateHeight()
      setIsOpen(true)
    }, 100)
  }

  const onDrag = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!isDragging) return
    e.preventDefault()

    setPosition({
      top: e.clientY - startPos.y,
      left: e.clientX - startPos.x
    })
  }

  const startResizing = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    const startWidth = dimensions.width
    const startHeight = dimensions.height
    const startX = e.clientX
    const startY = e.clientY

    const onMouseMove = (e: MouseEvent) => {
      setDimensions({
        width: Math.max(200, startWidth + (e.clientX - startX)),
        height: Math.max(150, startHeight + (e.clientY - startY))
      })
    }

    const onMouseUp = () => {
      window.removeEventListener('mousemove', onMouseMove)
      window.removeEventListener('mouseup', onMouseUp)
    }

    window.addEventListener('mousemove', onMouseMove)
    window.addEventListener('mouseup', onMouseUp)
  }

  return (
    <Popover>
      <PopoverTrigger onClick={onPopoverContentClick} asChild>
        <div className='cursor-pointer truncate' title={title}>
          {children}
        </div>
      </PopoverTrigger>

      <PopoverContent
        style={{
          position: 'absolute',
          top: `${position.top}px`,
          left: `${position.left}px`,
          width: `${dimensions.width}px`,
          height: `${dimensions.height}px`
        }}
        className='border shadow-md'
        onMouseMove={onDrag}
        onMouseUp={stopDragging}
      >
        <div
          className='flex cursor-move items-center justify-between p-2 text-submenu font-semibold'
          onMouseDown={startDragging}
        >
          <span>{title}</span>
          {headerTools && <div className='flex gap-2'>{headerTools}</div>}
        </div>

        <Separator />

        <div
          ref={contentRef}
          className='overflow-auto'
          style={{
            maxHeight: `calc(100% - 40px)`
          }}
        >
          {content}
        </div>

        <div
          className='absolute bottom-0 right-0 size-8 cursor-se-resize'
          onMouseDown={startResizing}
        />
      </PopoverContent>
    </Popover>
  )
}

export default ResizablePopover
