import React, { forwardRef, useImperativeHandle, useRef } from 'react'

import { Button, DialogActions, DialogContent, Divider, Grid } from '@mui/material'

import { cn } from '@utils/style-utils'

import { Formik, useFormikContext } from 'formik'
import * as Yup from 'yup'

import { useAddCustomField } from '../../queries/add-custom-field'
import { useUpdateCustomField } from '../../queries/update-custom-field'
import { CustomFieldType, IFieldSelectorHandles, IQueryData } from '../../types/query-builder-types'
import FieldsSelector from './fields-selector'
import QueryComponent from './query-component'
import { TogglePath } from './toggle-path'

const validationSchema = Yup.object({
  name: Yup.string().required('Name is required'),
  field_type: Yup.string(),
  formula: Yup.string().required('Formula is required')
})

export interface IFormHandles {
  submit: () => void
}

export default forwardRef<
  IFormHandles,
  {
    modelName: string
    initialValues: { name: string; field_type: CustomFieldType; formula: string }
    handleClose: () => void
    selectedId?: number
    v2?: boolean
    children?: React.ReactNode
    selectedData?: IQueryData
  }
>(
  (
    { modelName, initialValues, handleClose, selectedId, v2 = false, children, selectedData },
    ref
  ) => {
    const fieldsSelectorRef = useRef<IFieldSelectorHandles>(null)
    const submitButtonRef = useRef<HTMLButtonElement>(null)

    useImperativeHandle(ref, () => ({
      submit: () => {
        submitButtonRef.current?.click()
      }
    }))

    const { mutateAsync: add } = useAddCustomField()
    const { mutateAsync: update } = useUpdateCustomField()

    return (
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          const path = v2 ? _.cloneDeep(selectedData)! : fieldsSelectorRef.current?.getQueryData()!
          const body = _.assign({ path }, values)
          ;(selectedId ? update({ body, id: selectedId }) : add({ body })).then(() => handleClose())
        }}
      >
        <>
          <DialogContent dividers={!v2} className={cn(v2 && 'm-0 p-0')}>
            <Grid container spacing={v2 ? 0 : 3}>
              {!v2 && (
                <>
                  <FieldsSelector ref={fieldsSelectorRef} modelName={modelName} />
                  <Divider orientation='vertical' flexItem sx={{ mr: '-1px' }} />
                </>
              )}
              <Grid item xs={12} md={v2 ? 12 : 8}>
                <QueryComponent v2={v2}>{children}</QueryComponent>
                {!v2 && <TogglePath />}
              </Grid>
            </Grid>
          </DialogContent>
          {v2 ? (
            <Submit hide ref={submitButtonRef} />
          ) : (
            <DialogActions>
              <Button color='error' onClick={handleClose}>
                Cancel
              </Button>
              <Submit />
            </DialogActions>
          )}
        </>
      </Formik>
    )
  }
)

const Submit = forwardRef<HTMLButtonElement, { hide?: boolean }>(function Submit(
  { hide = false },
  ref
) {
  const { submitForm } = useFormikContext()
  return (
    <Button
      ref={ref}
      variant='contained'
      onClick={() => submitForm()}
      sx={{ mr: 1, display: hide ? 'none' : undefined }}
    >
      Submit
    </Button>
  )
})
