export const CURRENCIES = [
  {
    code: 'CAD',
    name: 'Canadian dollar'
  },
  {
    code: 'GBP',
    name: 'Pound sterling'
  },
  {
    code: 'USD',
    name: 'United States dollar'
  },
  {
    code: 'BRL',
    name: 'Brazilian real'
  },
  {
    code: 'EUR',
    name: 'Euro'
  }
]
