import { useRef } from 'react'

import { motion } from 'framer-motion'

import { humanizeFieldName } from '@utils/string-utils'
import { cn } from '@utils/style-utils'

import Button from '@core/button'
import { Skeleton } from '@core/skeleton'

import { DataPill } from '@components/component-management/DataPill'

import { associationsPath } from '@store/slices/component/query-config'

import { Panel } from 'pages/component-management/common/panel'
import Form, {
  IFormHandles
} from 'pages/component-management/query-builder/add-or-configure-field/form'
import {
  CustomFieldType,
  IAssociation,
  IColumnData,
  ICustomField,
  IField,
  IQueryData
} from 'pages/component-management/types/query-builder-types'

interface CustomFieldPanelProps {
  modelName: string
  data?: ICustomField
  isLoading?: boolean
  selectedCustomData?: IQueryData
  onSelect?: (column: IColumnData, parent: string) => void
  renderName?: (modelName: string, columnName?: string) => string | undefined // render prop for schema renaming
  onCancel?: () => void
  onSave?: () => void
}

export function CustomFieldPanel(props: CustomFieldPanelProps) {
  const {
    modelName = '',
    data,
    isLoading = false,
    selectedCustomData,
    onSelect = _.noop,
    renderName,
    onCancel = _.noop,
    onSave = _.noop
  } = props

  const formRef = useRef<IFormHandles>(null)

  const isEmpty =
    _.isEmpty(selectedCustomData?.fields) && _.isEmpty(selectedCustomData?.associations)

  const initialValues = {
    name: _.get(data, 'name', 'untitled_custom_field'),
    field_type: _.get(data, 'field_type', CustomFieldType.TEXT),
    formula: _.get(data, 'formula', '')
  }

  return (
    <motion.div
      initial={{ x: 100, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      transition={{ type: 'spring', stiffness: 70, damping: 12, mass: 1 }}
    >
      <Panel
        className='h-full min-w-[26rem] max-w-[26rem] border-2 border-primary'
        title={data?.id ? 'Edit Custom Field' : 'Add Custom Field'}
        secondary={
          <div className='flex gap-2'>
            <Button variant='outline' size='small' onClick={() => onCancel()}>
              Cancel
            </Button>
            <Button
              variant='primary'
              size='small'
              onClick={() => formRef.current?.submit()}
              disabled={isLoading}
            >
              Save
            </Button>
          </div>
        }
      >
        {isLoading ? (
          <Loading />
        ) : (
          <Form
            ref={formRef}
            modelName={modelName}
            initialValues={initialValues}
            handleClose={() => onSave()}
            selectedId={data?.id}
            v2
            selectedData={selectedCustomData}
          >
            {isEmpty ? (
              <Empty />
            ) : (
              <div className='-mt-2 mb-2'>
                <FieldAndAssociations
                  data={selectedCustomData}
                  baseModelName={modelName}
                  onSelect={onSelect}
                  renderName={renderName}
                />
              </div>
            )}
          </Form>
        )}
      </Panel>
    </motion.div>
  )
}

function Loading() {
  return (
    <div className='flex flex-col'>
      <div className='flex justify-between gap-2'>
        <Skeleton className='-my-2 h-12 w-full' />
        <Skeleton className='-my-2 h-12 w-[150px]' />
      </div>
      <div className='mt-2 h-[150px]'>
        <Skeleton className='size-full scale-y-100' />
      </div>
      <div className='mt-2 h-[300px]'>
        <Skeleton className='size-full scale-y-100' />
      </div>
    </div>
  )
}

function Empty() {
  return (
    <>
      <div className='mb-2 text-inline-form-title'>Referenced Fields (Select to add)</div>
      <div className='flex items-center justify-center rounded border border-dashed border-grey px-6 py-12 text-center text-body italic text-grey-dark'>
        Select fields from the left that you are referencing in your code.
      </div>
    </>
  )
}

function FieldAndAssociations({
  data = {},
  baseModelName = '',
  parent = '',
  onSelect = _.noop,
  renderName
}: {
  data: IAssociation | IQueryData | undefined
  baseModelName?: string
  parent?: string
  onSelect?: (column: IColumnData, parent: string) => void
  renderName?: (modelName: string, columnName?: string) => string | undefined
}) {
  if (_.isEmpty(data)) return null

  const { fields, associations } = data
  const associationNames = Object.keys(associations || {})

  const pathSplit = _.split(parent, '.')
  const isBaseModel = pathSplit.length <= 2
  const modelName = _.last(pathSplit) || baseModelName

  return (
    <>
      {!_.isEmpty(fields) && (
        <>
          <div
            className={cn(
              isBaseModel
                ? 'my-2 border-x-0 border-b border-t-0 border-solid border-grey-lighter py-1 text-inline-form-title'
                : 'mb-1 text-inline-form-title'
            )}
          >
            {humanizeFieldName(renderName?.(modelName) || modelName)}
          </div>
          <div className='flex flex-col gap-2'>
            {_.map(fields, (field) => (
              <Field
                key={field.name}
                field={field}
                onDelete={() => onSelect(field, parent)}
                displayName={modelName ? renderName?.(modelName, field.name) : undefined}
              />
            ))}
          </div>
        </>
      )}
      {associationNames.map((name) => {
        return (
          <div key={name} className={cn(parent && 'ml-2 mt-2')}>
            <FieldAndAssociations
              data={associations?.[name]}
              parent={`${associationsPath(parent)}.${name}`}
              onSelect={onSelect}
              renderName={renderName}
            />
          </div>
        )
      })}
    </>
  )
}

function Field({
  field,
  onDelete = _.noop,
  displayName
}: {
  field: IField
  onDelete?: () => void
  displayName?: string
}) {
  const label = humanizeFieldName(displayName || field.name)
  return (
    <div className='flex items-center justify-between'>
      <DataPill
        label={label}
        dataType={field.field_type}
        isForeignKey={!!field.association_name}
        isId={field.name === 'id'}
        isCustomField={!!field.custom}
        onDelete={onDelete}
      />
    </div>
  )
}
