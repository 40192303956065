import { type ClassValue, clsx } from 'clsx'
import _ from 'lodash'
import { extendTailwindMerge } from 'tailwind-merge'

import { chargerColors } from '../themes/theme/charger-colors-tw'
import { fontSizes } from '../themes/theme/font-sizes'
import { getNestedKeysWithSeparator } from './obj-utils'

export const FAILURE_ROW_COLOR = '#ffebee'

// Extract all color keys dynamically
const colorKeys = getNestedKeysWithSeparator(chargerColors, '', '-')

const extendedTwMerge = extendTailwindMerge({
  override: {
    theme: {
      colors: colorKeys
    },
    classGroups: {
      'font-size': _.keys(fontSizes)
    }
  }
})

export function cn(...inputs: ClassValue[]) {
  return extendedTwMerge(clsx(inputs))
}
