import { useState } from 'react'

import { Box, Divider, Stack, Switch, Tooltip, Typography } from '@mui/material'

import { getDisplayName } from '@utils/schema-renaming'

import { useDispatch, useSelector } from '@store/index'
import {
  selectCurrentSelectedModel,
  selectShowForeignKeys,
  toggleShowForeignKeys
} from '@store/slices/component/query-config'

import { useFetchRenamings } from '@pages/configuration/queries/fetch-renamings'

import { useFetchModels } from '../../queries/fetch-models'
import { useFetchModelsDetail } from '../../queries/fetch-models-detail'
import { FieldsPanel } from './fields-tab-panel'

export default function ModelFieldsSelector() {
  const dispatch = useDispatch()
  const currentSelectedModel = useSelector(selectCurrentSelectedModel())

  const { data, isLoading } = useFetchModels()
  const { data: detailData, isLoading: detailLoading } = useFetchModelsDetail(
    currentSelectedModel || ''
  )

  const [tableNav, setTableNav] = useState<'root' | 'model'>(
    currentSelectedModel ? 'model' : 'root'
  )
  const navigateToRoot = () => setTableNav('root')
  const navigateToModel = () => setTableNav('model')
  const rootSelected = tableNav === 'root'
  const modelSelected = tableNav === 'model'

  const { data: renamingsData } = useFetchRenamings(true)
  const renamings = renamingsData || {}

  const renamedModel = getDisplayName(renamings, currentSelectedModel || '', undefined, false)

  const showForeignKeys = useSelector(selectShowForeignKeys)

  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      <Stack direction='column' height={'100%'}>
        <Box sx={{ position: 'sticky', top: '60px' }}>
          <Stack
            direction='row'
            spacing={1}
            pb={1}
            justifyContent='space-between'
            alignItems='center'
          >
            <Stack direction='row' spacing={1}>
              <Typography
                variant='h5'
                gutterBottom
                onClick={navigateToRoot}
                sx={{ cursor: modelSelected ? 'pointer' : 'default' }}
              >
                TABLES
              </Typography>
              {!!currentSelectedModel && (
                <>
                  <Typography variant='h5' gutterBottom>
                    /
                  </Typography>
                  <Typography
                    variant='h5'
                    gutterBottom
                    overflow='hidden'
                    onClick={navigateToModel}
                    sx={{ cursor: rootSelected ? 'pointer' : 'default' }}
                  >
                    {renamedModel
                      ? `${renamedModel} (${currentSelectedModel})`
                      : currentSelectedModel}
                  </Typography>
                </>
              )}
            </Stack>
            <Tooltip title='Show foreign keys' placement='top'>
              <Switch
                checked={showForeignKeys}
                onChange={() => dispatch(toggleShowForeignKeys())}
              />
            </Tooltip>
          </Stack>

          <Divider orientation='horizontal' flexItem />
          {isLoading || detailLoading ? (
            <Typography variant='subtitle2'>Loading...</Typography>
          ) : (
            <FieldsPanel
              {...{
                models: data,
                detailData,
                currentSelectedModel,
                rootSelected,
                modelSelected,
                navigateToModel,
                navigateToRoot
              }}
            />
          )}
        </Box>
        <Box sx={{ flexGrow: 1 }} />
      </Stack>
    </Box>
  )
}
