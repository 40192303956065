import { useDispatch } from 'react-redux'

import MenuIcon from '@mui/icons-material/Menu'

import { CommonTimeZoneList } from '@utils/TimezoneList'

import { List } from '@core/list'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@core/select'

import Tooltip from '@components/core/tooltip'

import useAuth from '@hooks/useAuth'

import { usePageDispatch, usePageSelector, useSelector } from '@store/index'
import { selectTimeZone, updateTimeZone } from '@store/slices/action-bar'
import {
  selectUserBusinessTimeZone,
  updateUserBusinessTimezone
} from '@store/slices/user-preferences'

import ListSelector from '../selection/list-selector'
import {
  DropDownFilterProps,
  FilterDisplayModeEnum,
  SelectControlMode,
  SelectControlModeEnum,
  TimeZoneAccessModeEnum,
  TimeZoneDefault,
  isFilterDisplayModeList
} from './types'

interface TimezoneToggleProps extends DropDownFilterProps {
  mode?: SelectControlMode
  value?: string
  handleChange?: (newValue: string) => void
  accessMode?: TimeZoneAccessModeEnum
}

const TimeZoneToggle = ({
  accessMode = TimeZoneAccessModeEnum.global,
  labelName = 'Time Zone',
  mode = SelectControlModeEnum.select,
  handleChange,
  filterDisplayMode = FilterDisplayModeEnum.default,
  value
}: TimezoneToggleProps) => {
  const { user } = useAuth()

  const dispatch = useDispatch()
  const pageDispatch = usePageDispatch()

  const pageTimeZone = usePageSelector(selectTimeZone)
  const globalTimeZone = useSelector(selectUserBusinessTimeZone)

  const timeZone =
    (accessMode === TimeZoneAccessModeEnum.global ? globalTimeZone : pageTimeZone) ||
    value ||
    TimeZoneDefault

  const onChange = (newTimeZone: string) => {
    if (handleChange) {
      handleChange(newTimeZone)
    }

    if (accessMode === TimeZoneAccessModeEnum.global) {
      dispatch(updateUserBusinessTimezone(newTimeZone))
    } else {
      pageDispatch(updateTimeZone({ timeZone: newTimeZone }))
    }
  }

  const options = _.map(CommonTimeZoneList, (option) => {
    if (option.value === user?.business_local_timezone) {
      return {
        ...option,
        label: `Business HQ: ${option.value}`
      }
    }
    return option
  })

  const sortedOptions = _.sortBy(options, (option) =>
    option.label.includes('Business HQ') ? -1 : 1
  )

  if (isFilterDisplayModeList(filterDisplayMode)) {
    return (
      <ListSelector
        options={options}
        onChange={(value) => onChange(value as string)}
        label={labelName}
        selectedValue={timeZone}
        rightIndicatorChild={<MenuIcon />}
      />
    )
  } else {
    return (
      <Tooltip title={labelName}>
        <Select
          className='min-w-0 flex-1'
          value={timeZone}
          onValueChange={(value: any) => onChange(value)}
        >
          <SelectTrigger className='border-none px-4 shadow-none focus:ring-0 [&>svg]:text-primary'>
            <SelectValue placeholder='Select' />
          </SelectTrigger>
          <SelectContent className='screen max-h-[80vh] rounded-t-none shadow-brand'>
            <List>
              {sortedOptions.map((option) => (
                <SelectItem
                  className={'h-10 w-80 text-primary-darker'}
                  key={option.value}
                  value={option.value.toString()}
                  checkIconClassName='text-primary'
                >
                  {_.get(option, 'label')}
                </SelectItem>
              ))}
            </List>
          </SelectContent>
        </Select>
      </Tooltip>
    )
  }
}

export default TimeZoneToggle
