import { useEffect, useMemo, useRef } from 'react'

import { ColDef, FilterModel, IServerSideDatasource } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'

import { useQueryClient } from '@tanstack/react-query'

import { humanizeFieldName } from '@utils/string-utils'

import AgGrid from '@components/ag-grid'

import useAuth from '@hooks/useAuth'

import { fetchModelPreview } from '../queries/fetch-model-preview'
import { isDataTypeBoolean, isDataTypeDate, isDataTypeNumeric } from '../types/query-builder-types'

const initialColDef: ColDef[] = []

export function ModelPreview({ modelName }: { modelName: string }) {
  const queryClient = useQueryClient()
  const { user } = useAuth()
  const initialFetchRef = useRef(true)
  useEffect(() => {
    initialFetchRef.current = true
  }, [modelName])
  const gridRef = useRef<AgGridReact>(null)

  const dataSource: IServerSideDatasource = useMemo(() => {
    // DO NOT Carry the filter, sort and column state when currentModel is updated
    gridRef.current?.api.resetColumnState()
    gridRef.current?.api.setFilterModel(null)

    return {
      getRows: (params) => {
        const businessId = user?.business_id
        if (!businessId) return

        const { startRow, endRow, filterModel, sortModel } = params.request

        const simpleFilterModel = filterModel as FilterModel

        const limit = endRow! - startRow!
        const page = Math.floor(startRow! / limit) + 1

        queryClient
          .fetchQuery({
            queryKey: [
              'fetch-model-preview',
              businessId,
              modelName,
              { filterModel: simpleFilterModel, sortModel, limit, page }
            ],
            queryFn: () =>
              fetchModelPreview(businessId, modelName, {
                filterModel: simpleFilterModel,
                sortModel,
                limit,
                page
              })
          })
          .then((response) => {
            if (initialFetchRef.current) {
              initialFetchRef.current = false
              params.api.setGridOption(
                'columnDefs',
                _.map(response.headers, (header) => {
                  let cellDataType
                  if (isDataTypeNumeric(header.type)) {
                    cellDataType = 'number'
                  } else if (isDataTypeDate(header.type)) {
                    cellDataType = 'dateString'
                  } else if (isDataTypeBoolean(header.type)) {
                    cellDataType = 'boolean'
                  } else {
                    cellDataType = 'text'
                  }

                  let filter: string
                  if (isDataTypeNumeric(header.type)) {
                    filter = 'agNumberColumnFilter'
                  } else if (isDataTypeDate(header.type)) {
                    filter = 'agDateColumnFilter'
                  } else if (isDataTypeBoolean(header.type)) {
                    filter = 'agSetColumnFilter'
                  } else {
                    filter = 'agTextColumnFilter'
                  }

                  return _.assign(
                    {
                      headerName: humanizeFieldName(_.replace(header.name, '.label', '')),
                      field: header.name,
                      cellDataType,
                      filter
                    },
                    isDataTypeBoolean(header.type) && {
                      filterParams: { filterOptions: ['true', 'false', 'blank'] }
                    }
                  )
                })
              )
            }
            params.success({ rowData: response.data, rowCount: response.count })
          })
          .catch(() => {
            params.fail()
          })
      }
    }
  }, [queryClient, user?.business_id, modelName])

  return (
    <div className='size-full p-2'>
      <AgGrid
        ref={gridRef}
        rowModelType='serverSide'
        serverSideDatasource={dataSource}
        style={{ height: '100%' }}
        columnDefs={initialColDef} // Because we set the columnDefs in the getRows callback, we need to set an empty array here
        cacheBlockSize={100}
        cacheOverflowSize={5}
        maxConcurrentDatasourceRequests={1}
        infiniteInitialRowCount={100}
        maxBlocksInCache={10}
        suppressFieldDotNotation
        defaultColDef={{
          floatingFilter: true
        }}
        suppressServerSideFullWidthLoadingRow
        autoSizeStrategy={{ type: 'fitCellContents' }}
      />
    </div>
  )
}
