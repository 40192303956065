import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'

import { AgGridReact } from 'ag-grid-react'

import { useDndMonitor } from '@dnd-kit/core'

import { AUTO_FIT_TIMEOUT_MS } from '@components/ag-grid/index'
import { autoFitColumnsWithAPI } from '@components/ag-grid/utils'
import Button from '@components/core/button'
import { useFetchHierarchies } from '@components/financial/shared/queries/fetch-hierarchies'

import useAuth from '@hooks/useAuth'

import SkeletonTable from 'components/ag-grid/skeleton-table'
import {
  ChartOfAccountDisplayModes,
  FinancialHierarchy,
  FinancialHierarchyGlAccountNode,
  FinancialHierarchyNode,
  GeneralLedgerAccount
} from 'components/financial/types'
import { CHARGER_MONOSPACE_CLASS } from 'config'

import { useBulkTogglePosting } from '../queries/bulk-toggle-posting'
import { useFetchAutoMapped } from '../queries/fetch-automapped'
import { useFetchGlAccountNodes } from '../queries/fetch-gl-account-nodes'
import { useFetchGlAccountsInHierarchy } from '../queries/fetch-gl-accounts-in-hierarchy'
import { useFetchUnmapped } from '../queries/fetch-unmapped'
import { useRemoveMappings } from '../queries/remove-mappings'
import { useTriggerAutomap } from '../queries/trigger-automap'
import ConfirmRemoveMapping from './confirm-remove-mapping'
import { DraggableGeneralLedgerAccounts } from './draggable-gl-accounts'
import { ConfigureTrialBalanceContext } from './working-hierarchy'

export const DetailsPanel = ({
  selectedNode,
  mode,
  hierarchyId
}: {
  selectedNode: FinancialHierarchyNode | undefined
  mode: ChartOfAccountDisplayModes
  hierarchyId: number
}) => {
  const { isPrimaryBusinessCharger } = useAuth()

  return (
    <div className='flex-1'>
      {mode === ChartOfAccountDisplayModes.unmapped && (
        <UnmappedDetails hierarchyId={hierarchyId} />
      )}
      {mode === ChartOfAccountDisplayModes.automapped && isPrimaryBusinessCharger() && (
        <AutoMappedDetails hierarchyId={hierarchyId} />
      )}
      {mode === ChartOfAccountDisplayModes.nonposting && (
        <NonPostingDetails hierarchyId={hierarchyId} />
      )}
      {mode === ChartOfAccountDisplayModes.mapped &&
        (selectedNode ? (
          <SelectedNodeDetails selectedNode={selectedNode} hierarchyId={hierarchyId} />
        ) : (
          <GlAccountsInHierarchy hierarchyId={hierarchyId} />
        ))}
    </div>
  )
}

const GlAccountsInHierarchy = ({ hierarchyId }: { hierarchyId: number }) => {
  const { isLoading, data } = useFetchGlAccountsInHierarchy(hierarchyId)

  if (isLoading) {
    return (
      <div className='relative size-full'>
        <SkeletonTable />;
      </div>
    )
  }

  return (
    <>
      <GeneralLedgerListWithSelectedForDrag
        mode={ChartOfAccountDisplayModes.mapped}
        rowData={data as FinancialHierarchyGlAccountNode[]}
        hierarchyId={hierarchyId}
      />
    </>
  )
}

const SelectedNodeDetails = ({
  selectedNode,
  hierarchyId
}: {
  selectedNode: FinancialHierarchyNode
  hierarchyId: number
}) => {
  const { isLoading, data } = useFetchGlAccountNodes(selectedNode)

  if (isLoading) {
    return (
      <div className='relative size-full'>
        <SkeletonTable />;
      </div>
    )
  }

  return (
    <div className='flex'>
      <GeneralLedgerListWithSelectedForDrag
        rowData={data as FinancialHierarchyGlAccountNode[]}
        hierarchyId={hierarchyId}
        mode={ChartOfAccountDisplayModes.mapped}
        selectedNode={selectedNode}
      />
    </div>
  )
}

const UnmappedDetails = ({ hierarchyId }: { hierarchyId: number }) => {
  const { isLoading, data } = useFetchUnmapped(hierarchyId)

  const rowData = useMemo(() => {
    if (!data) return []

    return data.filter((item: GeneralLedgerAccount) => item.is_posting)
  }, [data])

  if (isLoading) {
    return (
      <div className='relative size-full'>
        <SkeletonTable />;
      </div>
    )
  }

  return (
    <div className='flex'>
      <GeneralLedgerListWithSelectedForDrag
        rowData={rowData as GeneralLedgerAccount[]}
        mode={ChartOfAccountDisplayModes.unmapped}
        hierarchyId={hierarchyId}
        isPosting={true}
      />
    </div>
  )
}

const AutoMappedDetails = ({ hierarchyId }: { hierarchyId: number }) => {
  const { isLoading, data: rowData } = useFetchAutoMapped(hierarchyId)

  if (isLoading) {
    return (
      <div className='relative size-full'>
        <SkeletonTable />;
      </div>
    )
  }

  return (
    <div className='flex flex-col'>
      <GeneralLedgerListWithSelectedForDrag
        rowData={rowData as FinancialHierarchyGlAccountNode[]}
        mode={ChartOfAccountDisplayModes.automapped}
        hierarchyId={hierarchyId}
      />
    </div>
  )
}

const NonPostingDetails = ({ hierarchyId }: { hierarchyId: number }) => {
  const { isLoading, data } = useFetchUnmapped(hierarchyId)

  const rowData = useMemo(() => {
    if (!data) return []

    return data.filter((item: GeneralLedgerAccount) => !item.is_posting)
  }, [data])

  if (isLoading) {
    return (
      <div className='relative size-full'>
        <SkeletonTable />;
      </div>
    )
  }

  return (
    <div className='flex'>
      <GeneralLedgerListWithSelectedForDrag
        rowData={rowData as GeneralLedgerAccount[]}
        mode={ChartOfAccountDisplayModes.unmapped}
        hierarchyId={hierarchyId}
        isPosting={false}
        showDragAccounts={false}
      />
    </div>
  )
}

const GeneralLedgerListWithSelectedForDrag = ({
  rowData,
  mode,
  hierarchyId,
  selectedNode,
  isPosting,
  showDragAccounts = true
}: {
  rowData: GeneralLedgerAccount[] | FinancialHierarchyGlAccountNode[]
  mode: ChartOfAccountDisplayModes
  hierarchyId?: number
  selectedNode?: FinancialHierarchyNode
  isPosting?: boolean
  showDragAccounts?: boolean
}) => {
  const [openRemoveMappingConfirmation, setOpenRemoveMappingConfirmation] = useState(false)
  const [selectedItems, setSelectedItems] = useState<
    FinancialHierarchyGlAccountNode[] | GeneralLedgerAccount[]
  >([])
  const { mutate: triggerAutomap } = useTriggerAutomap()
  const hierarchies = useFetchHierarchies()

  const selectedHierarchy = useMemo(() => {
    if (!hierarchyId || !hierarchies.data) return null

    return hierarchies.data.find(
      (hierarchy: any) => hierarchy.id === hierarchyId
    ) as FinancialHierarchy
  }, [hierarchyId, hierarchies.data])

  const { editingTrialBalance } = useContext(ConfigureTrialBalanceContext)
  const gridRef = useRef<AgGridReact | null>(null)

  useEffect(() => {
    if (editingTrialBalance) {
      setSelectedItems([])
      gridRef.current?.api?.deselectAll()
    }
  }, [editingTrialBalance])

  const { mutate: removeMappings } = useRemoveMappings()
  const { mutate: bulkTogglePosting } = useBulkTogglePosting()

  const isUnmappedMode = useMemo(() => mode === ChartOfAccountDisplayModes.unmapped, [mode])
  const isMappedMode = useMemo(() => mode === ChartOfAccountDisplayModes.mapped, [mode])
  const isAutomapped = useMemo(() => mode === ChartOfAccountDisplayModes.automapped, [mode])

  const columnDefs = useMemo(() => {
    const result = [
      {
        checkboxSelection: !editingTrialBalance,
        headerCheckboxSelection: !editingTrialBalance,
        headerCheckboxSelectionFilteredOnly: true,
        showDisabledCheckboxes: true,
        maxWidth: 64,
        pinned: true
      },
      {
        field: isUnmappedMode
          ? 'source_system_account_id'
          : 'general_ledger_account.source_system_account_id',
        headerName: 'Source Account Id',
        pinned: true,
        filter: 'agTextColumnFilter',
        sortable: true,
        floatingFilter: true,
        cellClass: CHARGER_MONOSPACE_CLASS
      },
      {
        field: isUnmappedMode ? 'name' : 'general_ledger_account.name',
        headerName: 'Name',
        filter: 'agTextColumnFilter',
        sortable: true,
        floatingFilter: true
      },
      {
        field: isUnmappedMode
          ? 'connected_source_system_name'
          : 'general_ledger_account.connected_source_system_name',
        headerName: 'Source Name',
        sortable: true
      },
      {
        field: isUnmappedMode ? 'classification' : 'general_ledger_account.classification',
        headerName: 'Classification',
        sortable: true
      },
      isUnmappedMode || isAutomapped
        ? null
        : { field: 'is_general_ledger_account_confirmed', headerName: 'Confirmed', filter: true },
      mode === ChartOfAccountDisplayModes.mapped && selectedNode
        ? { field: 'sort_order', headerName: 'Sort Order' }
        : null,
      isUnmappedMode
        ? null
        : {
            field: 'path',
            headerName: 'Path',
            filter: true,
            sortable: true,
            valueFormatter: ({ value }: { value: string }) =>
              value.split('.').slice(0, -1).join(' / ')
          }
      // Not useful to users, useful for debugging
      // isUnmappedMode ? null : { field: 'id', headerName: 'Node Id', maxWidth: 80, sortable: true },
      // {
      //   field: isUnmappedMode ? 'id' : 'general_ledger_account.id',
      //   headerName: 'GL Account Id',
      //   maxWidth: 150,
      //   sortable: true
      // }
    ]

    return _.compact(result)
  }, [isUnmappedMode, isAutomapped, mode, selectedNode, editingTrialBalance])

  const handleSelectionChanged = useCallback(() => {
    const selectedRows = gridRef.current!.api.getSelectedRows()
    setSelectedItems(selectedRows)
  }, [])

  const selectedGlAccountsForDrag = useMemo(() => {
    if (mode === ChartOfAccountDisplayModes.unmapped) {
      return selectedItems
    }

    return selectedItems.map((item: any) => item.general_ledger_account)
  }, [selectedItems, mode])

  useDndMonitor({
    onDragEnd(event) {
      const droppedNodeIdentifier = event.over?.data.current?.identifier // This is added manually to the droppable component ReadOnlyTree

      if (event.over && droppedNodeIdentifier !== 'formula') {
        setSelectedItems([])
      }
    }
  })

  return (
    <div className='flex w-full flex-col'>
      <div className='my-3 flex items-center gap-2'>
        {showDragAccounts && (
          <DraggableGeneralLedgerAccounts glAccounts={selectedGlAccountsForDrag} />
        )}
        {(isMappedMode || isAutomapped) && selectedItems.length > 0 && (
          <>
            <Button
              onClick={() => setOpenRemoveMappingConfirmation(true)}
              size='small'
              variant='outline'
              className='h-8'
            >
              Remove {selectedItems.length} Mapping
            </Button>
          </>
        )}

        {isAutomapped && (
          <div className='flex flex-1 justify-end'>
            <Button
              size='small'
              className='h-8'
              onClick={() => {
                const coaTypeMap = {
                  income_statement: 'Income Statement',
                  balance_sheet: 'Balance Sheet',
                  cash_flow: null
                }
                const coaType = coaTypeMap[selectedHierarchy!.purpose]

                if (!coaType) return

                triggerAutomap({
                  coaType: coaType as 'Income Statement' | 'Balance Sheet',
                  hierarchyId: hierarchyId as number
                })
              }}
            >
              Auto Map
            </Button>
          </div>
        )}
        {isUnmappedMode && (
          <>
            {selectedItems.length > 0 && (
              <Button
                variant='outline'
                onClick={() => {
                  bulkTogglePosting({
                    glAccountIds: selectedItems
                      .map((item) => item.id)
                      .filter((id): id is number => id !== undefined),
                    isPostingConfirmed: !isPosting
                  })
                }}
                size='small'
                className='my-1'
              >
                Mark {selectedItems.length} as {isPosting ? 'Non-Posting' : 'Posting'}
              </Button>
            )}
          </>
        )}
      </div>

      <div className='ag-theme-alpine h-[calc(100vh-230px)] w-full'>
        <AgGridReact
          ref={gridRef}
          rowData={rowData || []}
          columnDefs={columnDefs}
          rowSelection='multiple'
          suppressRowClickSelection={true}
          enableRangeSelection={true}
          rowDragManaged={true}
          rowDragMultiRow={true}
          animateRows={true}
          clipboardDelimiter={`,  \t`}
          loadingOverlayComponent={SkeletonTable}
          loadingOverlayComponentParams={{
            rowLength: 4
          }}
          onSelectionChanged={handleSelectionChanged}
          groupDisplayType='groupRows'
          suppressMovableColumns={true}
          onGridReady={() => gridRef.current?.api.autoSizeAllColumns()}
          defaultColDef={{ resizable: true }}
          columnMenu='legacy'
          autoSizeStrategy={{ type: 'fitCellContents' }}
          onRowDataUpdated={(params) => {
            setTimeout(() => {
              autoFitColumnsWithAPI(params.api)
            }, AUTO_FIT_TIMEOUT_MS)
          }}
        />

        <ConfirmRemoveMapping
          open={openRemoveMappingConfirmation}
          setOpen={setOpenRemoveMappingConfirmation}
          nodeCount={selectedItems.length}
          onConfirm={(markAsNonPosting = false) => {
            removeMappings({
              selectedNodeId: selectedNode?.id,
              hierarchyId: hierarchyId as number,
              nodeIds: selectedItems.map((item) => item.id) as number[],
              markAsNonPosting: markAsNonPosting
            })
            setSelectedItems([])
            setOpenRemoveMappingConfirmation(false)
          }}
        />
      </div>
    </div>
  )
}
