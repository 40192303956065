import type { ColDef } from 'ag-grid-community'

export enum ModelType {
  table = 'table',
  kpi = 'kpi',
  custom_sql_query = 'custom_sql_query'
}

export enum NoneType {
  NONE = '-'
}

export enum CutoffDateOptions {
  CURRENT_DATE = 'current_date',
  START_DATE = 'start_date',
  END_DATE = 'end_date'
}
export type CutoffDateType = CutoffDateOptions | NoneType

export enum AggregateOptions {
  SUM = 'sum',
  AVG = 'avg',
  COUNT = 'count',
  COUNT_DISTINCT = 'count_distinct',
  MIN = 'min',
  MAX = 'max'
}
export type AggregateType = AggregateOptions | NoneType

export enum TruncateOptions {
  DATE_FREQUENCY_HOOK = 'date_frequency_hook',
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  QUARTER = 'quarter',
  YEAR = 'year'
}
export type TruncateType = TruncateOptions | NoneType

export enum ExpandLevelType {
  ZERO = '0',
  ONE = '1',
  TWO = '2'
}

export type IModel = {
  name: string
  type: ModelType
  columns: IColumnData[]
  associations: IAssociationData[]
}

export type IField = IColumnData & {
  configs: Partial<IFieldAttribute>[]
}

export type IFieldAttribute = {
  aggregate: AggregateType
  truncate: TruncateType
  is_date_hook: boolean
  as_of_date: boolean
  is_hidden: boolean
  is_year_over_year: boolean
  name_alias: string
  expand_level: ExpandLevelType
  show_total: boolean
  drilldown_field: boolean
  cutoffDate: CutoffDateType
  presence: boolean
}

export enum IModelFieldDataType {
  INTEGER = 'integer',
  FLOAT = 'float',
  DECIMAL = 'decimal',
  DATETIME = 'datetime',
  DATE = 'date',
  TIMESTAMP = 'timestamp',
  STRING = 'string',
  ENUM = 'enum',
  TEXT = 'text',
  BOOLEAN = 'boolean',
  HEADING = 'heading',
  FORMULA = 'formula'
}

export const isDataTypeNumeric = (type: IModelFieldDataType) =>
  _.includes(['integer', 'float', 'decimal', 'number'], type)
export const isDataTypeDate = (type: IModelFieldDataType | ColDef['cellDataType']) =>
  _.includes(['datetime', 'date', 'timestamp', 'dateString'], type)
export const isDataTypeString = (type: IModelFieldDataType | ColDef['cellDataType']) =>
  _.includes(['string', 'enum', 'text'], type)
export const isDataTypeBoolean = (type: IModelFieldDataType | ColDef['cellDataType']) =>
  _.includes(['boolean'], type)
export const isDataTypeKpiHeading = (type: IModelFieldDataType | ColDef['cellDataType']) =>
  _.includes(['heading'], type)
export const isDataTypeKpiFormula = (type: IModelFieldDataType | ColDef['cellDataType']) =>
  _.includes(['formula'], type)
export const isDataTypeKpi = (type: IModelFieldDataType | ColDef['cellDataType']) =>
  isDataTypeKpiHeading(type) || isDataTypeKpiFormula(type)

interface BaseData {
  fields?: IField[]
  associations?: IAssociations
}

export interface IQueryData extends BaseData {
  model: string
  modelType?: ModelType
  customSqlQuery?: string
}

export type IAssociations = { [key: string]: IAssociation }

export type IAssociation = BaseData

export interface IFormulaDefinition {
  name: string
  data_type: string
  formula: string
}

export type QueryBuilderPlacement = 'custom-field'

export interface IColumnData {
  name: string
  field_type: IModelFieldDataType | any
  custom?: boolean
  id?: number
  association_name?: string
  property?: boolean
}

export interface IAssociationData {
  name: string
  field_type: string
  model: string
  columns: IColumnData[]
  associations: IAssociationData[]
}

export interface IColumnRenamingDetail {
  id?: number
  newName: string
  columnName: string
}

export interface IModelRenamingDetail {
  id?: number
  modelName: string
  newName: string
  schemaColumnRenamings: IColumnRenamingDetail[]
}
export interface IModelDetail {
  columns: IColumnData[]
  associations: IAssociationData[]
}

export enum CustomFieldType {
  TEXT = 'text',
  NUMBER = 'number',
  DATE = 'date',
  BOOLEAN = 'boolean'
}

export interface ICustomField {
  id: number
  name: string
  field_type: CustomFieldType
  table_name?: string
  formula: string
  path: IQueryData
}

export interface IFieldSelectorHandles {
  getQueryData: () => IQueryData
}

type ParameterNameString = string
type HeaderNameString = string
export type IParameterizedConfig = Record<ParameterNameString, HeaderNameString>

export interface IDataTab {
  modelName: string
  associationName: string
}
