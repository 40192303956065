import { createSlice } from '@reduxjs/toolkit'

import { rehydrateStore } from '@store/actions/rehydrate-store'
import { RootState } from '@store/index'
import { HANDLE_BUSINESS_SELECTOR_CHANGE, LOGIN } from '@store/reducers/actions'

import { AuthActionProps } from '../../types/auth'

interface UserPreference {
  userBusinessTimezone: string
}

const initialState: UserPreference = {
  userBusinessTimezone: ''
}

const userPreferenceSlice = createSlice({
  name: '@USER_PREFERENCE',
  initialState,
  reducers: {
    updateUserBusinessTimezone: (state, action) => {
      state.userBusinessTimezone = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(rehydrateStore, (_state, action) => {
      const userPreferences =
        action.payload?.userPreferences || { userBusinessTimezone: _state.userBusinessTimezone } ||
        initialState

      return {
        ..._state,
        ...userPreferences
      }
    })

    builder.addMatcher(
      (action) => [LOGIN, HANDLE_BUSINESS_SELECTOR_CHANGE].includes(action.type),
      (state, _action: AuthActionProps) => {
        const businessLocalTimezone = _action.payload?.user?.business_local_timezone
        const existingUserBusinessTimezone = state.userBusinessTimezone

        if (_action.type === LOGIN && businessLocalTimezone && !existingUserBusinessTimezone) {
          state.userBusinessTimezone = businessLocalTimezone
        }

        if (_action.type === HANDLE_BUSINESS_SELECTOR_CHANGE && businessLocalTimezone) {
          state.userBusinessTimezone = businessLocalTimezone
        }
      }
    )
  }
})

// actions
export const { updateUserBusinessTimezone } = userPreferenceSlice.actions

// reducer
export const userPreferenceReducer = userPreferenceSlice.reducer

// selectors
export const selectUserBusinessTimeZone = (state: RootState) =>
  state.userPreferences.userBusinessTimezone
