import { ListItem, ListItemButton, ListItemText } from '@mui/material'

import { getDisplayName } from '@utils/schema-renaming'

import { useDispatch, useSelector } from '@store/index'
import {
  chooseModel,
  selectCurrentSelectedModel,
  selectShowSelectedQueryDataAttrs
} from '@store/slices/component/query-config'

import { useFetchRenamings } from '@pages/configuration/queries/fetch-renamings'

import { ModelType } from '../../types/query-builder-types'

const ModelAccordian = function ModelChooser({
  modelName,
  modelType,
  onSelected
}: {
  modelName: string
  modelType: ModelType
  onSelected: () => void
}) {
  const dispatch = useDispatch()
  const currentSelectedModel = useSelector(selectCurrentSelectedModel())

  const showSelected = useSelector(selectShowSelectedQueryDataAttrs)

  const selected = currentSelectedModel === modelName

  const { data: renamingsData } = useFetchRenamings(true)
  const renamings = renamingsData || {}

  const renamedModel = getDisplayName(renamings, modelName, undefined, false)

  const displayModelName = renamedModel ? `${renamedModel} (${modelName})` : modelName

  if (showSelected && modelName !== currentSelectedModel) {
    return null
  }

  return (
    <ListItem disablePadding divider>
      <ListItemButton
        selected={selected}
        onClick={() => {
          dispatch(chooseModel({ modelName, modelType }))
          onSelected()
        }}
      >
        <ListItemText primary={displayModelName} />
      </ListItemButton>
    </ListItem>
  )
}

export default ModelAccordian
