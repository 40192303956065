import { useMemo } from 'react'

import { ColDef } from 'ag-grid-community'

import AgGrid from '@components/ag-grid'
import { JSONCellRenderer } from '@components/ag-grid/custom-cell-renderer/json-cell-renderer'

import {
  multiTextColumnDef,
  timeZoneDateColumnDef
} from '@layout-components/general/data-connections/utils/col-defs'

const transformStreamStatesData = (streamStatusesStreamStates: Record<string, any>) => {
  return _.map(streamStatusesStreamStates, (value, key) => ({
    tableName: key,
    ...value
  }))
}

const JobDetail = ({
  timeZone,
  streamStatusesId,
  streamStatusesStreamStates,
  gridWidth = '30%'
}: {
  timeZone: string | undefined
  streamStatusesId?: string | null
  streamStatusesStreamStates: string
  gridWidth: string
}) => {
  const jobDetailColumnDefs: ColDef[] = [
    multiTextColumnDef('status', 'Status', {
      rowGroup: true,
      hide: true
    }),

    {
      field: 'tableName',
      headerName: `Table Name ${streamStatusesId ? `(SSID: ${streamStatusesId})` : ''}`
    },
    { field: 'stats.records_read', headerName: 'Records Read' },
    { field: 'stats.retries', headerName: 'Retries' },
    { field: 'task_uuid', headerName: 'Task UUID' },
    timeZoneDateColumnDef('created_at', 'Created At', timeZone, { initialWidth: 150 }),
    {
      field: 'stats.errors',
      headerName: 'Errors',
      cellRenderer: JSONCellRenderer,
      cellRendererParams: {
        hideEmpty: true,
        title: 'Errors'
      },
      initialWidth: 200,
      maxWidth: 300
    },
    {
      field: 'attempts',
      headerName: 'Attempts',
      cellRenderer: JSONCellRenderer,
      cellRendererParams: {
        title: 'Attempts'
      },
      maxWidth: 300
    }
  ]

  const rowData = useMemo(
    () => transformStreamStatesData(JSON.parse(streamStatusesStreamStates)),
    [streamStatusesStreamStates]
  )

  return (
    <AgGrid
      className='ml-3'
      style={{ height: '100%', width: gridWidth }}
      rowData={rowData}
      columnDefs={jobDetailColumnDefs}
      defaultColDef={{
        resizable: true,
        sortable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        filterParams: {
          buttons: ['clear']
        }
      }}
      autoSizeStrategy={{
        type: 'fitCellContents'
      }}
      autoGroupColumnDef={{
        headerName: 'Status Group',
        cellRendererParams: {
          suppressCount: false
        },
        pinned: 'left'
      }}
      groupDefaultExpanded={0}
    />
  )
}

export default JobDetail
