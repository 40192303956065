import React, { useEffect, useState } from 'react'

import InfoIcon from '@mui/icons-material/Info'
import { Link } from '@mui/material'

import { awsCronExpressionValidator, humanizeCron } from '@utils/cron-utils'
import { enumToOptions } from '@utils/obj-utils'
import { cn } from '@utils/style-utils'

import { Text } from '@core/text'

import { Button } from '@components/core/button'
import { Input } from '@components/core/input'
import { Select } from '@components/form/select'

import useAuth from '@hooks/useAuth'

import { IJobSchedule, ScheduleStateEnum } from '../../queries/fetch-job-schedules'
import { useUpdateJobSchedule } from '../../queries/update-job-schedule'

interface UpdateScheduleFormProps {
  data: IJobSchedule
  onSuccess: () => void
  onCancel: () => void
}

export const UpdateScheduleForm: React.FC<UpdateScheduleFormProps> = ({
  onSuccess,
  onCancel,
  data
}) => {
  const { user } = useAuth()
  const cronPart = data.scheduleExpression.match(/cron\((.*)\)/)?.[1]
  const [cronVal, setCronVal] = useState<string>(cronPart || '')
  const [error, setError] = useState<string>('')
  const [parallelism, setParallelism] = useState<number | null>(null)
  const [replicationJobId, setReplicationJobId] = useState<number | null>(null)
  const [scheduleState, setScheduleState] = useState<ScheduleStateEnum>(data.state)

  const { mutate: updateJobSchedule } = useUpdateJobSchedule({ onSuccess })

  const scheduleTypeIsRust = data.scheduleType === 'rust'

  useEffect(() => {
    if (scheduleTypeIsRust && data.targetInput) {
      try {
        const targetData = JSON.parse(data.targetInput)
        const messageBody = JSON.parse(targetData.MessageBody)

        setParallelism(messageBody.parallelism || null)
        setReplicationJobId(messageBody.replication_job_id || null)
      } catch (err) {
        console.error('Error parsing targetInput:', err)
      }
    }
  }, [scheduleTypeIsRust, data.targetInput])

  const handleCronChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    setCronVal(value)

    try {
      awsCronExpressionValidator(value)
      setError('')
    } catch (err) {
      setError((err as Error).message)
    }
  }

  const handleSave = () => {
    const payload = {
      id: data.id,
      scheduleExpression: `cron(${cronVal})`,
      timezone: user?.business_local_timezone || 'UTC',
      parallelism: parallelism ?? null,
      replicationJobId: replicationJobId ?? null,
      state: scheduleState
    }

    updateJobSchedule(payload)
  }

  return (
    <div>
      <div className={cn('flex items-center gap-2', 'w-full justify-between')}>
        <Button onClick={onCancel} type='button' variant='outline' size={'default'}>
          {'Cancel'}
        </Button>
        <Button
          onClick={handleSave}
          disabled={!!error}
          type='button'
          variant={error ? 'disabled' : 'primary'}
          size='default'
        >
          Save
        </Button>
      </div>
      <div className='mb-2 mt-4 flex flex-col items-stretch'>
        <div className='flex items-center justify-between'>
          <Text variant='cardValue'>Update Schedule</Text>
        </div>

        <div className='flex items-center justify-between'>
          <Text variant='h6'>{data.name}</Text>
        </div>

        <div className='mt-2 flex gap-4'>
          {/*Timezone*/}
          <div className='flex-1'>
            <div className='mt-2 flex items-center justify-between'>
              <Text variant='description'>New Timezone</Text>
            </div>
            <div className='mt-2 gap-1'>
              <Input
                value={user?.business_local_timezone}
                type='text'
                className='w-full'
                disabled
              />
            </div>
          </div>

          {/*AWS Schedule State*/}
          <div className='flex-1'>
            <div className='mt-2 flex items-center justify-between'>
              <Text variant='description'>State</Text>
            </div>
            <div className='mt-2 gap-1'>
              <Select
                options={enumToOptions(ScheduleStateEnum)}
                value={scheduleState}
                onChange={(value) => setScheduleState(value as ScheduleStateEnum)}
              />
            </div>
          </div>
        </div>

        {scheduleTypeIsRust && (
          <>
            <div className='mt-2 flex gap-4'>
              {/*Parallelism */}
              <div className='flex-1'>
                <div className='mt-2 flex items-center justify-between'>
                  <Text variant='description'>Parallelism</Text>
                </div>
                <Input
                  value={parallelism ?? ''}
                  type='number'
                  onChange={(e) => setParallelism(e.target.value ? Number(e.target.value) : null)}
                  min={1}
                />
              </div>

              {/*Replication Job ID*/}
              <div className='flex-1'>
                <div className='mt-2 flex items-center justify-between'>
                  <Text variant='description'>Replication Job ID</Text>
                </div>
                <Input
                  value={replicationJobId ?? ''}
                  type='number'
                  onChange={(e) =>
                    setReplicationJobId(e.target.value ? Number(e.target.value) : null)
                  }
                />
              </div>
            </div>
          </>
        )}

        {/*Cron Expression*/}
        <div className='mb-8 flex gap-4'>
          <div className='flex-1'>
            <div className='mt-2 flex items-center justify-start'>
              <Text variant='description'>New Schedule Expression</Text>
              <Link
                className='ml-2'
                href='https://docs.aws.amazon.com/eventbridge/latest/userguide/eb-scheduled-rule-pattern.html'
                target='_blank'
                rel='noopener noreferrer'
                display='flex'
                alignItems='center'
              >
                <InfoIcon fontSize='small' />
              </Link>
            </div>
            <Input
              value={cronVal}
              type='text'
              placeholder='0 23 * * ? *'
              onChange={handleCronChange}
            />
            {error && (
              <Text variant='details' className='mt-2 text-error-darker'>
                Error: {error}
              </Text>
            )}
            <Text variant='details' className='mt-2'>
              {humanizeCron(cronVal, '')}
            </Text>
          </div>
          <div className='flex-1'></div>
        </div>
      </div>
    </div>
  )
}
