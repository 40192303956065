/* eslint-disable no-loop-func */
import { useCallback, useEffect, useState } from 'react'

import { IStatusPanelParams } from 'ag-grid-community'

import { formatNumber } from '@utils/number-utils'

import { isCellValueVisible } from 'utils/ag_grid/is-cell-value-visible'

type AggFunc = 'avg' | 'sum' | 'count'

interface OriginalTotals {
  totalAmount: number
  totalCount: number
  averageAmount: number
}

interface Props extends IStatusPanelParams {
  aggFuncs: AggFunc[]
  originalTotals: OriginalTotals
}

const StatusBar = (props: Props) => {
  const { api, aggFuncs, originalTotals } = props

  const [sum, setSum] = useState(originalTotals?.totalAmount || 0)
  const [average, setAverage] = useState(originalTotals?.averageAmount || 0)
  const [count, setCount] = useState(originalTotals?.totalCount || 0)
  const [showAvgSum, setShowAvgSum] = useState(false)

  const updateValues = useCallback(() => {
    const cellRanges = api.getCellRanges()
    const values: number[] = []
    let newCount = 0

    cellRanges?.forEach((range) => {
      if (range.startRow && range.endRow) {
        const startRow = Math.min(range.startRow.rowIndex, range.endRow.rowIndex)
        const endRow = Math.max(range.startRow.rowIndex, range.endRow.rowIndex)
        for (let rowIndex = startRow; rowIndex <= endRow; rowIndex++) {
          const rowNode = api.getDisplayedRowAtIndex(rowIndex)
          if (rowNode && isCellValueVisible(rowNode)) {
            newCount++
            range.columns.forEach((column) => {
              const value = api.getCellValue({ colKey: column, rowNode: rowNode! })
              if (_.isEqual(typeof value, 'number')) {
                values.push(value)
              }
            })
          }
        }
      }
    })

    if (_.isEqual(newCount, 0) && originalTotals?.totalCount > 0) {
      setSum(originalTotals?.totalAmount)
      setAverage(originalTotals?.averageAmount)
      setCount(originalTotals?.totalCount)
      setShowAvgSum(true)
    } else {
      const sum = values.reduce((acc, curr) => acc + curr, 0)
      const average = values.length > 0 ? sum / values.length : 0

      setSum(sum)
      setAverage(average)
      setCount(newCount)
      setShowAvgSum(values.length > 0)
    }
  }, [api, originalTotals])

  useEffect(() => {
    updateValues()
    const cellValueChangedListener = () => updateValues()
    const rangeSelectionChangedListener = () => updateValues()

    api.addEventListener('cellValueChanged', cellValueChangedListener)
    api.addEventListener('gridColumnsChanged', cellValueChangedListener)
    api.addEventListener('rangeSelectionChanged', rangeSelectionChangedListener)

    // Cleanup
    return () => {
      api.removeEventListener('cellValueChanged', cellValueChangedListener)
      api.removeEventListener('gridColumnsChanged', cellValueChangedListener)
      api.removeEventListener('rangeSelectionChanged', rangeSelectionChangedListener)
    }
  }, [api, updateValues])

  return (
    <div style={{ gap: '0.5rem', display: 'flex', justifyContent: 'space-between' }}>
      {count > 0 && (
        <>
          {aggFuncs.map((aggFunc) => {
            switch (aggFunc) {
              case 'avg': {
                return showAvgSum ? (
                  <div key={aggFunc} className='ag-status-name-value'>
                    Average: <strong>{formatNumber(average, 2)}</strong>
                  </div>
                ) : null
              }
              case 'count': {
                return (
                  <div key={aggFunc} className='ag-status-name-value'>
                    Count: <strong>{count}</strong>
                  </div>
                )
              }
              case 'sum': {
                return showAvgSum ? (
                  <div key={aggFunc} className='ag-status-name-value'>
                    Sum: <strong>{formatNumber(sum, 2)}</strong>
                  </div>
                ) : null
              }
              default:
                return null
            }
          })}
        </>
      )}
    </div>
  )
}

export default StatusBar
