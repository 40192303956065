import React, { useRef, useState } from 'react'
import { Link, useParams } from 'react-router-dom'

import { Box, Button, Input, TextareaAutosize, Typography } from '@mui/material'

import { Button as CoreButton } from '@components/core/button'
import { Popover, PopoverContent, PopoverTrigger } from '@components/core/popover'
import { Text } from '@components/core/text'
import { closePopover } from '@components/form/charger-form'
import { Icon } from '@components/icons'

import useDelayedUpdate from '@hooks/use-delayed-update'

import { useDispatch, useSelector } from '@store/index'
import {
  selectBasicConfig,
  selectDescription,
  selectTitle,
  selectUpdatedAt,
  updateDescription,
  updateTitle
} from '@store/slices/component/basic-config'
import { selectChartConfig } from '@store/slices/component/chart-config'
import { selectLimit } from '@store/slices/component/query-config'
import { selectTableConfig } from '@store/slices/component/table-config'
import { selectTreemapConfig } from '@store/slices/component/treemap-config'

import { Pencil } from 'components/icons/pencil'
import useTimeAgo from 'hooks/useTimeAgo'

import { Description as DescriptionHeading } from './common/description'
import { useComponentAttrRef } from './hooks/use-component-attr-refs'
import { useUpdateComponent } from './queries/update-component'

function Save() {
  const popoverTriggerRef = useRef<HTMLButtonElement>(null)

  const { title, id, genre, description } = useSelector(selectBasicConfig)
  const { mutate, isPending } = useUpdateComponent(id)
  const {
    queryDataRef: dataRef,
    filtersRef,
    sortsRef,
    hiddenFiltersRef,
    formulasRef,
    parameterizedConfigRef
  } = useComponentAttrRef()
  const limit = useSelector(selectLimit)
  const chartConfig = useSelector(selectChartConfig)
  const tableConfig = useSelector(selectTableConfig)
  const treemapConfig = useSelector(selectTreemapConfig)

  const handleSave = () => {
    mutate({
      body: {
        title,
        description,
        genre,
        component_attributes: {
          query: dataRef.current,
          filters: filtersRef.current,
          hidden_filters: hiddenFiltersRef.current,
          sorts: sortsRef.current,
          limit,
          formulas: formulasRef.current,
          parameterized_config: parameterizedConfigRef.current,
          chart_config: chartConfig,
          table_config: tableConfig,
          treemap_config: treemapConfig
        }
      }
    })
  }

  return (
    <Popover>
      <PopoverTrigger asChild>
        <CoreButton
          ref={popoverTriggerRef}
          variant={isPending ? 'disabled' : 'primary'}
          size='small'
        >
          Save Chart
        </CoreButton>
      </PopoverTrigger>

      <PopoverContent className='mt-1 w-96' align='end'>
        <div className='flex flex-col gap-2'>
          <Text variant='body' className='text-blue-dark'>
            Are you sure you want to continue?
          </Text>

          <DescriptionHeading>
            This version of Charts is no longer maintained. Please be cautious when saving your
            chart.
          </DescriptionHeading>

          <div className='flex justify-end gap-2'>
            <CoreButton
              variant='text'
              className='mt-2'
              onClick={() => closePopover(popoverTriggerRef.current)}
            >
              Cancel
            </CoreButton>
            <CoreButton
              variant={isPending ? 'disabled' : 'primary'}
              className='mt-2'
              disabled={isPending}
              onClick={() => handleSave()}
            >
              Confirm
            </CoreButton>
          </div>
        </div>
      </PopoverContent>
    </Popover>
  )
}

function Title() {
  const currentTitle = useSelector(selectTitle)
  const dispatch = useDispatch()
  const [title, setTitle] = useDelayedUpdate(currentTitle, (value) => dispatch(updateTitle(value)))
  const [isEditing, setIsEditing] = useState(false)

  const handleEditClick = () => setIsEditing(true)

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value)
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault() // Prevent adding a new line when pressing Enter alone
      setIsEditing(false)
    }
  }

  const handleBlur = () => setIsEditing(false)

  return isEditing ? (
    <Input
      value={title}
      onChange={handleTitleChange}
      onKeyDown={handleKeyDown}
      onBlur={handleBlur}
      autoFocus
      fullWidth
    />
  ) : (
    <Typography variant='h4' onClick={handleEditClick} className='inline-flex items-center'>
      {title} <Icon icon={<Pencil />} className='ml-2' />
    </Typography>
  )
}

function Description() {
  const currentDescription = useSelector(selectDescription)
  const dispatch = useDispatch()
  const [description, setDescription] = useDelayedUpdate(currentDescription, (value) =>
    dispatch(updateDescription(value))
  )
  const [isEditing, setIsEditing] = useState(false)

  const handleEditClick = () => setIsEditing(true)

  const handleDescriptionChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setDescription(e.target.value)
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault() // Prevent adding a new line when pressing Enter alone
      setIsEditing(false)
    }
  }

  const handleBlur = () => setIsEditing(false)

  return (
    <Box sx={{ position: 'relative', zIndex: 1, width: '100%' }}>
      {isEditing ? (
        <>
          <TextareaAutosize
            minRows={2}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              zIndex: 'modal'
            }}
            placeholder='Add a description'
            value={description}
            onChange={handleDescriptionChange}
            onKeyDown={handleKeyDown}
            onBlur={handleBlur}
            autoFocus
          />
          <Box sx={{ visibility: 'hidden', whiteSpace: 'nowrap', maxWidth: '250px' }}>hidden</Box>
        </>
      ) : (
        <Typography
          variant='websiteDetails'
          onClick={handleEditClick}
          sx={{
            cursor: 'pointer',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: '250px',
            display: 'inline-block',
            verticalAlign: 'middle'
          }}
        >
          {description || 'Add a description'}
        </Typography>
      )}{' '}
      {!isEditing && (
        <Typography
          variant='websiteDetails'
          sx={{
            display: 'inline-block',
            verticalAlign: 'middle',
            cursor: 'pointer'
          }}
          onClick={handleEditClick}
        >
          <Icon icon={<Pencil />} className='ml-1 size-4' />
        </Typography>
      )}
    </Box>
  )
}

export default function Heading() {
  const { id } = useParams<{ id: string }>()
  const updatedAt = useSelector(selectUpdatedAt)
  const timeAgo = useTimeAgo(updatedAt)

  return (
    <div className='flex items-center justify-between'>
      <div className='flex flex-col'>
        <Title />
        <Description />
        <Typography variant='details'>Last saved {timeAgo}</Typography>
      </div>
      <div className='flex gap-2'>
        <Button component={Link} to={`../../${id}`} variant='outlined'>
          v2
        </Button>
        <Button component={Link} to='..' variant='outlined'>
          Home
        </Button>
        <Button component={Link} to='query' variant='outlined'>
          &lt;&lt;
        </Button>
        <Button component={Link} to='builder' variant='outlined'>
          &gt;&gt;
        </Button>
        <Save />
      </div>
    </div>
  )
}
