import TimeZoneToggle from '@components/control-panel/time-zone-toggle'

const BusinessPreferenceTab = () => {
  const preferences = [
    {
      label: 'User Time Zone:',
      control: <TimeZoneToggle />
    }
  ]

  return (
    <div className='flex flex-col items-center justify-center'>
      {preferences.map((preference, index) => (
        <div key={index} className='mb-4 flex w-[50%] max-w-2xl items-center'>
          <label className='mr-4 w-36 text-left font-bold'>{preference.label}</label>
          <div className='flex-1'>{preference.control}</div>
        </div>
      ))}
    </div>
  )
}

export default BusinessPreferenceTab
