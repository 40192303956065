import React, { useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Outlet } from 'react-router-dom'

import { Box } from '@mui/material'

import * as am5 from '@amcharts/amcharts5'

import {
  closeDrawer,
  selectDrawerOpen,
  selectHeaderCollapsed,
  toggleDrawer
} from '@store/slices/navigation'

import Drawer from './Drawer'
import Header from './Header'

am5.addLicense(import.meta.env.VITE_AM_CHART_LICENSE)

interface Props {
  children?: React.ReactNode
}

const MainLayout = (props: Props) => {
  const drawerOpen = useSelector(selectDrawerOpen)
  const dispatch = useDispatch()

  const drawerRef = useRef<HTMLDivElement>()

  const handleDrawerToggle = () => {
    dispatch(toggleDrawer())
  }

  const handleDrawerClose = () => {
    dispatch(closeDrawer())
  }

  const headerCollapsed = useSelector(selectHeaderCollapsed)

  return (
    <Box sx={{ display: 'flex', width: '100%' }}>
      <Header open={drawerOpen} handleDrawerToggle={handleDrawerToggle} />
      <Drawer ref={drawerRef} open={drawerOpen} handleDrawerClose={handleDrawerClose} />
      <Box
        className='mainlayout-content-box'
        component='main'
        sx={{
          width: 'calc(100% - 260px)',
          flexGrow: 1,
          p: 0,
          pt: headerCollapsed ? 0 : '2.5rem',
          minHeight: '100vh'
        }}
      >
        <Box
          sx={{ position: 'relative', height: '100%', display: 'flex', flexDirection: 'column' }}
        >
          {props.children ?? <Outlet />}
        </Box>
      </Box>
    </Box>
  )
}

export default MainLayout
