import * as React from 'react'
import { useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react'

import { cn } from '@utils/style-utils'

import Tooltip from '@components/core/tooltip'
import { FormIconPlacement, FormIconPlacementType } from '@components/form/types'
import { Icon } from '@components/icons'
import { Copy } from '@components/icons/copy'

import { queryErrorToast, querySuccessToast } from 'queries/query-toasts'

import { TextareaAutosize, TextareaAutosizeRef } from './textarea-autosize'

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  layout?: 'inline' | 'fullWidth' | 'popover' | 'default'
  failedValidation?: boolean
  leadingIcon?: React.ReactNode
  trailingIcon?: React.ReactNode
  allowMultiline?: boolean
  allowCopyToClipboard?: boolean
  maxRows?: number
  minHeight?: number
  trailingIconPlacement?: FormIconPlacementType
}

const Input = React.forwardRef<HTMLInputElement | HTMLTextAreaElement, InputProps>(
  (
    {
      className,
      type,
      layout = 'default',
      failedValidation,
      leadingIcon,
      trailingIcon,
      allowMultiline = false,
      allowCopyToClipboard = false,
      trailingIconPlacement = FormIconPlacement.INSIDE,
      readOnly,
      maxRows = 5,
      minHeight = 36,
      ...props
    },
    ref
  ) => {
    const [isMultiline, setIsMultiline] = useState(false)
    const inputRef = useRef<HTMLInputElement | TextareaAutosizeRef>(null)

    const nonInlineClasses = `bg-transparent rounded-md ${_.isEqual(type, 'file') ? 'pt-1.5 pl-1' : 'p-3'} h-9`
    const isInline = _.isEqual(layout, 'inline')

    const inlineClasses = 'px-2 py-1 h-9 error-group focus:outline-none'

    const inputClasses = cn(
      `twp flex w-full rounded-md border  disabled:cursor-not-allowed`,
      `file:border-0 file:bg-transparent file:font-medium`,
      `border-grey ${isInline ? 'text-button' : 'text-input'}`,
      !isInline ? nonInlineClasses : inlineClasses,
      failedValidation ? 'border-red' : '',
      className,
      leadingIcon && 'pl-8',
      trailingIcon && 'pr-8',
      readOnly ? 'text-gray-dark bg-gray-lighter cursor-not-allowed' : '',
      layout === 'fullWidth' && 'w-full'
    )

    const handleCopyToClipboard = () => {
      const element =
        inputRef.current instanceof HTMLInputElement ? inputRef.current : inputRef.current?.textArea
      if (element) {
        navigator.clipboard.writeText(element.value).then(
          () => querySuccessToast('Copied to clipboard.'),
          () => queryErrorToast('Failed to copy text.')
        )
      }
    }

    const checkOverflow = useCallback(() => {
      const element =
        inputRef.current instanceof HTMLInputElement ? inputRef.current : inputRef.current?.textArea

      if (element && allowMultiline && !isMultiline && element.scrollWidth > element.clientWidth) {
        setIsMultiline(true)
      }
    }, [allowMultiline, isMultiline])

    useEffect(() => {
      checkOverflow()
    }, [checkOverflow, props.value])

    // To set the cursor to the end of the text when the text control gets switched from input to textarea
    useEffect(() => {
      if (isMultiline && inputRef.current) {
        const textarea =
          inputRef.current instanceof HTMLInputElement ? null : inputRef.current?.textArea // Type narrowing

        if (textarea) {
          textarea.focus()
          const length = textarea.value.length
          textarea.setSelectionRange(length, length)
        }
      }
    }, [isMultiline])

    // Expose the internal ref to the parent via forwarded ref
    useImperativeHandle(ref, () => inputRef.current as HTMLInputElement | HTMLTextAreaElement, [])

    const bothSideIconsActive = trailingIcon && allowCopyToClipboard
    const trailingIconPlacementInside = trailingIconPlacement === FormIconPlacement.INSIDE

    const icons = (
      <>
        {trailingIcon && (
          <div className={cn('flex items-center justify-center', !bothSideIconsActive && 'mb-1')}>
            {trailingIcon}
          </div>
        )}
        {allowCopyToClipboard && (
          <Tooltip title='Copy to clipboard'>
            <Icon className='cursor-pointer' icon={<Copy />} onClick={handleCopyToClipboard} />
          </Tooltip>
        )}
      </>
    )

    const classes = isInline ? 'flex' : !trailingIconPlacementInside ? 'flex-grow' : 'w-full'

    return (
      <div className={cn(layout === 'fullWidth' && 'w-full')}>
        <div className={cn('relative', 'flex', classes)}>
          {leadingIcon && (
            <div className='pointer-events-none absolute inset-y-0 left-0 flex items-center pl-2'>
              {leadingIcon}
            </div>
          )}
          {isMultiline && allowMultiline ? (
            <TextareaAutosize
              className={inputClasses}
              maxRows={maxRows}
              dynamicMaxHeight={true}
              minHeight={minHeight}
              ref={inputRef as React.Ref<TextareaAutosizeRef>}
              {...(props as React.TextareaHTMLAttributes<HTMLTextAreaElement>)}
            />
          ) : (
            <input
              type={type}
              className={inputClasses}
              ref={inputRef as React.Ref<HTMLInputElement>}
              {...props}
            />
          )}

          {trailingIconPlacementInside && (
            <div
              className='absolute inset-y-0 right-0 flex items-center pr-2'
              style={{ height: isMultiline ? '80%' : '100%' }}
            >
              {icons}
            </div>
          )}

          {!trailingIconPlacementInside && (
            <div
              className={cn('ml-2', !bothSideIconsActive && 'mt-1')}
              style={{
                marginTop: bothSideIconsActive ? '-5px' : ''
              }}
            >
              {icons}
            </div>
          )}
        </div>
      </div>
    )
  }
)

Input.displayName = 'Input'

export { Input }
