import { useMemo, useState } from 'react'

import { cn } from '@utils/style-utils'

import Button from '@components/core/button'
import { Input } from '@components/core/input'
import { Popover, PopoverContent, PopoverTrigger } from '@components/core/popover'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '@components/core/select'
import { Separator } from '@components/core/separator'
import { SwitchWithTitle } from '@components/core/switch-with-title'
import Tooltip from '@components/core/tooltip'
import {
  ActionType,
  ActionTypeEnum,
  TreeItem,
  TreeSource,
  TreeSourceEnum,
  TreeSourceEnumTypes
} from '@components/tree/types'

import { CURRENCIES } from '@pages/property-renaming/constants'

import { useFormik } from 'formik'

export const TreeFormPopover = ({
  item,
  source,
  action,
  onCancel,
  onCreate,
  onEdit,
  afterSubmit,
  children
}: {
  item?: TreeItem
  canAddChild?: boolean
  isAdding?: boolean
  action: ActionType
  source: TreeSource | undefined
  onCancel?: () => void
  onEdit?: (nodeId: number, item: TreeItem) => void
  onCreate?: ({ parentNodeId, item }: { parentNodeId?: number; item: TreeItem }) => void | undefined
  afterSubmit?: () => void
  children?: React.ReactNode
}) => {
  const [openPopover, setOpenPopover] = useState(false)
  const currencies = useMemo(() => {
    return CURRENCIES
  }, [])

  const types = useMemo(() => {
    if (source === TreeSourceEnum.Page) {
      return ['page', 'static_page', 'page_group', 'collection']
    } else if (source === TreeSourceEnum.FinancialReport) {
      return ['heading', 'pointer', 'spacer', 'formula']
    } else {
      return []
    }
  }, [source])

  const initialValues = useMemo(() => {
    if (action === ActionTypeEnum.editNode) {
      return {
        label: item?.label,
        identifier: item?.identifier,
        referencedNodeId: item?.referencedNodeId,
        showOnReport: item?.showOnReport
      }
    } else if (
      action === ActionTypeEnum.createChildNode &&
      source === TreeSourceEnum.SubsidiaryDataRenaming
    ) {
      return { id: item?.id, label: '', currency: 'USD' }
    } else if (action === ActionTypeEnum.createChildNode) {
      // NOTE: When creating child node id will be passed as parent Id
      return { id: item?.id, label: '', identifier: types?.[0], showOnReport: true }
    } else {
      // NOTE: when creating root node id will be undefined
      return { id: '', label: '', identifier: types?.[0], showOnReport: true }
    }
  }, [action, item, types, source])

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: (values, { resetForm }) => {
      if (action === ActionTypeEnum.editNode && onEdit) {
        onEdit(item?.id as number, { ...values, children: [] } as TreeItem)
      } else {
        onCreate &&
          onCreate({
            parentNodeId: item?.id as number,
            item: { ...values, children: [] } as TreeItem
          })
      }

      resetForm()
      if (afterSubmit) afterSubmit()
    }
  })

  const isPointerIdentifier = useMemo(() => {
    return source === TreeSourceEnum.FinancialReport && formik.values.identifier === 'pointer'
  }, [formik.values.identifier, source])

  const showTypeSelector = useMemo(() => {
    if (types.length === 0) return false

    if (action === ActionTypeEnum.editNode && TreeSourceEnumTypes.includes(source || ''))
      return false

    return true
  }, [action, source, types.length])

  return (
    <div>
      <Popover
        open={openPopover}
        onOpenChange={(value) => {
          setOpenPopover(value)
        }}
      >
        <PopoverTrigger
          asChild
          onClick={() => {
            setOpenPopover(true)
          }}
        >
          {children}
        </PopoverTrigger>
        <PopoverContent className='flex flex-col' forceMount>
          <div
            className='flex w-72 flex-1 flex-col p-1'
            onClick={(event) => {
              event.stopPropagation()
            }}
          >
            <div className='flex justify-between'>
              <Button
                variant='outline'
                size='extra-small'
                onClick={() => {
                  setOpenPopover(false)
                  if (onCancel) onCancel()
                }}
              >
                Cancel
              </Button>
              <Button
                variant='primary'
                size='extra-small'
                onClick={() => {
                  formik.handleSubmit()
                  setOpenPopover(false)
                }}
              >
                Add Node
              </Button>
            </div>
            <Separator className='my-2' />

            <form onSubmit={formik.handleSubmit} className='flex-1'>
              <div className='flex flex-col gap-2'>
                <Input
                  className='hidden'
                  id='id'
                  name='id'
                  value={formik.values.id}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />

                <Input
                  autoFocus
                  layout='fullWidth'
                  className={cn(
                    'w-full flex-1',
                    formik.touched.label && Boolean(formik.errors.label) && 'error'
                  )}
                  id='label'
                  name='label'
                  placeholder={formik.values.label || 'Node Name'}
                  required={true}
                  value={formik.values.label}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      formik.handleSubmit()
                      setOpenPopover(false)
                    }
                  }}
                />

                {source === TreeSourceEnum.SubsidiaryDataRenaming && (
                  <Select
                    defaultValue='USD'
                    id='currency'
                    name='currency'
                    value={formik.values.currency}
                    onValueChange={(value: string) => formik.setFieldValue('currency', value)}
                  >
                    <SelectTrigger>
                      <SelectValue placeholder='Select Currency' />
                    </SelectTrigger>
                    <SelectContent>
                      {currencies?.map((currency) => (
                        <SelectItem key={currency.code} value={currency.code}>
                          {currency.name}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                )}

                {isPointerIdentifier && (
                  <Input
                    id='referencedNodeId'
                    name='referencedNodeId'
                    layout='fullWidth'
                    value={formik.values.referencedNodeId}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    required={true}
                    placeholder='Referenced Node ID'
                    min={1}
                    step={1}
                    type='number'
                  />
                )}

                {showTypeSelector && (
                  <Select
                    id='identifier'
                    value={formik.values.identifier}
                    className={cn(
                      formik.touched.identifier && Boolean(formik.errors.identifier) && 'error'
                    )}
                    onValueChange={(value) => formik.setFieldValue('identifier', value)}
                  >
                    <SelectTrigger className='w-auto px-4 focus:ring-0 [&>svg]:text-primary'>
                      <SelectValue placeholder='Type' />
                    </SelectTrigger>
                    <SelectContent>
                      {types?.map((type) => (
                        <SelectItem key={type} value={type}>
                          {type}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                )}

                {isPointerIdentifier && (
                  <Tooltip title='Toggle Pointer Node Visibility In Reports'>
                    <SwitchWithTitle
                      id='showOnReport'
                      title='Show On Report'
                      checked={formik.values.showOnReport}
                      onCheckedChange={(value: boolean) =>
                        formik.setFieldValue('showOnReport', value)
                      }
                      onBlur={formik.handleBlur}
                    />
                  </Tooltip>
                )}
              </div>
            </form>
          </div>
        </PopoverContent>
      </Popover>
    </div>
  )
}
