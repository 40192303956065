import { useQuery } from '@tanstack/react-query'

import { camelCaseKeys } from '@utils/case-conversion'

import axiosServices from 'utils/axios'

export interface IBusinessAlertConfigDetail {
  businessId: number | undefined
  id: number | undefined
  subject?: string
  htmlTemplate?: string
}

const fetchBusinessAlertConfigDetail = async (
  businessId?: number,
  alertConfigId?: number
): Promise<IBusinessAlertConfigDetail> => {
  const response = await axiosServices.get(
    `/businesses/${businessId}/alerts/alert_config_detail?id=${alertConfigId}`
  )
  return camelCaseKeys(response.data)
}

export const useBusinessFetchAlertConfigDetail = ({
  businessId,
  alertConfigId
}: {
  businessId?: number
  alertConfigId?: number
}) => {
  return useQuery<IBusinessAlertConfigDetail>({
    queryKey: ['fetch-business-alert-config-detail', businessId, alertConfigId],
    enabled: !!businessId && !!alertConfigId,
    queryFn: () => fetchBusinessAlertConfigDetail(businessId, alertConfigId)
  })
}
