import LogRocket from 'logrocket'

import { isNotEmpty } from './lodash'

if (isNotEmpty(import.meta.env.VITE_LOG_ROCKET_SLUG)) {
  LogRocket.init(import.meta.env.VITE_LOG_ROCKET_SLUG, {
    network: {
      requestSanitizer: (request) => {
        if (request.url.includes('/login')) {
          request.body = undefined
        }

        return request
      }
    },
    shouldDebugLog: false // Suppress misleading "Redux logger detected, skipping data" warning
  })
}
