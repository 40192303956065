import { UseQueryResult, useQuery } from '@tanstack/react-query'

import { camelCaseKeys } from '@utils/case-conversion'

import useAuth from 'hooks/useAuth'
import { QUERY_STALE_TIME_DEFAULT } from 'queries/constants'
import axiosServices from 'utils/axios'
import { DisplayName, DisplayNameSchema } from 'utils/schema-renaming'

export const fetchRenamings = async (business_id: number): Promise<DisplayNameSchema> => {
  try {
    const response = await axiosServices.get(`/businesses/${business_id}/schema_renamings`)
    const data: any[] = camelCaseKeys(response.data) || []

    const schemaRenamings: DisplayNameSchema = {}

    data.forEach((modelRenaming: any) => {
      const modelName = modelRenaming.modelName
      const newName = modelRenaming.newName
      const columns: DisplayName = {}

      modelRenaming.schemaColumnRenamings.forEach((columnRenaming: any) => {
        columns[columnRenaming.columnName] = columnRenaming.newName
      })

      schemaRenamings[modelName] = {
        newName: newName,
        columns: columns
      }
    })

    return schemaRenamings
  } catch (error) {
    // Handle the error as needed
    console.error('Error fetching renamings:', error)
    return {} // Return an empty object to match DisplayNameSchema
  }
}

export const useFetchRenamings = (enabled: boolean): UseQueryResult<DisplayNameSchema, Error> => {
  const { user } = useAuth()

  return useQuery<DisplayNameSchema, Error>({
    queryKey: ['fetch-renamings', user?.business_id],
    queryFn: async (): Promise<DisplayNameSchema> => {
      if (typeof user?.business_id === 'number') {
        return await fetchRenamings(user.business_id)
      } else {
        return {} // Return empty object matching DisplayNameSchema
      }
    },
    enabled: !!user?.business_id && enabled,
    staleTime: QUERY_STALE_TIME_DEFAULT
  })
}
