import { useCallback, useEffect, useRef, useState } from 'react'
import { ImperativePanelHandle, Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels'

import { isMacOS } from '@utils/os'
import { cn } from '@utils/style-utils'

import { IconButton } from '@core/icon-button'
import Tooltip from '@core/tooltip'

import { ChevronDown, ChevronUp, Icon } from '@components/icons'

interface BaseResizablePanelProps {
  primaryContent: React.ReactNode
  secondaryContent?: React.ReactNode
  secondaryDefaultSize?: number // out of 100 (ex. 50 would be half/half split)
  minSize?: number // out of 100
  autoSaveId?: string // optional unique key to persist the layout if you want the position to be saved across page reloads
}

interface VerticalResizablePanelProps extends BaseResizablePanelProps {
  direction: 'vertical'
  showToggle?: boolean // only supported for 'vertical' direction
}

interface HorizontalResizablePanelProps extends BaseResizablePanelProps {
  direction: 'horizontal'
}

type ResizablePanelProps = VerticalResizablePanelProps | HorizontalResizablePanelProps

export function ResizablePanel({
  direction,
  secondaryDefaultSize = 50,
  minSize = 10,
  primaryContent,
  secondaryContent,
  autoSaveId,
  ...props
}: ResizablePanelProps) {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false)
  const [lastSize, setLastSize] = useState<number>(secondaryDefaultSize)
  const secondaryPanelRef = useRef<ImperativePanelHandle>(null)

  const showToggle = (props as VerticalResizablePanelProps).showToggle ?? false

  const secondarySize = isCollapsed ? 0 : secondaryDefaultSize
  const primarySize = secondaryContent ? 100 - secondarySize : 100

  const handleCollapse = useCallback(() => {
    const newIsCollapsed = !isCollapsed
    setIsCollapsed(newIsCollapsed)

    if (secondaryPanelRef.current) {
      secondaryPanelRef.current.resize(
        newIsCollapsed ? minSize : Math.max(lastSize, secondaryDefaultSize)
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCollapsed, lastSize])

  const handleResize = (size: number) => {
    const isNowCollapsed = size <= minSize
    setIsCollapsed(isNowCollapsed)

    // Only store the last size if the panel isn't being collapsed
    if (!isNowCollapsed) {
      setLastSize(size)
    }
  }

  const handleKeyboardShortcuts = useCallback(
    (event: KeyboardEvent) => {
      if (event.altKey) {
        switch (event.code) {
          case 'Backquote': // Alt + ` (Option + ` on Mac)
            handleCollapse()
            break
        }
      }
    },
    [handleCollapse]
  )

  useEffect(() => {
    window.addEventListener('keydown', handleKeyboardShortcuts)
    return () => {
      window.removeEventListener('keydown', handleKeyboardShortcuts)
    }
  }, [handleKeyboardShortcuts])

  const isMac = isMacOS()
  const modifierKey = isMac ? '⌥' : 'Alt'

  return (
    <PanelGroup autoSaveId={autoSaveId} direction={direction}>
      {/* Primary Panel */}
      <Panel id='primary-panel' order={1} defaultSize={primarySize} minSize={minSize}>
        {primaryContent}
      </Panel>

      {secondaryContent && (
        <>
          {/* Resize Handle */}
          <PanelResizeHandle
            className={cn(
              'group relative bg-grey hover:bg-grey-dark',
              direction === 'vertical'
                ? 'h-[1px] w-full cursor-row-resize'
                : 'h-full w-[1px] cursor-col-resize'
            )}
          >
            {showToggle && (
              <Tooltip title={`${modifierKey} + \``} side='left' delay={0} className='-mt-8'>
                <IconButton
                  onClick={handleCollapse}
                  className={cn(
                    'absolute',
                    direction === 'vertical' ? '-top-[33px] left-2' : '-left-3 top-0 rotate-90'
                  )}
                >
                  <Icon icon={isCollapsed ? <ChevronUp /> : <ChevronDown />} />
                </IconButton>
              </Tooltip>
            )}
          </PanelResizeHandle>

          {/* Secondary Panel */}
          <Panel
            ref={secondaryPanelRef}
            id='secondary-panel'
            order={2}
            defaultSize={secondarySize}
            minSize={minSize}
            onResize={handleResize}
          >
            {secondaryContent}
          </Panel>
        </>
      )}
    </PanelGroup>
  )
}
