import { useCallback, useMemo, useRef } from 'react'

import { Box } from '@mui/material'

import { ColDef, GridReadyEvent } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'

import StatusBar from '@components/ag-grid/status-bar'

import SkeletonTable from 'components/ag-grid/skeleton-table'
import useGridLoadingOverlay from 'hooks/grid/useGridLoadingOverlay'

import { useQueryResultContext } from '../contexts/query-result-context'

import './query-builder-ag-grid.css'

export default function DataPreview() {
  const { data, headers, isFetching } = useQueryResultContext()
  const gridRef = useRef<AgGridReact | null>(null)

  const columnDefs = useMemo<ColDef[]>(
    () =>
      headers
        .filter((x) => !x.is_hidden)
        .map((item) => {
          const header: ColDef = { field: item.name, cellDataType: item.data_type }

          if (item.data_type === 'number') {
            header.valueGetter = (params) => {
              const value = params.data[item.name]
              if ([null, undefined, ''].includes(value)) {
                return null
              }
              return +value
            }
          }

          if (item.previous_period) {
            header.headerClass = 'ag-header-cell-previous-period'
          } else if (item.is_aggregate) {
            header.headerClass = 'ag-header-cell-aggregate'
          } else if (item.formula) {
            header.headerClass = 'ag-header-cell-formula'
          }

          return header
        }),
    [headers]
  )

  const defaultColDef = useMemo<ColDef>(
    () => ({
      filter: false,
      sortable: false,
      suppressHeaderMenuButton: true
    }),
    []
  )

  useGridLoadingOverlay(gridRef, isFetching)

  const onGridReady = useCallback(
    (params: GridReadyEvent) => {
      if (isFetching) {
        params.api.showLoadingOverlay()
      } else {
        params.api.hideOverlay()
      }
    },
    [isFetching]
  )

  return (
    <Box
      p={2}
      className='ag-theme-alpine ag-component-query-builder'
      style={{ height: 'calc(100vh - 210px)', width: '100%' }}
    >
      <AgGridReact
        ref={gridRef}
        rowData={data}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        onGridReady={onGridReady}
        loadingOverlayComponent={SkeletonTable}
        columnMenu='legacy'
        statusBar={{
          statusPanels: [
            {
              statusPanel: StatusBar,
              statusPanelParams: { aggFuncs: ['sum', 'avg', 'count'] }
            }
          ]
        }}
      />
    </Box>
  )
}
