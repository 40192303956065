import { ColDef } from 'ag-grid-community'

import * as yup from 'yup'

import { CHARGER_MONOSPACE_CLASS } from '../../config'

export interface ValidateableColDef extends ColDef {
  validationSchema?: yup.AnySchema
}

export enum ColumnTypes {
  RIGHT_ALIGNED = 'rightAligned',
  NUMERIC = 'numeric'
}

export const AG_RIGHT_ALIGNED_CELL = 'ag-right-aligned-cell'

export const RIGHT_ALIGN_COLUMN_DATA = {
  type: 'rightAligned',
  cellClass: `${CHARGER_MONOSPACE_CLASS} ${AG_RIGHT_ALIGNED_CELL}`
}
