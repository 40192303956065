import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit'

import { isNotEqual } from '@utils/lodash'

import { initComponentManagement } from '@store/actions/component-management'
import { RootState } from '@store/index'

import { previousPeriodHeaderName } from '@pages/component-management/utils/header-utils'

import { IComponentData } from 'pages/component-management/types'
import {
  IComponentAdvancedFilters,
  IComponentFormula,
  IComponentHiddenFilters,
  IComponentSortItem
} from 'pages/component-management/types/component-types'
import {
  IAssociations,
  IField,
  IFieldAttribute,
  IParameterizedConfig,
  IQueryData,
  ModelType,
  QueryBuilderPlacement
} from 'pages/component-management/types/query-builder-types'
import cleanupEmptyFieldsAndAssociations from 'pages/component-management/utils/cleanup-empty-fields-and-associations'
import { isEmptyAdvancedFilter } from 'pages/component-management/utils/is-empty-advanced-filter'
import { updatedHiddenFiltersAfterFieldsDeletion } from 'pages/component-management/utils/updated-hidden-filters-after-fields-deletion'

export const MODEL_DOES_NOT_EXIST = 'does-not-exist'
export const MODEL_CUSTOM_SQL_QUERY = 'Custom SQL Query'
export const MODEL_KPI = 'KPI'

const DEFAULT_LIMIT = 5_000

interface QueryConfigState {
  queryData: IQueryData
  showSelectedQueryDataAttrs: boolean
  showForeignKeys: boolean
  hideUnusedTables: boolean
  hideUnusedColumns: boolean
  filters: IComponentAdvancedFilters
  hiddenFilters: IComponentHiddenFilters
  sorts: IComponentSortItem[] | null
  limit: number
  formulas: IComponentFormula[]
  parametrizedConfig: IParameterizedConfig
  customFieldQueryData: IQueryData
  searchText: string
}

const getInitialQueryData = () => ({
  model: MODEL_DOES_NOT_EXIST,
  fields: [],
  associations: {},
  customSqlQuery: ''
})

const initialState: QueryConfigState = {
  queryData: getInitialQueryData(),
  showSelectedQueryDataAttrs: false,
  showForeignKeys: false,
  hideUnusedTables: false,
  hideUnusedColumns: false,
  filters: null,
  hiddenFilters: null,
  sorts: null,
  limit: DEFAULT_LIMIT,
  formulas: [],
  parametrizedConfig: {},
  customFieldQueryData: getInitialQueryData(),
  searchText: ''
}

export const fieldsPath = (path?: string) => (path ? `${path}.fields` : 'fields')
export const associationsPath = (path?: string) => (path ? `${path}.associations` : 'associations')

const queryConfigSlice = createSlice({
  name: '@COMPONENT/QUERY_CONFIG',
  initialState: initialState,
  reducers: {
    setQueryData: (
      state,
      action: PayloadAction<{ queryData: IQueryData; placement?: QueryBuilderPlacement }>
    ) => {
      const { queryData, placement } = action.payload
      const queryDataVar = placement ? 'customFieldQueryData' : 'queryData'
      state[queryDataVar] = queryData
    },
    initQueryData: (state, action: PayloadAction<{ placement?: QueryBuilderPlacement }>) => {
      const { placement } = action.payload
      const queryDataVar = placement ? 'customFieldQueryData' : 'queryData'
      state[queryDataVar] = getInitialQueryData()
    },
    chooseModel: (
      state,
      action: PayloadAction<{
        modelName: string
        modelType?: ModelType
        placement?: QueryBuilderPlacement
      }>
    ) => {
      const { modelName, modelType = ModelType.table, placement } = action.payload
      const queryDataVar = placement ? 'customFieldQueryData' : 'queryData'
      if (isNotEqual(state[queryDataVar].model, modelName)) {
        state[queryDataVar] = {
          ...getInitialQueryData(),
          model: modelName,
          modelType
        }
      }
    },
    chooseField: (
      state,
      action: PayloadAction<{ field: IField; path: string; placement?: QueryBuilderPlacement }>
    ) => {
      const { field, path, placement } = action.payload
      const queryDataVar = placement ? 'customFieldQueryData' : 'queryData'
      const fields = _.get(state[queryDataVar], fieldsPath(path), []) as IField[]
      const index = _.findIndex(fields, (curr) => _.isEqual(curr.name, field.name))
      if (_.isEqual(index, -1)) {
        fields.push(field)
      } else {
        state.hiddenFilters = updatedHiddenFiltersAfterFieldsDeletion(
          _.get(fields, [index, 'configs'], []),
          [],
          state.hiddenFilters
        )

        fields.splice(index, 1)
      }
      _.set(state[queryDataVar], fieldsPath(path), fields)

      cleanupEmptyFieldsAndAssociations(state[queryDataVar].associations as IAssociations)
    },
    setFieldConfigs: (
      state,
      action: PayloadAction<{
        fieldName: string
        path: string
        configs: Partial<IFieldAttribute>[]
        placement?: QueryBuilderPlacement
      }>
    ) => {
      const { fieldName, path, configs, placement } = action.payload
      const queryDataVar = placement ? 'customFieldQueryData' : 'queryData'
      const fields = _.get(state[queryDataVar], fieldsPath(path), []) as IField[]
      const index = _.findIndex(fields, (curr) => _.isEqual(curr.name, fieldName))

      if (isNotEqual(index, -1)) {
        state.hiddenFilters = updatedHiddenFiltersAfterFieldsDeletion(
          _.get(fields, [index, 'configs'], []),
          configs,
          state.hiddenFilters
        )

        fields[index].configs = configs
      }
      _.set(state[queryDataVar], fieldsPath(path), fields)

      // @ts-ignore
      cleanupEmptyFieldsAndAssociations(state.queryData.associations)
    },
    toggleShowSelectedQueryDataAttrs: (state) => {
      state.showSelectedQueryDataAttrs = !state.showSelectedQueryDataAttrs
    },
    toggleShowForeignKeys: (state) => {
      state.showForeignKeys = !state.showForeignKeys
    },
    toggleHideUnusedTables: (state) => {
      state.hideUnusedTables = !state.hideUnusedTables
    },
    toggleHideUnusedColumns: (state) => {
      state.hideUnusedColumns = !state.hideUnusedColumns
    },
    setFilters: (state, action: PayloadAction<IComponentAdvancedFilters>) => {
      state.filters = isEmptyAdvancedFilter(action.payload) ? null : action.payload
    },
    setHiddenFilters: (state, action: PayloadAction<IComponentHiddenFilters>) => {
      state.hiddenFilters = action.payload
    },
    setSorts: (state, action: PayloadAction<IComponentSortItem[]>) => {
      state.sorts = action.payload
    },
    setLimit: (state, action: PayloadAction<number>) => {
      state.limit = action.payload
    },
    addFormula: (state, action: PayloadAction<IComponentFormula>) => {
      state.formulas.push(action.payload)
    },
    removeFormula: (state, action: PayloadAction<string>) => {
      _.remove(state.formulas, (curr) => _.isEqual(curr.name, action.payload))
    },
    updateFormula: (
      state,
      action: PayloadAction<{ config: IComponentFormula; oldName: string }>
    ) => {
      const { oldName, config } = action.payload
      _.forEach(state.formulas, (curr, index) => {
        if (_.isEqual(curr.name, oldName)) {
          state.formulas[index] = config
        }
      })
    },
    updateParameterConfigOnHeaderNameChange: (
      state,
      action: PayloadAction<{ headerName: string; newHeaderName: string }>
    ) => {
      const { headerName, newHeaderName } = action.payload
      const parameterName = _.findKey(state.parametrizedConfig, (value) => value === headerName)
      if (parameterName) {
        state.parametrizedConfig[parameterName] = newHeaderName
      }

      // check previous period
      const previousPeriodHeader = previousPeriodHeaderName(headerName)

      const previousPeriodParameterName = _.findKey(
        state.parametrizedConfig,
        (value) => value === previousPeriodHeader
      )
      if (previousPeriodParameterName) {
        state.parametrizedConfig[previousPeriodParameterName] =
          previousPeriodHeaderName(newHeaderName)
      }
    },
    updateParameterName: (
      state,
      action: PayloadAction<{ parameterName: string; headerName: string }>
    ) => {
      const { parameterName, headerName } = action.payload

      const currentParameterName = _.findKey(
        state.parametrizedConfig,
        (value) => value === headerName
      )

      if (currentParameterName) {
        delete state.parametrizedConfig[currentParameterName]
      }

      if (!parameterName) return // removes the parameter from the list

      state.parametrizedConfig[parameterName] = headerName
    },
    setSearchText: (state, action: PayloadAction<string>) => {
      state.searchText = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(initComponentManagement, (_state, action) => {
      const queryConfig = _.pick(_.get(action.payload, 'component_attributes', {}), [
        'query',
        'filters',
        'hidden_filters',
        'sorts',
        'limit',
        'formulas',
        'parameterized_config'
      ]) as Partial<IComponentData['component_attributes']>
      return {
        queryData: _.get(queryConfig, 'query', initialState.queryData),
        showSelectedQueryDataAttrs: initialState.showSelectedQueryDataAttrs,
        showForeignKeys: initialState.showForeignKeys,
        hideUnusedTables: initialState.hideUnusedTables,
        hideUnusedColumns: initialState.hideUnusedColumns,
        filters: _.get(queryConfig, 'filters', initialState.filters),
        hiddenFilters: _.get(queryConfig, 'hidden_filters', initialState.hiddenFilters),
        sorts: _.get(queryConfig, 'sorts', initialState.sorts),
        limit: _.get(queryConfig, 'limit', initialState.limit),
        formulas: _.get(queryConfig, 'formulas', initialState.formulas),
        parametrizedConfig: _.get(
          queryConfig,
          'parameterized_config',
          initialState.parametrizedConfig
        ),
        customFieldQueryData: initialState.customFieldQueryData,
        searchText: initialState.searchText
      }
    })
  }
})

// Actions
export const {
  setQueryData,
  initQueryData,
  chooseModel,
  chooseField,
  setFieldConfigs,
  toggleShowSelectedQueryDataAttrs,
  toggleShowForeignKeys,
  toggleHideUnusedTables,
  toggleHideUnusedColumns,
  setFilters,
  setHiddenFilters,
  setSorts,
  setLimit,
  addFormula,
  removeFormula,
  updateFormula,
  updateParameterConfigOnHeaderNameChange,
  updateParameterName,
  setSearchText
} = queryConfigSlice.actions

// Reducer
export const queryConfigReducer = queryConfigSlice.reducer

// Selectors
export const selectQueryConfig = (state: RootState) => state.component.queryConfig
export const selectQueryData = (placement?: QueryBuilderPlacement) => {
  return createSelector(selectQueryConfig, (queryConfig) => {
    const queryDataVar = placement ? 'customFieldQueryData' : 'queryData'
    return queryConfig[queryDataVar]
  })
}
export const selectCurrentSelectedModel = (placement?: QueryBuilderPlacement) => {
  return createSelector(selectQueryData(placement), (queryConfig) => {
    let currentSelectedModel = _.get(queryConfig, 'model', null)
    if (_.isEqual(currentSelectedModel, MODEL_DOES_NOT_EXIST)) {
      currentSelectedModel = null
    }
    return currentSelectedModel
  })
}
export const selectCurrentSelectedModelType = (placement?: QueryBuilderPlacement) => {
  return createSelector(selectQueryData(placement), (queryConfig) => {
    const currentSelectedModel = _.get(queryConfig, 'model', null)
    let currentSelectedModelType: ModelType | null = _.get(
      queryConfig,
      'modelType',
      ModelType.table
    )
    if (_.isEqual(currentSelectedModel, MODEL_DOES_NOT_EXIST)) {
      currentSelectedModelType = null
    }
    return currentSelectedModelType
  })
}
export const selectFieldConfigs = (
  fieldName: string,
  path: string,
  placement?: QueryBuilderPlacement
) => {
  return createSelector(selectQueryData(placement), (queryData) => {
    const fields = _.get(queryData, fieldsPath(path), []) as IField[]
    const field = _.find(fields, (curr) => _.isEqual(curr.name, fieldName))
    return (field?.configs || [{}]) as Partial<IFieldAttribute>[]
  })
}
export const selectIsFieldSelected = (
  fieldName: string,
  path: string,
  placement?: QueryBuilderPlacement
) => {
  return createSelector(selectQueryData(placement), (queryData) => {
    const fields = _.get(queryData, fieldsPath(path), [] as IField[])
    return _.some(fields, (curr) => _.isEqual(curr.name, fieldName))
  })
}
export const selectAreFieldsSelectedInAssociation = (
  associationName: string,
  path: string,
  placement?: QueryBuilderPlacement
) => {
  return createSelector(selectQueryData(placement), (queryData) => {
    const currAssociationPath = `${associationsPath(path)}.${associationName}`
    const fields = _.get(queryData, fieldsPath(currAssociationPath), [] as IField[])
    return _.gt(fields.length, 0)
  })
}
export const selectIsAssociationSelected = (
  associationName: string,
  path: string,
  placement?: QueryBuilderPlacement
) => {
  return createSelector(selectQueryData(placement), (queryData) => {
    const association = _.get(queryData, associationsPath(path), {} as IAssociations)
    return !!association[associationName]
  })
}
export const selectAreAssociationsSelected = (path: string, placement?: QueryBuilderPlacement) => {
  return createSelector(selectQueryData(placement), (queryData) => {
    const associations = _.get(queryData, associationsPath(path), {} as IAssociations)
    return _.gt(_.size(associations), 0)
  })
}
export const selectShowSelectedQueryDataAttrs = (state: RootState) =>
  selectQueryConfig(state).showSelectedQueryDataAttrs
export const selectShowForeignKeys = (state: RootState) => selectQueryConfig(state).showForeignKeys
export const selectHideUnusedTables = (state: RootState) =>
  selectQueryConfig(state).hideUnusedTables
export const selectHideUnusedColumns = (state: RootState) =>
  selectQueryConfig(state).hideUnusedColumns
export const selectFilters = (state: RootState) => selectQueryConfig(state).filters
export const selectHiddenFilters = (state: RootState) => selectQueryConfig(state).hiddenFilters
export const selectSorts = (state: RootState) => selectQueryConfig(state).sorts
export const selectLimit = (state: RootState) => selectQueryConfig(state).limit
export const selectFormulas = (state: RootState) => selectQueryConfig(state).formulas
export const selectFormula = (name: string | null) => {
  return createSelector(selectFormulas, (formulas) => {
    return _.find(formulas, (curr) => _.isEqual(curr.name, name))
  })
}
export const selectSearchText = (state: RootState) => selectQueryConfig(state).searchText

// parameterized config selectors
export const selectParameterizedConfig = (state: RootState) =>
  selectQueryConfig(state).parametrizedConfig
export const selectParameterizedConfigAsHeaders = createSelector(
  selectParameterizedConfig,
  (parametrizedConfig) => _.invert(parametrizedConfig)
)
export const selectParameterNameByHeaderName = (headerName: string | null) => {
  return createSelector(selectParameterizedConfigAsHeaders, (parametrizedConfig) => {
    return parametrizedConfig[headerName || '']
  })
}
