import { useMemo, useState } from 'react'

import { List, ListItem, ListItemButton, ListItemText } from '@mui/material'

import { getDisplayName } from '@utils/schema-renaming'

import Button from '@core/button'

import { useSelector } from '@store/index'
import {
  selectCurrentSelectedModelType,
  selectShowSelectedQueryDataAttrs
} from '@store/slices/component/query-config'

import { useFetchRenamings } from '@pages/configuration/queries/fetch-renamings'

import { IColumnData, ModelType, QueryBuilderPlacement } from '../../types/query-builder-types'
import AddOrConfigureField from '../add-or-configure-field'
import NormalField from './normal-field'

export default function NormalFields({
  data,
  parent,
  modelName,
  placement
}: {
  data: IColumnData[]
  parent: string
  modelName: string
  placement?: QueryBuilderPlacement
}) {
  const isPlacedOnMain = !placement
  const showSelectedQueryDataAttrs = useSelector(selectShowSelectedQueryDataAttrs)
  const showSelected = isPlacedOnMain && showSelectedQueryDataAttrs
  const currentSelectedModelType = useSelector(selectCurrentSelectedModelType(placement))

  const { data: renamingsData } = useFetchRenamings(true)
  const renamings = useMemo(() => renamingsData || {}, [renamingsData])

  const [open, setOpen] = useState(false)
  const [selected, setSelected] = useState<IColumnData | null>(null)

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const configureField = (data?: IColumnData) => {
    setSelected(data || null)
    handleOpen()
  }

  // Compute renamed names for fields using useMemo
  const renamedData = useMemo(
    () =>
      data.map((field) => ({
        ...field,
        newName: getDisplayName(renamings, modelName, field.name)
      })),
    [data, modelName, renamings]
  )

  return (
    <>
      {isPlacedOnMain && (
        <AddOrConfigureField {...{ open, handleClose, modelName, selected, setSelected }} />
      )}
      <List dense sx={{ width: '100%' }}>
        {renamedData.map(({ name, newName, field_type, custom, id, association_name }) => (
          <NormalField
            key={name}
            id={id}
            name={name}
            newName={newName}
            field_type={field_type}
            association_name={association_name}
            parent={parent}
            custom={custom}
            configureField={configureField}
            placement={placement}
          />
        ))}
        {isPlacedOnMain && !showSelected && currentSelectedModelType !== ModelType.kpi && (
          <ListItem
            disableGutters
            secondaryAction={
              <Button className='mr-2' size='extra-small' onClick={() => configureField()}>
                Add
              </Button>
            }
            disablePadding
          >
            <ListItemButton>
              <ListItemText id='create-new-field' primary='Add New Field' />
            </ListItemButton>
          </ListItem>
        )}
      </List>
    </>
  )
}
