/* eslint-disable no-loop-func */
import { useCallback, useEffect, useState } from 'react'

import { IStatusPanelParams } from 'ag-grid-community'

import {
  formatSecondsToDuration,
  isValidDurationFormat,
  parseDurationToSeconds
} from '@utils/date-utils'
import { countFractionDigits, formatNumber } from '@utils/number-utils'

import { isCellValueVisible } from 'utils/ag_grid/is-cell-value-visible'

type AggFunc = 'avg' | 'sum' | 'count' | 'totalDuration'

interface Props extends IStatusPanelParams {
  aggFuncs: AggFunc[]
}

const StatusBar = (props: Props) => {
  const { api, aggFuncs } = props

  const [sum, setSum] = useState(0)
  const [average, setAverage] = useState(0)
  const [count, setCount] = useState(0)
  const [totalNumbers, setTotalNumbers] = useState(0)
  const [maxDecimals, setMaxDecimals] = useState(0)
  const [totalDurationInSeconds, setTotalDurationInSeconds] = useState(0)

  const updateValues = useCallback(() => {
    const cellRanges = api.getCellRanges()
    const values: number[] = []
    let newCount = 0
    let maxDecimals = 0
    let durationSum = 0

    cellRanges?.forEach((range) => {
      if (range.startRow && range.endRow) {
        const startRow = Math.min(range.startRow.rowIndex, range.endRow.rowIndex)
        const endRow = Math.max(range.startRow.rowIndex, range.endRow.rowIndex)
        for (let rowIndex = startRow; rowIndex <= endRow; rowIndex++) {
          const rowNode = api.getDisplayedRowAtIndex(rowIndex)
          if (rowNode && isCellValueVisible(rowNode)) {
            range.columns.forEach((column) => {
              newCount++
              const value = api.getCellValue({ colKey: column, rowNode: rowNode! })
              if (typeof value === 'number') {
                values.push(value)
                maxDecimals = Math.max(maxDecimals, countFractionDigits(value))
              }

              if (typeof value === 'string' && isValidDurationFormat(value)) {
                durationSum += parseDurationToSeconds(value)
              }
            })
          }
        }
      }
    })

    const sum = values.reduce((acc, curr) => acc + curr, 0)
    const average = values.length > 0 ? sum / values.length : 0

    setTotalNumbers(values.length)
    setMaxDecimals(maxDecimals)
    setSum(sum)
    setAverage(average)
    setCount(newCount)
    setTotalDurationInSeconds(durationSum)
  }, [api])

  useEffect(() => {
    updateValues()
    const cellValueChangedListener = () => updateValues()
    const rangeSelectionChangedListener = () => updateValues()

    api.addEventListener('cellValueChanged', cellValueChangedListener)
    api.addEventListener('gridColumnsChanged', cellValueChangedListener)
    api.addEventListener('rangeSelectionChanged', rangeSelectionChangedListener)

    // Cleanup
    return () => {
      api.removeEventListener('cellValueChanged', cellValueChangedListener)
      api.removeEventListener('gridColumnsChanged', cellValueChangedListener)
      api.removeEventListener('rangeSelectionChanged', rangeSelectionChangedListener)
    }
  }, [api, updateValues])

  return (
    <div style={{ gap: '0.5rem', display: 'flex', justifyContent: 'space-between' }}>
      {count > 1 ? (
        <>
          {aggFuncs.map((aggFunc) => {
            switch (aggFunc) {
              case 'avg': {
                return totalNumbers >= 1 ? (
                  <div key={aggFunc} className='ag-status-name-value'>
                    Average: <strong>{formatNumber(average, maxDecimals + 1)}</strong>
                  </div>
                ) : undefined
              }
              case 'count': {
                return (
                  <div key={aggFunc} className='ag-status-name-value'>
                    Count: <strong>{count}</strong>
                  </div>
                )
              }
              case 'sum': {
                return totalNumbers >= 1 ? (
                  <div key={aggFunc} className='ag-status-name-value'>
                    Sum: <strong>{formatNumber(sum, maxDecimals)}</strong>
                  </div>
                ) : undefined
              }
              case 'totalDuration': {
                return totalDurationInSeconds >= 1 ? (
                  <div key={aggFunc} className='ag-status-name-value'>
                    Total Duration:{' '}
                    <strong>{formatSecondsToDuration(totalDurationInSeconds)}</strong>
                  </div>
                ) : undefined
              }
              default:
                return null
            }
          })}
        </>
      ) : undefined}
    </div>
  )
}

export default StatusBar
