import { useEffect, useMemo, useRef, useState } from 'react'

import { ColDef } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'

import StatusBar from '@components/ag-grid/status-bar'
import { PageBuilderEnum } from '@components/financial/types'

import useChangeDetection from '@hooks/use-change-detection'

import AgGrid from 'components/ag-grid'
import ComponentWidget from 'components/cards/component-widget'
import { useRegisterAgGridRefEffect } from 'contexts/grid-ref-context'

import { ITableColumn, ITableConfig } from '../types/table-builder-types'
import { ComponentPreviewProps } from '../viewer/types'
import { FilterDropdown } from './filter-dropdown'
import { useBroadcastValue } from './hooks/use-broadcast-value'
import { useColDefs } from './hooks/use-col-defs'
import { useGrandTotals } from './hooks/use-grand-totals'
import { getDefaultExternalFiltersMap } from './utils'

export function ClientSideTable(props: ComponentPreviewProps) {
  const { data, title, isFetching, gridOptions } = props
  const gridRef = useRef<AgGridReact | null>(null)
  useRegisterAgGridRefEffect(gridRef, PageBuilderEnum.PageBuilder)

  const config = _.get(data, 'config') as ITableConfig

  const externalFilters = useMemo(() => _.get(config, 'externalFilters', {}), [config])
  const columns = useMemo(() => _.get(config, 'columns', []) as ITableColumn[], [config])
  const pivotMode = _.get(config, 'pivotMode', false)
  const tableSettings = useMemo(() => _.get(config, 'tableSettings', {}), [config])
  const results = _.get(data, 'results', [])
  const [externalFiltersChanged, triggerExternalFiltersChanged] = useChangeDetection()
  const [firstDataRendered, triggerFirstDataRendered] = useChangeDetection()
  const columnDefs = useColDefs({ columns, gridRef, externalFiltersChanged })
  const [activeFilters, setActiveFilters] = useState(() =>
    getDefaultExternalFiltersMap(externalFilters)
  )
  const { getGrandTotalsProps, getAutoGroupColDefCellRendererParams } = useGrandTotals({
    pivotRowTotals: _.get(tableSettings, 'pivotRowTotals'),
    showTotalFooter: _.get(tableSettings, 'showTotalFooter'),
    grandTotalRow: _.get(tableSettings, 'grandTotalRow'),
    groupTotalRow: _.get(tableSettings, 'groupTotalRow'),
    gridRef,
    events: { firstDataRendered, externalFiltersChanged }
  })
  const { getBroadcastGridOptions, broadcastClassNames, selectRowOnDataRefreshed } =
    useBroadcastValue({ columns, gridRef })

  useEffect(() => {
    setActiveFilters(getDefaultExternalFiltersMap(externalFilters))
  }, [externalFilters])
  useEffect(() => triggerExternalFiltersChanged(), [activeFilters, triggerExternalFiltersChanged])

  const autoGroupColDef = useMemo(() => {
    if (!columns) return

    const groupedColumn = _.find(columns, (column) => column.rowGroup) as ITableColumn
    if (!groupedColumn) {
      return
    }
    const groupColDef: ColDef = {
      headerName: groupedColumn.headerName,
      field: groupedColumn.field,
      pinned: groupedColumn.pinned,
      cellRendererParams: {
        suppressCount: true,
        ...getAutoGroupColDefCellRendererParams()
      }
    }
    return groupColDef
  }, [columns, getAutoGroupColDefCellRendererParams])

  const defaultColDef = useMemo<ColDef>(
    () =>
      _.assign({
        filter: false,
        sortable: true,
        suppressHeaderMenuButton: true
      }),
    []
  )

  return (
    <ComponentWidget
      loading={isFetching}
      title={title}
      titleSecondary={
        <FilterDropdown
          externalFilters={externalFilters}
          activeFilters={activeFilters}
          setActiveFilters={setActiveFilters}
          showSettings={!props.fromViewer}
        />
      }
    >
      <div className='size-full pb-3'>
        <AgGrid
          className={broadcastClassNames}
          style={{ height: '100%' }}
          ref={gridRef}
          rowData={results}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          autoGroupColumnDef={autoGroupColDef}
          loading={isFetching}
          pivotMode={pivotMode}
          showOpenedGroup
          groupDisplayType='singleColumn'
          groupAllowUnbalanced
          suppressAggFuncInHeader
          persistColumnState={true}
          removePivotHeaderRowWhenSingleValueColumn
          allowDragFromColumnsToolPanel
          suppressDragLeaveHidesColumns
          autoSizeStrategy={{ type: 'fitCellContents' }}
          statusBar={{
            statusPanels: [
              {
                statusPanel: StatusBar,
                statusPanelParams: { aggFuncs: ['sum', 'avg', 'count'] }
              }
            ]
          }}
          persistExpandedGroups
          initialMostExpandedLevel={0}
          doesExternalFilterPass={(node) => {
            return _.reduce(
              _.toPairs(activeFilters),
              (acc, [columnField, value]) => {
                if (!value) return acc
                return acc && node.data[columnField] === value
              },
              true
            )
          }}
          isExternalFilterPresent={() => _.toPairs(externalFilters).length > 0}
          onFirstDataRendered={() => triggerFirstDataRendered()}
          onRowDataUpdated={() => selectRowOnDataRefreshed()}
          {...getGrandTotalsProps()}
          {...gridOptions}
          {...getBroadcastGridOptions()}
        />
      </div>
    </ComponentWidget>
  )
}
