import { useMemo } from 'react'
import { Navigate } from 'react-router'
import { Outlet, RouteObject } from 'react-router-dom'

import {
  findChargerDataConnectionsPage,
  findClientDataConnectionsPage,
  findComponentManagementPage,
  findFeatureFlagsPage,
  findPageBuilderPage,
  isChargerDataConnectionsPage,
  isClientDataConnectionsPage,
  isComponentManagementPage,
  isFeatureFlagsPage,
  isPageBuilderPage,
  resolveInternalPageSettings
} from '@utils/layouts/page-settings'
import { InternalPagesGuard } from '@utils/route-guard/internal-pages-guard'

import LazyComponent from '@components/core/LazyComponent'
import { RedirectTo } from '@components/redirect-to'

import { JobSchedules } from '@layout-components/general/data-connections/shared/job-schedules'

import { DataConnectionMode } from 'layout-components/general/data-connections/types'
import ChargerDataConnections from 'pages/admin/charger-data-connections'
import { CatalogTab } from 'pages/admin/charger-data-connections/catalog'
import { ConfigurationTab } from 'pages/admin/charger-data-connections/configuration-tab'
import { DataIntegrityJobsTab } from 'pages/admin/charger-data-connections/data-integrity-jobs-tab'
import { DetailLayout as ChargerDataConnectionDetail } from 'pages/admin/charger-data-connections/detail-layout'
import AddUpdateFeature from 'pages/admin/feature/add-update-feature'
import Feature from 'pages/admin/feature/feature'
import LayoutBuilder from 'pages/admin/layouts'
import LayoutManager from 'pages/admin/layouts/layout-manager'
import ComponentManagementBuilder from 'pages/component-management/builder'
import ComponentManagementCreateFlow from 'pages/component-management/create-flow'
import { DataPreview as ComponentManagementDataPreview } from 'pages/component-management/data-preview'
import ComponentManagementDataSelection from 'pages/component-management/data-selection'
import { EditFlow as ComponentManagementEditFlow } from 'pages/component-management/edit-flow'
import ComponentManagementHome from 'pages/component-management/home'
import ComponentManagementQueryBuilder from 'pages/component-management/query-builder'
import RegisterComponent from 'pages/component-management/register-component'
import { Visualize as ComponentManagementVisualize } from 'pages/component-management/visualize'
import { Page } from 'types/page'

import { ConnectionTabs } from '../layout-components/general/data-connections/charger-ops-connection-detail'
import { AlertsTab } from '../pages/admin/charger-data-connections/alerts-tab'
import { DataIntegrityChecksTab } from '../pages/admin/charger-data-connections/data-integrity-checks-tab'
import { DataMovementJobsTab } from '../pages/admin/charger-data-connections/data-movement-jobs-tab'
import { LiveConnectionsTab } from '../pages/admin/charger-data-connections/live-connections-tab'
import { createDataConnectionRoute } from './route-definitions/data-connections-route'

export type ExtendedRouteObject = RouteObject & {
  title?: string
}

export const InternalRoutes = ({
  pages = [],
  modules
}: {
  pages: Page[]
  modules: any
}): ExtendedRouteObject => {
  const componentManagementPage = findComponentManagementPage(pages)
  const pageBuilderPage = findPageBuilderPage(pages)
  const featureFlagsPage = findFeatureFlagsPage(pages)
  const chargerDataConnectionsPage = findChargerDataConnectionsPage(pages)
  const clientDataConnectionsPage = findClientDataConnectionsPage(pages)

  const componentManagementRoutes = useMemo(() => {
    if (!componentManagementPage) return {}

    return {
      path: 'charts',
      children: [
        {
          path: '',
          element: (
            <ComponentManagementHome
              pageSettings={resolveInternalPageSettings(componentManagementPage)}
            />
          )
        },
        {
          path: 'register-component',
          element: <RegisterComponent />
        },
        {
          path: 'register-component/:id/edit',
          element: <RegisterComponent />
        },
        {
          path: 'v1',
          children: [
            {
              index: true,
              element: <RedirectTo path='..' />
            },
            {
              path: ':id',
              element: (
                <ComponentManagementCreateFlow
                  pageSettings={resolveInternalPageSettings(componentManagementPage, {
                    addDimensionFilters: true
                  })}
                />
              ),
              children: [
                {
                  path: '',
                  element: <RedirectTo path='query' />
                },
                {
                  path: 'query',
                  element: <ComponentManagementQueryBuilder />
                },
                {
                  path: 'builder',
                  element: <ComponentManagementBuilder />
                },
                {
                  path: '*',
                  element: <RedirectTo path='../query' />
                }
              ]
            }
          ]
        },
        {
          path: ':id',
          element: <ComponentManagementEditFlow />,
          children: [
            {
              path: '',
              element: <RedirectTo path='data-selection' />
            },
            {
              path: 'data-selection',
              element: <ComponentManagementDataSelection />
            },
            {
              path: 'data-preview',
              element: <ComponentManagementDataPreview />
            },
            {
              path: 'visualize',
              element: <ComponentManagementVisualize />
            },
            {
              path: '*',
              element: <RedirectTo path='../data-selection' />
            }
          ]
        },
        {
          path: '*',
          element: <Navigate to='/charts' replace />
        }
      ]
    }
  }, [componentManagementPage])

  const pageBuilderRoutes = useMemo(() => {
    if (!pageBuilderPage) return {}

    return [
      {
        path: 'page-builder',
        element: <LayoutManager pageSettings={resolveInternalPageSettings(pageBuilderPage)} />
      },
      {
        path: 'pages/:id/edit',
        element: <LayoutBuilder pageSettings={resolveInternalPageSettings(pageBuilderPage)} />
      }
    ]
  }, [pageBuilderPage])

  const featureFlagsRoutes = useMemo(() => {
    if (!featureFlagsPage) return {}

    return [
      {
        path: 'feature-flags',
        element: <Feature pageSettings={resolveInternalPageSettings(featureFlagsPage)} />
      },
      {
        path: 'feature-flags/new',
        element: <AddUpdateFeature pageSettings={resolveInternalPageSettings(featureFlagsPage)} />
      },
      {
        path: 'feature-flags/:id/edit',
        element: <AddUpdateFeature pageSettings={resolveInternalPageSettings(featureFlagsPage)} />
      }
    ]
  }, [featureFlagsPage])

  const chargerDataConnectionsRoutes = useMemo(() => {
    if (!chargerDataConnectionsPage) return {}

    return [
      {
        path: 'charger-data-connections',
        children: [
          {
            index: true,
            element: (
              <ChargerDataConnections
                pageSettings={resolveInternalPageSettings(chargerDataConnectionsPage)}
              />
            )
          },
          {
            path: ':id',
            element: (
              <ChargerDataConnectionDetail
                pageSettings={resolveInternalPageSettings(chargerDataConnectionsPage)}
              />
            ),
            children: [
              {
                index: true,
                element: <RedirectTo path={ConnectionTabs.CONFIGURATION} />
              },
              {
                path: ConnectionTabs.CONFIGURATION,
                element: <ConfigurationTab mode={DataConnectionMode.CHARGER_OPS} />
              },
              {
                path: ConnectionTabs.LIVE_CONNECTIONS,
                element: <LiveConnectionsTab mode={DataConnectionMode.CHARGER_OPS} />
              },
              {
                path: ConnectionTabs.DATA_MOVEMENT,
                element: <DataMovementJobsTab mode={DataConnectionMode.CHARGER_OPS} />
              },
              {
                path: ConnectionTabs.CATALOG,
                element: <CatalogTab mode={DataConnectionMode.CHARGER_OPS} />
              },
              {
                path: ConnectionTabs.DATA_INTEGRITY_CHECKS,
                element: <DataIntegrityChecksTab mode={DataConnectionMode.CHARGER_OPS} />
              },
              {
                path: ConnectionTabs.DATA_INTEGRITY_JOBS,
                element: <DataIntegrityJobsTab mode={DataConnectionMode.CHARGER_OPS} />
              },
              {
                path: ConnectionTabs.ALERTS,
                element: <AlertsTab mode={DataConnectionMode.CHARGER_OPS} />
              },
              {
                path: ConnectionTabs.JOB_SCHEDULES,
                element: <JobSchedules mode={DataConnectionMode.CHARGER_OPS} />
              },
              {
                path: '*',
                element: <RedirectTo path='../configuration' />
              }
            ]
          }
        ]
      }
    ]
  }, [chargerDataConnectionsPage])

  const clientDataConnectionsRoutes = useMemo(() => {
    if (!clientDataConnectionsPage) return {}

    return createDataConnectionRoute(clientDataConnectionsPage)
  }, [clientDataConnectionsPage])

  const routes = useMemo(() => {
    return pages.map((page: Page) => {
      if (isComponentManagementPage(page)) return componentManagementRoutes
      if (isPageBuilderPage(page)) return pageBuilderRoutes
      if (isFeatureFlagsPage(page)) return featureFlagsRoutes
      if (isChargerDataConnectionsPage(page)) return chargerDataConnectionsRoutes
      if (isClientDataConnectionsPage(page)) return clientDataConnectionsRoutes

      const commonProps: ExtendedRouteObject = {
        path: page.pageRoute,
        id: page.id.toString(),
        title: page.title,
        element: <div className='text-h3 font-bold text-error-dark'>Not Found!</div>
      }

      if (page.metaData?.componentPath) {
        const configPath = `../${page.metaData.componentPath}.tsx`

        const Component = LazyComponent(modules[configPath])
        commonProps.element = <Component pageSettings={resolveInternalPageSettings(page)} />
      }

      return commonProps
    })
  }, [
    chargerDataConnectionsRoutes,
    clientDataConnectionsRoutes,
    componentManagementRoutes,
    featureFlagsRoutes,
    modules,
    pageBuilderRoutes,
    pages
  ])

  return {
    path: '/',
    element: (
      <InternalPagesGuard>
        <Outlet />
      </InternalPagesGuard>
    ),
    children: [..._.flatten(routes)]
  } as ExtendedRouteObject
}
