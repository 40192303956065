import { Middleware } from '@reduxjs/toolkit'

import { REDUX_PERSIST_KEY_PREFIX } from '@store/constants'

import { rehydrateStore } from '../actions/rehydrate-store'
import { RootState } from '../index'
import { HANDLE_BUSINESS_SELECTOR_CHANGE, LOGIN } from '../reducers/actions'

const EXCLUDED_STORES = ['auth', 'snackbar', 'grid']
const REHYDRATABLE_ACTIONS = [LOGIN, HANDLE_BUSINESS_SELECTOR_CHANGE]

export const persist: Middleware<{}, RootState> = (store) => (next) => (action: any) => {
  const prevState = store.getState()
  const result = next(action)
  const newState = store.getState()

  const businessIdPath = 'auth.user.business_id'
  const businessFriendlyName = _.get(newState, 'auth.user.business_friendly_name')
  if (_.isNil(businessFriendlyName)) {
    return result
  }

  const persistenceKey = `${REDUX_PERSIST_KEY_PREFIX}${businessFriendlyName}`

  const shouldRehydrateStore = REHYDRATABLE_ACTIONS.includes(action.type)
  if (shouldRehydrateStore) {
    rehydrateFromLocalStorage(store, persistenceKey)
    return result
  }

  const isStateChangeInSameBusiness =
    _.get(prevState, businessIdPath) &&
    _.isEqual(_.get(prevState, businessIdPath), _.get(newState, businessIdPath))

  if (isStateChangeInSameBusiness) {
    persistStoreToLocalStorage(newState, persistenceKey)
  }

  return result
}

const rehydrateFromLocalStorage = (store: any, key: string) => {
  const hydratedState = JSON.parse(localStorage.getItem(key) || '{}') as Partial<RootState>

  store.dispatch(rehydrateStore(hydratedState))
}

const persistStoreToLocalStorage = (state: RootState, key: string) => {
  const stateToPersist = _.omit(state, EXCLUDED_STORES)

  localStorage.setItem(key, JSON.stringify(stateToPersist))
}
