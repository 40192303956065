import { ICellRendererParams } from 'ag-grid-community'

import { Cancel, Check, Icon } from '@components/icons'

export const MismatchFoundCellRenderer = (params: ICellRendererParams) => (
  <div className='flex size-full items-center justify-center'>
    {params.value === true && <Icon icon={<Check />} className='text-success-darker' />}
    {params.value === false && <Icon icon={<Cancel />} className='text-error-darker' />}
    {(params.value === undefined || params.value === null) && <></>}
  </div>
)
