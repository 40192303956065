import { forwardRef } from 'react'

import { IconProps } from './types'

export const Icon = forwardRef<SVGSVGElement, IconProps>(({ icon, ...rest }, forwardedRef) => {
  return (
    <svg
      ref={forwardedRef}
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='currentColor'
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      {icon}
    </svg>
  )
})

export { ABC } from './abc'
export { AlignCenter } from './align-center'
export { AlignLeft } from './align-left'
export { AlignRight } from './align-right'
export { Archive } from './archive'
export { ArchiveOut } from './archive-out'
export { ArrowDropdown } from './arrow-dropdown'
export { ArrowPlay } from './arrow-play'
export { AttachFile } from './attach-file'
export { Calendar } from './calendar'
export { Cancel } from './cancel'
export { ChartGridVisibility } from './chart-grid-visibility'
export { ChartGridVisibilityOff } from './chart-grid-visibility-off'
export { Check } from './check'
export { ChevronDown } from './chevron-down'
export { ChevronLeft } from './chevron-left'
export { ChevronRight } from './chevron-right'
export { ChevronUp } from './chevron-up'
export { Clock } from './clock'
export { Copy } from './copy'
export { DataLabelVisibility } from './data-label-visibility'
export { DataLabelVisibilityOff } from './data-label-visibility-off'
export { Document } from './document'
export { DocumentAdd } from './document-add'
export { DotsHorizontal } from './dots-horizontal'
export { DotsVertical } from './dots-vertical'
export { Download } from './download'
export { DragIndicator } from './drag-indicator'
export { Eye } from './eye'
export { ExpandDefault } from './expand-default'
export { ExpandLeft } from './expand-left'
export { ExpandLess } from './expand-less'
export { ExpandMore } from './expand-more'
export { ExpandRight } from './expand-right'
export { EyeOff } from './eye-off'
export { Filter } from './filter'
export { FontSizeLarge } from './font-size-large'
export { FontSizeSmall } from './font-size-small'
export { FormulaF } from './formula-f'
export { FormulaFx } from './formula-fx'
export { Hamburger } from './hamburger'
export { Hash } from './hash'
export { HeadingH } from './heading-h'
export { Help } from './help'
export { Home } from './home'
export { Info } from './info'
export { Link } from './link'
export { Pencil } from './pencil'
export { Plus } from './plus'
export { PlusCircle } from './plus-circle'
export { Profile } from './profile'
export { Refresh } from './refresh'
export { Reorder } from './reorder'
export { Search } from './search'
export { Slash } from './slash'
export { TableColumn } from './table-column'
export { TableRow } from './table-row'
export { ToggleLeft } from './toggle-left'
export { Trash } from './trash'
export { TrueFalse } from './true-false'
export { Underline } from './underline'
export { Upload } from './upload'
export { NorthEast } from './north-east'
export { CircleInfo } from './circle-info'
export { HiddenNorthEast } from './hidden-north-east'
export { WordWrap } from './word-wrap'
