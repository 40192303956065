import { useQuery } from '@tanstack/react-query'

import { camelCaseKeys } from '@utils/case-conversion'

import axiosServices from 'utils/axios'

import { IDataIntegrityJob, IDataIntegrityJobsSSROptions } from '../types'

const mapColIdToServerField = (colId: string): string => {
  const lookup: { [key: string]: string } = {
    stream: 'stream',
    namespace: 'namespace',
    diffCheckType: 'diff_check_type',
    uuid: 'uuid',
    mismatchFound: 'mismatch_found',
    startedAt: 'started_at',
    endedAt: 'ended_at'
  }

  return lookup[colId] || colId
}

export type FetchDataIntegrityJobsResponse = {
  records: IDataIntegrityJob[]
  actualRecordsCount: number
  count: number
}

const preprocessData = (data: any[]) => {
  const consolidatedData: Record<string, any> = {}

  _.forEach(data, (row) => {
    const key = `${row.stream}_${row.uuid}`
    if (!consolidatedData[key]) {
      consolidatedData[key] = {
        stream: row.stream,
        uuid: row.uuid,
        mismatchFound: row.mismatchFound,
        startedAt: row.startedAt,
        endedAt: row.endedAt
      }
    }

    consolidatedData[key].startedAt = _.min([consolidatedData[key].startedAt, row.startedAt])
    consolidatedData[key].endedAt = _.max([consolidatedData[key].endedAt, row.endedAt])
    consolidatedData[key][`${_.camelCase(row.diffCheckType)}Metadata`] = row.metadata
  })

  return _.values(consolidatedData)
}

export const fetchDataIntegrityJobs = async (
  businessId?: number,
  id?: string,
  options?: IDataIntegrityJobsSSROptions
): Promise<FetchDataIntegrityJobsResponse> => {
  const mappedSortModel = options?.sortModel.map((sortItem) => ({
    ...sortItem,
    colId: mapColIdToServerField(sortItem.colId)
  }))
  const mappedFilterModel = options?.filterModel
    ? _.mapKeys(options.filterModel, (value, key) => mapColIdToServerField(key))
    : null

  const response = await axiosServices.post(
    `/businesses/${businessId}/connected_source_systems/${id}/data_integrity_jobs`,
    {
      page: options?.page,
      limit: options?.limit,
      filterModel: mappedFilterModel,
      sortModel: mappedSortModel
    }
  )

  const recordsCamelCased = response.data.records.map((record: any) => {
    const camelCasedRecord = camelCaseKeys(record)
    if (camelCasedRecord.metadata) {
      camelCasedRecord.metadata = camelCaseKeys(camelCasedRecord.metadata)
    }
    return camelCasedRecord as IDataIntegrityJob
  })

  return {
    ...camelCaseKeys(response.data),
    records: preprocessData(recordsCamelCased),
    actualRecordsCount: response.data?.records?.length
  } as FetchDataIntegrityJobsResponse
}

export const useFetchDataIntegrityJobs = (
  businessId: number,
  id: string,
  options?: IDataIntegrityJobsSSROptions
) => {
  return useQuery<FetchDataIntegrityJobsResponse>({
    queryKey: ['fetch-css-data-integrity-jobs', id],
    enabled: !!businessId && !!id,
    queryFn: () => fetchDataIntegrityJobs(businessId!, id!, options)
  })
}
