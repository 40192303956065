import React, { useEffect, useState } from 'react'

import { useMutation } from '@tanstack/react-query'

import axiosServices from '@utils/axios'
import { camelCaseKeys } from '@utils/case-conversion'

import Button from '@core/button'

import AgGrid from '@components/ag-grid'
import { Spinner } from '@components/core/spinner'
import { Cancel, Check, Clock, Icon, Info } from '@components/icons'

import { useQuickTestConnection } from '@layout-components/general/data-connections/queries/quick-test-connection'
import { textSearchColumnDef } from '@layout-components/general/data-connections/utils/col-defs'

import useAuth from '@hooks/useAuth'

import { queryErrorToast, querySuccessToast } from '../../../queries/query-toasts'
import { useCheckConnection } from './queries/check-connection'
import {
  EventHandlerStatus,
  IDataConnectionLogDetails,
  IEventHandlerResponse,
  QuickTestStatus
} from './types'

export const EVENT_NAME_TEST_CSS_CONNECTION = 'test_css_connection'

export function CheckConnectionButton({
  businessId,
  cssId,
  isValid,
  businessFriendlyName,
  handlePreCheck
}: {
  businessId: number | undefined
  cssId: number | undefined
  isValid: boolean
  businessFriendlyName?: string
  handlePreCheck?: () => void
}) {
  const { isPrimaryBusinessCharger } = useAuth()
  const [eventKey, setEventKey] = useState('')
  const [lastStatusData, setLastStatusData] = useState<IEventHandlerResponse>({
    cachedData: { status: EventHandlerStatus.Unknown, message: 'n/a', connectionStatus: '' }
  })
  const { mutate: handleEvent } = useMutation<IEventHandlerResponse>({
    mutationFn: async () => {
      const response = await axiosServices.post<IEventHandlerResponse>(
        `/businesses/${businessId}/events/handle_event`,
        {
          event_name: EVENT_NAME_TEST_CSS_CONNECTION,
          data: {
            css_id: cssId
          }
        }
      )
      return response.data
    },
    onSuccess: (data: any) => {
      const response = camelCaseKeys(data)
      setEventKey(response.eventKey)
      querySuccessToast('Connection Check Initiated')
    },
    onError: (error: any) => {
      queryErrorToast(`Connection Check Failed: ${error.message}`)
    }
  })

  const {
    mutate: quickTestConnection,
    status: quickTestStatus,
    data: quickTestResponse
  } = useQuickTestConnection()

  const handleQuickTestClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()

    if (handlePreCheck) {
      handlePreCheck()
    }

    if (businessId && cssId) {
      quickTestConnection({ businessId, cssId })
    }
  }

  const {
    data: statusData,
    attemptExceeded,
    resetTracking
  } = useCheckConnection({ businessId, cssId, eventKey })

  const handleClick = () => {
    if (handlePreCheck) {
      handlePreCheck()
    }

    if (businessId && cssId) {
      setLastStatusData({
        cachedData: { status: EventHandlerStatus.Unknown, message: 'n/a', connectionStatus: '' }
      })
      setEventKey('')
      resetTracking()
      handleEvent()
    }
  }

  useEffect(() => {
    if (quickTestResponse && quickTestResponse.css_id) {
      const quickTestSuccess = quickTestResponse.status === QuickTestStatus.Success

      setLastStatusData({
        cachedData: {
          status: quickTestSuccess ? EventHandlerStatus.Completed : EventHandlerStatus.Failed,
          message: quickTestSuccess
            ? 'Connection successful'
            : quickTestResponse.reason || 'Connection Failed',
          connectionStatus: ''
        }
      })
    }
  }, [quickTestResponse])

  useEffect(() => {
    if (statusData && statusData.cachedData) {
      setLastStatusData(statusData)
    }
  }, [statusData])

  useEffect(() => {
    if (attemptExceeded) {
      // TODO: Move this and lastStatusData data to the hook itself
      setLastStatusData({
        cachedData: {
          status: EventHandlerStatus.Failed,
          message: 'Request Time-out.',
          connectionStatus: ''
        }
      })
    }
  }, [attemptExceeded])

  // Stop polling when status is 'failed' or 'completed'
  useEffect(() => {
    if (
      lastStatusData &&
      lastStatusData.cachedData &&
      [EventHandlerStatus.Failed, EventHandlerStatus.Completed].includes(
        lastStatusData.cachedData.status
      )
    ) {
      setEventKey('') // Reset the event key to stop polling
    }
  }, [lastStatusData])

  const quickTestPending = quickTestStatus === 'pending'

  const disabled = !isValid || eventKey !== '' || quickTestPending

  return (
    <div className='mt-2 flex flex-col'>
      <div className='mt-2 flex flex-col'>
        <Button
          className='mt-2 flex w-60 items-center justify-center'
          type='button'
          variant={disabled ? 'disabled' : 'primary'}
          disabled={disabled}
          onClick={handleQuickTestClick}
        >
          {quickTestPending ? (
            <>
              <Spinner variant='button' />
              <span className='ml-2'>Testing Connection...</span>
            </>
          ) : (
            'Quick Test'
          )}
        </Button>

        {isPrimaryBusinessCharger() && (
          <Button
            className='mt-2 flex w-60 items-center justify-center'
            type='button'
            variant={disabled ? 'disabled' : 'primary'}
            disabled={disabled}
            onClick={handleClick}
          >
            {eventKey ? (
              <>
                <Spinner variant='button' />
                <span className='ml-2'>Testing Connection</span>
              </>
            ) : (
              'Test Connection'
            )}
          </Button>
        )}
      </div>

      <div className='mt-4'>
        <ConnectionStatusDisplay statusData={lastStatusData} />
      </div>
    </div>
  )
}

interface ConnectionStatusDisplayProps {
  statusData: IEventHandlerResponse
}

const transformDetailsToGridData = (details: IDataConnectionLogDetails) => {
  if (!details) return []
  if (!_.isObject(details)) return []

  const gridData = []

  _.forEach(details, (value, key) => {
    if (key !== 'stdout') {
      gridData.push({
        key,
        type: 'LOG',
        value: _.isString(value) ? value : JSON.stringify(value, null, 2)
      })
    }
  })

  if (_.isArray(details.stdout)) {
    const stdoutData = _.map(details.stdout, (log, index) => ({
      key: 'stdout',
      type: log.type || 'LOG',
      value: log.log?.message || 'n/a'
    }))
    gridData.push(...stdoutData)
  }

  return gridData
}

export const ConnectionStatusDisplay = ({ statusData }: ConnectionStatusDisplayProps) => {
  const {
    details = null,
    asOf = '',
    connectionStatus = '',
    status = '',
    message = 'n/a'
  } = statusData.cachedData || {}

  const normalizedStatus = (connectionStatus || status).toUpperCase()
  const isObject = (data: any) => typeof data === 'object' && data !== null

  const gridData = transformDetailsToGridData(details || {})

  const getStatusIcon = () => {
    switch (normalizedStatus) {
      case 'FAILED':
        return <Icon icon={<Cancel />} className='text-error-darker' />
      case 'COMPLETED':
      case 'SUCCEEDED':
        return <Icon icon={<Check />} className='text-success-darker' />
      case 'IN_PROGRESS':
        return <Icon icon={<Clock />} className='text-warning' />
      default:
        return <Icon icon={<Info />} className='text-warning' />
    }
  }

  const columnDefs = [
    textSearchColumnDef('key', 'Info', { initialWidth: 50 }),
    textSearchColumnDef('type', 'Type', { initialWidth: 50 }),
    textSearchColumnDef('value', 'Message')
  ]

  return (
    <div className='mt-4'>
      <div className='flex items-center'>
        <div className='text-lg'>Connection Status:</div>
        <div className='flex items-center'>
          <Icon icon={getStatusIcon()} className='size-9 text-warning' />
          <span className='ml-2'>{normalizedStatus || 'n/a'}</span>
        </div>
      </div>

      <div className='flex items-center'>
        <div className='mr-2'>Message:</div>
        <div className='flex items-center'>{message || 'n/a'}</div>
      </div>

      {asOf && (
        <div className='mt-2 flex items-center'>
          <div className='mr-2'>As of:</div>
          <div className='flex items-center'>{asOf}</div>
        </div>
      )}

      {details && isObject(details) && (
        <>
          <div className='mt-2 flex items-center'>
            <div className='flex w-full items-center'>
              {gridData.length > 0 && (
                <div className='ag-theme-alpine' style={{ height: 400, width: '100%' }}>
                  <AgGrid
                    autoSizeStrategy={{ type: 'fitGridWidth' }}
                    style={{ height: '100%' }}
                    rowData={gridData}
                    columnDefs={columnDefs}
                    defaultColDef={{ minWidth: 100, resizable: true }}
                    gridOptions={{
                      paginationPageSize: 20,
                      pagination: true
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  )
}
