import { useRef } from 'react'
import { useParams } from 'react-router'

import { ColDef } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'

import AgGrid from '@components/ag-grid'
import StatusBar from '@components/ag-grid/status-bar'

import { useFetchDataHealthChecks } from './queries/fetch-data-health-checks'
import { IConnectedSourceSystem } from './types'
import {
  multiTextColumnDef,
  numericCommaSeparatedColumnDef,
  textSearchColumnDef
} from './utils/col-defs'

export function DataHealthCheckList({
  connectedSourceSystem
}: {
  connectedSourceSystem?: IConnectedSourceSystem
}) {
  const wrapperRef = useRef<HTMLDivElement>(null)
  const gridRef = useRef<AgGridReact>(null)

  const { id } = useParams<{ id: string }>()
  const { data, isLoading } = useFetchDataHealthChecks({
    businessId: connectedSourceSystem?.businessId,
    id
  })

  const columnDefs: ColDef[] = [
    textSearchColumnDef('streamName', 'Name'),
    multiTextColumnDef('streamNamespace', 'Namespace'),
    numericCommaSeparatedColumnDef('rowCountDiff', 'Row Count Diff', {
      filter: 'agTextColumnFilter'
    }),
    numericCommaSeparatedColumnDef('sourceRowCount', 'Source Row Count', {
      filter: 'agTextColumnFilter'
    }),
    numericCommaSeparatedColumnDef('destinationRowCount', 'Dest Row Count', {
      filter: 'agTextColumnFilter'
    }),
    textSearchColumnDef('sourceCursorValues', 'Source Cursor Values', {
      initialWidth: 200,
      maxWidth: 700
    }),
    textSearchColumnDef('destCursorValues', 'Dest Cursor Values', {
      initialWidth: 200,
      maxWidth: 700
    }),
    numericCommaSeparatedColumnDef('rowsAdded', 'Rows Added', {
      filter: 'agTextColumnFilter'
    }),
    numericCommaSeparatedColumnDef('rowsUpdated', 'Rows Updated', {
      filter: 'agTextColumnFilter'
    }),
    numericCommaSeparatedColumnDef('rowsDeleted', 'Rows Deleted', {
      filter: 'agTextColumnFilter'
    })
  ]

  return (
    <div className='h-[calc(100vh-12rem)] w-full' ref={wrapperRef}>
      <AgGrid
        style={{ height: '100%' }}
        ref={gridRef}
        rowData={data}
        columnDefs={columnDefs}
        autoSizeStrategy={{ type: 'fitCellContents' }}
        defaultColDef={{
          minWidth: 100,
          getQuickFilterText: () => '',
          filter: true,
          floatingFilter: true,
          filterParams: {
            buttons: ['clear']
          }
        }}
        loading={isLoading}
        persistFilterState={true}
        statusBar={{
          statusPanels: [
            {
              statusPanel: StatusBar,
              statusPanelParams: { aggFuncs: ['sum', 'avg', 'count'] }
            }
          ]
        }}
      />
    </div>
  )
}
