import CheckIcon from '@mui/icons-material/Check'
import { Box, Divider, Grid, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material'

interface ListSelectorProps {
  options: any[]
  onChange: (value: string) => void
  label: string
  selectedValue: string
  rightIndicatorChild?: React.JSX.Element
}

const ListSelector = ({
  options,
  onChange,
  label,
  selectedValue,
  rightIndicatorChild
}: ListSelectorProps) => {
  const handleClick = (value: string) => {
    onChange(value)
  }

  return (
    <Box sx={{ px: '16px' }}>
      <Grid container justifyContent='space-between' alignItems='center'>
        <Typography variant='subText'>{label}</Typography>
        {rightIndicatorChild && rightIndicatorChild}
      </Grid>
      <Divider sx={{ my: '4px' }} />
      {options.map((option) => (
        <ListItem
          className='filter-item'
          button
          key={option.value}
          onClick={() => handleClick(option.value)}
          sx={{ padding: 0, margin: 0, my: '8px' }}
        >
          <ListItemText primaryTypographyProps={{ variant: 'subtitle1' }} primary={option.label} />
          {option.value === selectedValue && (
            <ListItemIcon>
              <CheckIcon />
            </ListItemIcon>
          )}
        </ListItem>
      ))}
    </Box>
  )
}

export default ListSelector
