import React, { useEffect, useImperativeHandle, useRef, useState } from 'react'
import { useMemo } from 'react'

import { Slot } from '@radix-ui/react-slot'
import { cn } from '@utils/style-utils'

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?:
    | 'primary'
    | 'outline'
    | 'disabled'
    | 'text'
    | 'ghost'
    | 'popoverTrigger'
    | 'success'
    | 'danger'
  size?: 'default' | 'action-bar' | 'extra-small' | 'small'
  fullWidth?: boolean
  asChild?: boolean
}

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      asChild = false,
      variant = 'primary',
      size = 'default',
      fullWidth = false,
      ...props
    },
    ref
  ) => {
    const [isPopoverOpen, setIsPopoverOpen] = useState(false)
    const internalRef = useRef<HTMLButtonElement>(null)

    useImperativeHandle(ref, () => internalRef.current!)

    useEffect(() => {
      const currentRef = internalRef.current
      if (currentRef) {
        const observer = new MutationObserver((mutations) => {
          mutations.forEach((mutation) => {
            if (mutation.attributeName === 'data-state') {
              const state = currentRef.getAttribute('data-state')
              setIsPopoverOpen(state === 'open')
            }
          })
        })

        observer.observe(currentRef, { attributes: true })

        return () => {
          observer.disconnect()
        }
      }
    }, [])

    const variantClasses = useMemo(
      () => ({
        primary:
          'bg-primary border-transparent text-white hover:bg-primary-darker focus:border-solid focus:border-primary-darker focus:shadow-[0_0_0_1px_rgba(255,255,255)_inset] rounded',
        success:
          'bg-green-medium border-transparent text-white hover:bg-green-dark focus:border-solid focus:border-green-dark focus:shadow-[0_0_0_1px_rgba(255,255,255)_inset] rounded',
        danger:
          'bg-error border-transparent text-white hover:bg-error-darker focus:border-solid focus:border-error-darker focus:shadow-[0_0_0_1px_rgba(255,255,255)_inset] rounded',
        outline:
          'bg-white border-grey hover:border-grey-dark text-primary-darker focus:border-primary rounded',
        disabled:
          'bg-grey-dark border-transparent opacity-50 text-white rounded pointer-events-none',
        text: cn(
          'text-primary border-transparent bg-transparent hover:text-primary-darker focus:text-primary-darker focus:border-primary-darker rounded',
          props.disabled && 'text-grey'
        ),
        ghost: cn(
          'border-none border-transparent text-primary-darker enabled:hover:bg-grey-lighter aria-expanded:bg-primary-darker aria-expanded:text-white rounded',
          'enabled:active:bg-grey enabled:active:text-primary-darker disabled:opacity-50',
          props.disabled && 'text-primary-darker opacity-50'
        ),
        popoverTrigger: 'bg-primary border-transparent text-white hover:bg-primary-darker rounded'
      }),
      [props.disabled]
    )

    const popoverClasses = `bg-white text-black hover:bg-primary-darker hover:text-white border border-solid border-grey border-b-0 rounded-b-none`

    const sizeClasses = useMemo(
      () => ({
        'action-bar': 'w-6 h-6 rounded text-button',
        'extra-small': 'h-6 px-2 text-button',
        small: 'py-1 px-2 text-button',
        default: 'py-1.5 px-2.5 text-button'
      }),
      []
    )

    const Comp = asChild ? Slot : 'button'

    return (
      <Comp
        ref={internalRef}
        className={cn([
          'twp flex items-center justify-center gap-1.5 border border-solid enabled:cursor-pointer',
          variantClasses[variant],
          sizeClasses[size],
          fullWidth && 'w-full',
          isPopoverOpen && popoverClasses,
          className
        ])}
        {...props}
      />
    )
  }
)

export default Button
