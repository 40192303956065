import { PayloadAction, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { createSelector } from '@reduxjs/toolkit'

import {
  ArchiveStatusEnum,
  ArchiveStatusType,
  DollarUnit,
  DollarUnitEnum,
  ItemVendorEnum,
  ItemVendorType,
  SalesQuantityToggleEnum,
  SalesQuantityType,
  StatusEnum,
  StatusType
} from '@components/control-panel/types'

import { OnlyID, WithID, createPageAction } from '@store/page-util'

import {
  DEFAULT_MAX_EXPANSION_LEVEL,
  DEFAULT_MOST_EXPANDED_LEVEL
} from 'components/control-panel/expand-collapse-control'
import { FONT_SIZE_MAX, FONT_SIZE_MIN } from 'pages/admin/layouts/data'

import { rehydrateStore } from '../actions/rehydrate-store'
import { RootState } from '../index'

interface ActionBarState {
  timeZone: string
  salesQuantityType: SalesQuantityType
  itemVendorType: ItemVendorType
  archiveStatus: ArchiveStatusType
  dollarUnit: DollarUnit
  fontSize: number
  // NOTE: This is a port of legacy implementation - DO NOT add to this
  // This should be flattened. Anything could be on view more, so probably not a good idea to nest things under viewMoreItems
  viewMoreItems: {
    duplicateGlAccount: boolean
    showAllZeroRows: boolean
    [key: string]: boolean
  }
  showDataLabels: boolean
  status: StatusType
  showChartGridLines: boolean
  financialHierarchyId?: string | number
  showArchivedFinancialHierarchies: boolean
  mostExpandedLevel?: number
  maxExpandLevel?: number
  initialMostExpandedLevel: number
  hasExpandableNodes?: boolean
  hasPageBeenLoaded: boolean
  displayExpansionControls?: boolean
  languageId?: string
}

const actionBarAdapter = createEntityAdapter<WithID<ActionBarState>>()

const defaultValues: ActionBarState = {
  timeZone: '', // TimeZoneDefault
  salesQuantityType: SalesQuantityToggleEnum.$,
  itemVendorType: ItemVendorEnum.Item,
  archiveStatus: ArchiveStatusEnum['Not Archived'],
  dollarUnit: DollarUnitEnum.E,
  fontSize: 12,
  viewMoreItems: {
    duplicateGlAccount: false,
    showAllZeroRows: false
  },
  showDataLabels: false,
  status: StatusEnum.ACTIVE,
  showChartGridLines: false,
  financialHierarchyId: undefined,
  showArchivedFinancialHierarchies: false,
  mostExpandedLevel: DEFAULT_MOST_EXPANDED_LEVEL - 2,
  maxExpandLevel: DEFAULT_MAX_EXPANSION_LEVEL,
  initialMostExpandedLevel: DEFAULT_MOST_EXPANDED_LEVEL,
  hasExpandableNodes: false,
  hasPageBeenLoaded: false,
  displayExpansionControls: false,
  languageId: undefined
}

const initialState = actionBarAdapter.getInitialState()

const findOrCreateEntity = (state: typeof initialState, id: string) => {
  let entity = state.entities[id]
  if (!entity) {
    actionBarAdapter.addOne(state, _.assign({ id }, _.cloneDeep(defaultValues)))
    entity = state.entities[id]
  }
  return entity
}

const actionBarSlice = createSlice({
  name: '@ACTION_BAR',
  initialState,
  reducers: {
    toggleDollarUnit: (state, action: PayloadAction<WithID<{ dollarUnit: DollarUnit }>>) => {
      const { id, dollarUnit } = action.payload
      const entity = findOrCreateEntity(state, id)
      entity.dollarUnit = dollarUnit
    },
    updateFontSize: (state, action: PayloadAction<WithID<{ fontSize: number }>>) => {
      const { id, fontSize } = action.payload
      const entity = findOrCreateEntity(state, id)
      const clampedValue = Math.max(FONT_SIZE_MIN, Math.min(FONT_SIZE_MAX, fontSize))
      entity.fontSize = clampedValue
    },
    updateStatusType: (state, action: PayloadAction<WithID<{ status: StatusType }>>) => {
      const { id, status } = action.payload
      const entity = findOrCreateEntity(state, id)
      entity.status = status
    },
    updateItemVendorType: (
      state,
      action: PayloadAction<WithID<{ itemVendorType: ItemVendorType }>>
    ) => {
      const { id, itemVendorType } = action.payload
      const entity = findOrCreateEntity(state, id)
      entity.itemVendorType = itemVendorType
    },
    updateSalesQuantityType: (
      state,
      action: PayloadAction<WithID<{ salesQuantityType: SalesQuantityType }>>
    ) => {
      const { id, salesQuantityType } = action.payload
      const entity = findOrCreateEntity(state, id)
      entity.salesQuantityType = salesQuantityType
    },
    updateArchiveToggleStatus: (
      state,
      action: PayloadAction<WithID<{ archiveStatus: ArchiveStatusType }>>
    ) => {
      const { id, archiveStatus } = action.payload
      const entity = findOrCreateEntity(state, id)
      entity.archiveStatus = archiveStatus
    },
    updateViewMoreItem: (state, action: PayloadAction<WithID<{ key: string; value: boolean }>>) => {
      const { id, key, value } = action.payload
      const entity = findOrCreateEntity(state, id)
      entity.viewMoreItems[key] = value
    },
    toggleShowDataLabels: (state, action: PayloadAction<OnlyID>) => {
      const { id } = action.payload
      const entity = findOrCreateEntity(state, id)
      entity.showDataLabels = !entity.showDataLabels
    },
    toggleShowChartGridLines: (state, action: PayloadAction<OnlyID>) => {
      const { id } = action.payload
      const entity = findOrCreateEntity(state, id)
      entity.showChartGridLines = !entity.showChartGridLines
    },
    toggleShowArchivedFinancialHierarchies: (state, action: PayloadAction<OnlyID>) => {
      const { id } = action.payload
      const entity = findOrCreateEntity(state, id)
      entity.showArchivedFinancialHierarchies = !entity.showArchivedFinancialHierarchies
    },
    updateSelectedFinancialHierarchyId: (
      state,
      action: PayloadAction<WithID<{ hierarchyId: string | number }>>
    ) => {
      const { id, hierarchyId } = action.payload
      const entity = findOrCreateEntity(state, id)
      entity.financialHierarchyId = hierarchyId
    },
    updateExpandLevels: (state, action: PayloadAction<WithID<{ mostExpandedLevel: number }>>) => {
      const { id, mostExpandedLevel } = action.payload
      const entity = findOrCreateEntity(state, id)
      entity.mostExpandedLevel = mostExpandedLevel
    },
    updateMaxExpandLevel: (state, action: PayloadAction<WithID<{ maxExpandLevel: number }>>) => {
      const { id, maxExpandLevel } = action.payload
      const entity = findOrCreateEntity(state, id)
      entity.maxExpandLevel = maxExpandLevel
    },
    updateInitialMostExpandedLevel: (
      state,
      action: PayloadAction<WithID<{ initialMostExpandedLevel: number }>>
    ) => {
      const { id, initialMostExpandedLevel } = action.payload
      const entity = findOrCreateEntity(state, id)
      entity.initialMostExpandedLevel = initialMostExpandedLevel
    },
    updateHasExpandableNodes: (
      state,
      action: PayloadAction<WithID<{ hasExpandableNodes: boolean }>>
    ) => {
      const { id, hasExpandableNodes } = action.payload
      const entity = findOrCreateEntity(state, id)
      entity.hasExpandableNodes = hasExpandableNodes
    },
    setPageLoaded: (state, action: PayloadAction<WithID<{}>>) => {
      const { id } = action.payload
      const entity = findOrCreateEntity(state, id)
      entity.hasPageBeenLoaded = true
    },
    setDisplayExpansionControls: (
      state,
      action: PayloadAction<WithID<{ displayExpansionControls: boolean }>>
    ) => {
      const { id, displayExpansionControls } = action.payload
      const entity = findOrCreateEntity(state, id)
      entity.displayExpansionControls = displayExpansionControls
    },
    updateLanguageId: (state, action: PayloadAction<WithID<{ languageId: string }>>) => {
      const { id, languageId } = action.payload
      const entity = findOrCreateEntity(state, id)
      entity.languageId = languageId
    },
    updateTimeZone: (state, action: PayloadAction<WithID<{ timeZone: string }>>) => {
      const { id, timeZone } = action.payload
      const entity = findOrCreateEntity(state, id)
      entity.timeZone = timeZone
    }
  },
  extraReducers: (builder) => {
    builder.addCase(rehydrateStore, (_state, action) => {
      return { ...initialState, ...action.payload.actionBar }
    })
  }
})

// Actions
export const toggleDollarUnit = createPageAction(actionBarSlice.actions.toggleDollarUnit)
export const updateFontSize = createPageAction(actionBarSlice.actions.updateFontSize)
export const updateStatusType = createPageAction(actionBarSlice.actions.updateStatusType)
export const updateItemVendorType = createPageAction(actionBarSlice.actions.updateItemVendorType)
export const updateSalesQuantityType = createPageAction(
  actionBarSlice.actions.updateSalesQuantityType
)
export const updateArchiveToggleStatus = createPageAction(
  actionBarSlice.actions.updateArchiveToggleStatus
)
export const updateViewMoreItem = createPageAction(actionBarSlice.actions.updateViewMoreItem)
export const toggleShowDataLabels = createPageAction(actionBarSlice.actions.toggleShowDataLabels)
export const toggleShowChartGridLines = createPageAction(
  actionBarSlice.actions.toggleShowChartGridLines
)
export const updateFinancialHierarchyId = createPageAction(
  actionBarSlice.actions.updateSelectedFinancialHierarchyId
)
export const toggleShowArchivedFinancialHierarchies = createPageAction(
  actionBarSlice.actions.toggleShowArchivedFinancialHierarchies
)
export const updateExpandLevels = createPageAction(actionBarSlice.actions.updateExpandLevels)
export const updateMaxExpandLevel = createPageAction(actionBarSlice.actions.updateMaxExpandLevel)
export const updateInitialMostExpandedLevel = createPageAction(
  actionBarSlice.actions.updateInitialMostExpandedLevel
)

export const updateHasExpandableNodes = createPageAction(
  actionBarSlice.actions.updateHasExpandableNodes
)
export const setPageLoaded = createPageAction(actionBarSlice.actions.setPageLoaded)
export const setDisplayExpansionControls = createPageAction(
  actionBarSlice.actions.setDisplayExpansionControls
)
export const updateLanguageId = createPageAction(actionBarSlice.actions.updateLanguageId)

export const updateTimeZone = createPageAction(actionBarSlice.actions.updateTimeZone)

// Reducer
export const actionBarReducer = actionBarSlice.reducer

// Selectors
const getActionBarState = (id: string) => (state: RootState) =>
  _.get(state, ['actionBar', 'entities', id], defaultValues)

export const selectDollarUnit = (id: string) =>
  createSelector(getActionBarState(id), (actionBar) => _.get(actionBar, ['dollarUnit']))

export const selectFontSize = (id: string) =>
  createSelector(getActionBarState(id), (actionBar) => _.get(actionBar, ['fontSize']))

export const selectStatusType = (id: string) =>
  createSelector(getActionBarState(id), (actionBar) => _.get(actionBar, ['status']))

export const selectItemVendorType = (id: string) =>
  createSelector(getActionBarState(id), (actionBar) => _.get(actionBar, ['itemVendorType']))

export const selectSalesQuantityType = (id: string) =>
  createSelector(getActionBarState(id), (actionBar) => _.get(actionBar, ['salesQuantityType']))

export const selectArchiveStatus = (id: string) =>
  createSelector(getActionBarState(id), (actionBar) => _.get(actionBar, ['archiveStatus']))

export const selectViewMoreItems = (id: string) =>
  createSelector(getActionBarState(id), (actionBar) => _.get(actionBar, ['viewMoreItems']))

export const selectShowDataLabels = (id: string) =>
  createSelector(getActionBarState(id), (actionBar) => _.get(actionBar, ['showDataLabels']))

export const selectShowChartGridLines = (id: string) =>
  createSelector(getActionBarState(id), (actionBar) => _.get(actionBar, ['showChartGridLines']))

export const selectFinancialHierarchyId = (id: string) =>
  createSelector(getActionBarState(id), (actionBar) => _.get(actionBar, ['financialHierarchyId']))

export const selectShowArchivedFinancialHierarchies = (id: string) =>
  createSelector(getActionBarState(id), (actionBar) =>
    _.get(actionBar, ['showArchivedFinancialHierarchies'])
  )

export const selectMostExpandedLevel = (id: string) =>
  createSelector(getActionBarState(id), (actionBar) =>
    _.get(actionBar, ['mostExpandedLevel'], DEFAULT_MOST_EXPANDED_LEVEL - 2)
  )

export const selectMaxExpandLevel = (id: string) =>
  createSelector(getActionBarState(id), (actionBar) => _.get(actionBar, ['maxExpandLevel']))

export const selectInitialMostExpandedLevel = (id: string) =>
  createSelector(getActionBarState(id), (actionBar) =>
    _.get(actionBar, ['initialMostExpandedLevel'], DEFAULT_MOST_EXPANDED_LEVEL)
  )

export const selectHasExpandableNodes = (id: string) =>
  createSelector(getActionBarState(id), (actionBar) =>
    _.get(actionBar, ['hasExpandableNodes'], false)
  )

export const selectHasPageBeenLoaded = (id: string) =>
  createSelector(getActionBarState(id), (actionBar) => _.get(actionBar, ['hasPageBeenLoaded']))

export const selectDisplayExpansionControls = (id: string) =>
  createSelector(getActionBarState(id), (actionBar) =>
    _.get(actionBar, ['displayExpansionControls'], false)
  )

export const selectLanguageId = (id: string) =>
  createSelector(getActionBarState(id), (actionBar) => _.get(actionBar, ['languageId']))

export const selectTimeZone = (id: string) =>
  createSelector(getActionBarState(id), (actionBar) => _.get(actionBar, ['timeZone']))
