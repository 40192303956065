import { combineReducers } from '@reduxjs/toolkit'

import { userPreferenceReducer } from '@store/slices/user-preferences'

import auth from './reducers/auth'
import snackbar from './reducers/snackbar'
import { accessManagementReducer } from './slices/access-management'
import { actionBarReducer } from './slices/action-bar'
import { tabReducer } from './slices/active-tab'
import { basicConfigReducer } from './slices/component/basic-config'
import { chartConfigReducer } from './slices/component/chart-config'
import { drilldownsReducer } from './slices/component/drilldowns'
import { parameterizedFiltersReducer } from './slices/component/parameterized-filters'
import { queryConfigReducer } from './slices/component/query-config'
import { tableConfigReducer } from './slices/component/table-config'
import { tableParametersReducer } from './slices/component/table-parameters'
import { treemapConfigReducer } from './slices/component/treemap-config'
import { customKpisReducer } from './slices/custom-kpis'
import { dateTimeFilterReducer } from './slices/date-time-filter'
import { dimensionFilterReducer } from './slices/dimension'
import { expandedGroupsReducer } from './slices/expanded-groups'
import { financialModelsReducer } from './slices/financial-models'
import { glTransactionsDimensionFiltersReducer } from './slices/gl-transactions-dimension-filters'
import { gridReducer } from './slices/grid'
import { gridStateReducer } from './slices/grid-state'
import { modelPlusActualReducer } from './slices/model-plus-actual'
import { navigationReducer } from './slices/navigation'
import { propertyRenamingReducer } from './slices/property-renaming'
import { toastReducer } from './slices/toast'
import { trialBalanceReducer } from './slices/trial-balance'

const reducers = combineReducers({
  auth,
  snackbar,
  userPreferences: userPreferenceReducer,
  actionBar: actionBarReducer,
  accessManagement: accessManagementReducer,
  dateTimeFilter: dateTimeFilterReducer,
  dimensions: dimensionFilterReducer,
  navigation: navigationReducer,
  grid: gridReducer,
  component: combineReducers({
    drilldowns: drilldownsReducer,
    basicConfig: basicConfigReducer,
    tableConfig: tableConfigReducer,
    chartConfig: chartConfigReducer,
    queryConfig: queryConfigReducer,
    treemapConfig: treemapConfigReducer,
    parameterizedFilters: parameterizedFiltersReducer,
    tableParameters: tableParametersReducer
  }),
  toasts: toastReducer,
  trialBalance: trialBalanceReducer,
  modelPlusActual: modelPlusActualReducer,
  customKpis: customKpisReducer,
  activeTab: tabReducer,
  financialModels: financialModelsReducer,
  propertyRenaming: propertyRenamingReducer,

  glTransactionsDimensionFilters: glTransactionsDimensionFiltersReducer,
  expandedGroups: expandedGroupsReducer,
  gridState: gridStateReducer
})

export default reducers
