import { useEffect, useMemo, useRef } from 'react'
import { Outlet, useNavigate, useParams } from 'react-router'

import { Breadcrumb, BreadcrumbItem, BreadcrumbList, BreadcrumbSeparator } from '@core/breadcrumb'
import { Separator } from '@core/separator'
import { Skeleton } from '@core/skeleton'
import { Tabs, TabsList, TabsTrigger } from '@core/tab'
import { Text } from '@core/text'

import { useCheckPathSelected } from '@hooks/use-check-path-selected'

import { useFetchConnectedSourceSystem } from 'layout-components/general/data-connections/queries/fetch-connected-source-system'

import { DataConnectionMode, IDataConnectionDetailProps } from './types'

export enum ConnectionTabs {
  CONFIGURATION = 'configuration',
  LIVE_CONNECTIONS = 'live-connections',
  DATA_MOVEMENT = 'data-movement',
  CATALOG = 'catalog',
  DATA_INTEGRITY_CHECKS = 'data-integrity-checks',
  DATA_INTEGRITY_JOBS = 'data-integrity-jobs',
  ALERTS = 'alerts',
  JOB_SCHEDULES = 'job-schedules'
}

interface ChargerOpsConnectionDetailProps extends IDataConnectionDetailProps {}

export const ChargerOpsConnectionDetail = ({ mode }: ChargerOpsConnectionDetailProps) => {
  const { id } = useParams<{ id: string }>()
  const navigate = useNavigate()
  const { data, isPending, isError } = useFetchConnectedSourceSystem({ id })

  const tabsListRef = useRef<HTMLDivElement>(null)
  const selectedTabRef = useRef<HTMLButtonElement>(null)

  useEffect(() => {
    if (selectedTabRef.current) {
      selectedTabRef.current.focus()
    } else if (tabsListRef.current) {
      tabsListRef.current.focus()
    }
  }, [])

  const renderBreadcrumbItem = (content?: string) => {
    if (isPending) return <Skeleton className='h-6 w-[200px]' />
    if (isError) return <Text variant='h5'>ERR!</Text>

    return content ? <Text variant='h5'>{content}</Text> : null
  }

  const isConfigurationTabSelected = useCheckPathSelected(ConnectionTabs.CONFIGURATION)
  const isLiveConnectionsTabSelected = useCheckPathSelected(ConnectionTabs.LIVE_CONNECTIONS)
  const isDataMovementTabSelected = useCheckPathSelected(ConnectionTabs.DATA_MOVEMENT)
  const isCatalogTabSelected = useCheckPathSelected(ConnectionTabs.CATALOG)
  const isDataIntegrityChecksTabSelected = useCheckPathSelected(
    ConnectionTabs.DATA_INTEGRITY_CHECKS
  )
  const isDataIntegrityJobsTabSelected = useCheckPathSelected(ConnectionTabs.DATA_INTEGRITY_JOBS)
  const isAlertsTabSelected = useCheckPathSelected(ConnectionTabs.ALERTS)
  const isJobSchedulesTabSelected = useCheckPathSelected(ConnectionTabs.JOB_SCHEDULES)

  const tabValue = useMemo(() => {
    const tabMapping = {
      [ConnectionTabs.CONFIGURATION]: isConfigurationTabSelected,
      [ConnectionTabs.LIVE_CONNECTIONS]: isLiveConnectionsTabSelected,
      [ConnectionTabs.DATA_MOVEMENT]: isDataMovementTabSelected,
      [ConnectionTabs.CATALOG]: isCatalogTabSelected,
      [ConnectionTabs.DATA_INTEGRITY_CHECKS]: isDataIntegrityChecksTabSelected,
      [ConnectionTabs.DATA_INTEGRITY_JOBS]: isDataIntegrityJobsTabSelected,
      [ConnectionTabs.ALERTS]: isAlertsTabSelected,
      [ConnectionTabs.JOB_SCHEDULES]: isJobSchedulesTabSelected
    }

    return Object.keys(tabMapping).find((key) => tabMapping[key as keyof typeof tabMapping])
  }, [
    isConfigurationTabSelected,
    isLiveConnectionsTabSelected,
    isDataMovementTabSelected,
    isCatalogTabSelected,
    isDataIntegrityChecksTabSelected,
    isDataIntegrityJobsTabSelected,
    isAlertsTabSelected,
    isJobSchedulesTabSelected
  ])

  const connectionTitles = {
    [DataConnectionMode.CLIENT]: 'Data Connections',
    [DataConnectionMode.CHARGER_OPS]: 'Charger Data Connections'
  }

  const btnClassName = 'w-[150px] text-center'

  return (
    <div className='flex flex-col gap-2'>
      <Breadcrumb>
        <BreadcrumbList>
          <BreadcrumbItem className='cursor-pointer' onClick={() => navigate('..')}>
            <Text variant='h5'>{connectionTitles[mode]}</Text>
          </BreadcrumbItem>
          <BreadcrumbSeparator />

          <BreadcrumbItem>{renderBreadcrumbItem(data?.businessName)}</BreadcrumbItem>

          <BreadcrumbSeparator />

          <BreadcrumbItem>
            {renderBreadcrumbItem(`${data?.name} (${data?.id.toString()})`)}
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>
      <Separator className='w-full' />
      <div className='flex min-h-11 w-full items-center space-x-2'>
        <Tabs id='dataConnectionsTabs' value={tabValue} onValueChange={navigate}>
          <TabsList className='flex gap-2' ref={tabsListRef} tabIndex={-1}>
            <TabsTrigger
              className={btnClassName}
              value={ConnectionTabs.CONFIGURATION}
              ref={tabValue === ConnectionTabs.CONFIGURATION ? selectedTabRef : null}
            >
              Configuration
            </TabsTrigger>

            {data?.pulseSupported && (
              <TabsTrigger
                value={ConnectionTabs.LIVE_CONNECTIONS}
                ref={tabValue === ConnectionTabs.LIVE_CONNECTIONS ? selectedTabRef : null}
              >
                Live Connections
              </TabsTrigger>
            )}

            <TabsTrigger
              className={btnClassName}
              value={ConnectionTabs.CATALOG}
              ref={tabValue === ConnectionTabs.CATALOG ? selectedTabRef : null}
            >
              Catalog
            </TabsTrigger>

            <TabsTrigger
              className={btnClassName}
              value={ConnectionTabs.DATA_MOVEMENT}
              ref={tabValue === ConnectionTabs.DATA_MOVEMENT ? selectedTabRef : null}
            >
              Data Movement
            </TabsTrigger>

            <TabsTrigger
              className={btnClassName}
              value={ConnectionTabs.DATA_INTEGRITY_CHECKS}
              ref={tabValue === ConnectionTabs.DATA_INTEGRITY_CHECKS ? selectedTabRef : null}
            >
              Data Integrity
            </TabsTrigger>

            {mode == DataConnectionMode.CHARGER_OPS && (
              <TabsTrigger
                className={btnClassName}
                value={ConnectionTabs.DATA_INTEGRITY_JOBS}
                ref={tabValue === ConnectionTabs.DATA_INTEGRITY_JOBS ? selectedTabRef : null}
              >
                Data Integrity Jobs
              </TabsTrigger>
            )}
            {mode == DataConnectionMode.CHARGER_OPS && (
              <TabsTrigger
                className={btnClassName}
                value={ConnectionTabs.ALERTS}
                ref={tabValue === ConnectionTabs.ALERTS ? selectedTabRef : null}
              >
                Alerting
              </TabsTrigger>
            )}

            <TabsTrigger
              className={btnClassName}
              value={ConnectionTabs.JOB_SCHEDULES}
              ref={tabValue === ConnectionTabs.JOB_SCHEDULES ? selectedTabRef : null}
            >
              Job Schedules
            </TabsTrigger>
          </TabsList>
        </Tabs>
      </div>
      <Separator className='w-full' />
      <Outlet />
    </div>
  )
}
