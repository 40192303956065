import { useState } from 'react'

import { ICellRendererParams } from 'ag-grid-community'

import LineWrapToggle from '@core/custom/line-wrap-toggle'
import ResizablePopover from '@core/custom/resizable-popover'

import { PythonEditor } from '@components/code-editors'

interface PythonCellRendererProps extends ICellRendererParams {
  title?: string
}

export const PythonCellRenderer = (params: PythonCellRendererProps) => {
  const [enableLineWrapping, setEnableLineWrapping] = useState(false)

  const toggleLineWrapping = () => {
    setEnableLineWrapping((prevState) => !prevState)
  }

  const headerTools = (
    <LineWrapToggle isEnabled={enableLineWrapping} onToggle={toggleLineWrapping} />
  )

  return (
    <ResizablePopover
      headerTools={headerTools}
      title={params.title || 'Python Code'}
      content={
        <PythonEditor value={params.value} readOnly enableLineWrapping={enableLineWrapping} />
      }
    >
      {params.value}
    </ResizablePopover>
  )
}
