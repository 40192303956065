import { useMemo, useState } from 'react'

import { SortDimensionFilters, selectedDimensionKeyFromDimensionType } from '@utils/dimension-utils'
import { countItemsNotInSelection, countTotalSelectedItems } from '@utils/obj-utils'
import { DisplayNameSchema, getDisplayName } from '@utils/schema-renaming'
import { capitalizeFirstLetter } from '@utils/string-utils'
import { cn } from '@utils/style-utils'

import { MultiSelect } from '@components/core/multi-select'
import { Separator } from '@components/core/separator'
import { Text } from '@components/core/text'

import { usePageDispatch, usePageSelector } from '@store/index'
import { replaceDimensionsAction, selectDimensions } from '@store/slices/dimension'

import { useFetchRenamings } from '@pages/configuration/queries/fetch-renamings'

import ExpandCollapseClear from 'pages/admin/layouts/components/shared/expand-collapse-clear'
import pluralize from 'pluralize'
import { useFetchDimensions } from 'queries/fetch-dimensions'

import { SkeletonRow } from '../../ag-grid/skeleton-table'
import { Button } from '../../core/button'
import {
  DimensionFilterType,
  EmptySelectedDimensions,
  SelectedDimensions,
  SelectedDimensionsType
} from '../types'

export const convertDataToOptions = (data: Record<string, string>) => {
  return _.map(data, (label: string, id: string | number) => ({ value: id, label }))
}

const DimensionExplorer = ({
  onClose,
  classNames = ''
}: {
  onClose: () => void
  classNames?: string
}) => {
  const pageDispatch = usePageDispatch()
  const { isLoading, data: dimensions } = useFetchDimensions()
  const appliedDimensions = usePageSelector(selectDimensions)

  const [selectedFilters, setSelectedFilters] = useState<SelectedDimensions>(appliedDimensions)
  const [deletedFilters, setDeletedFilters] = useState<SelectedDimensions>(EmptySelectedDimensions)
  const [isDirty, setIsDirty] = useState(false)

  const handleFilterChange = (identifier: string, selectedItems: string[]) => {
    const dimensionType = identifier as SelectedDimensionsType

    setSelectedFilters((prevSelectedFilters) => {
      return { ...prevSelectedFilters, [dimensionType]: selectedItems }
    })

    const itemsToDelete = _.difference(appliedDimensions[dimensionType], selectedItems)

    setDeletedFilters((prevDeletedFilters) => {
      const updatedDeletedFilters: SelectedDimensions = { ...prevDeletedFilters }
      updatedDeletedFilters[dimensionType] = itemsToDelete
      return updatedDeletedFilters
    })

    setIsDirty(true)
  }

  const { data: renamingsData } = useFetchRenamings(true)
  const renamingHeaders = useMemo(() => {
    const renamings = (renamingsData || {}) as DisplayNameSchema
    const headers: Record<string, string> = {}
    if (dimensions) {
      Object.keys(dimensions).forEach((key) => {
        const formattedKey = _.split(pluralize.singular(key), '_')
          .map((k) => capitalizeFirstLetter(k))
          .join('')
        const selected = getDisplayName(renamings, formattedKey)
        headers[key] = pluralize(selected || _.join(_.split(key, '_'), ' ')).toString()
      })
    }
    return headers
  }, [dimensions, renamingsData])

  const onCancel = () => {
    clearStage()

    if (!isDirty) {
      onClose()
    }
  }

  const clearStage = () => {
    setSelectedFilters(EmptySelectedDimensions)
    setDeletedFilters(EmptySelectedDimensions)
    setIsDirty(false)
  }

  const applyFilters = () => {
    Object.entries(selectedFilters).forEach(([key, value]) => {
      const dimensionType = key as DimensionFilterType
      const itemsToAdd: string[] = value
      const itemsToDelete =
        deletedFilters[dimensionType as unknown as keyof SelectedDimensions] || []

      if (_.isEmpty(itemsToAdd) && _.isEmpty(itemsToDelete)) return
      pageDispatch(
        replaceDimensionsAction({ dimensionType: key as DimensionFilterType, items: value })
      )
    })

    setDeletedFilters(EmptySelectedDimensions)
    setIsDirty(false)
  }

  if (isLoading) return <SkeletonRow columnsLength={1} />
  if (!dimensions || Object.keys(dimensions).length === 0) return null

  const totalSelected = countItemsNotInSelection(selectedFilters, appliedDimensions)
  const totalDeleted = countTotalSelectedItems(deletedFilters) || 0

  const totalCount = totalSelected + totalDeleted
  const isDisabled = totalCount <= 0 || !isDirty

  return (
    <div
      className={cn('mb-[100px] flex h-[calc(100%-64px)] w-[478px] flex-col px-6 py-4', classNames)}
    >
      <div className='flex items-center justify-between pt-3'>
        <Text variant='h5' className='text-black-dark'>
          Dimension Filters
        </Text>
        <div className='flex items-center justify-between'>
          <Button variant='outline' color='primary' onClick={onCancel}>
            {isDirty ? 'Cancel' : 'Close'}
          </Button>
          <Button
            variant={isDisabled ? 'disabled' : 'primary'}
            onClick={applyFilters}
            disabled={isDisabled}
            className='ml-4'
          >
            {totalCount ? `(${totalCount}) Apply` : 'Apply'}
          </Button>
        </div>
      </div>
      <Separator className='my-2' />
      {Object.entries(dimensions)
        .sort((a, b) => {
          const [keyA] = a
          const [keyB] = b
          return SortDimensionFilters(keyA, keyB)
        })
        .map(([key, filterValue]) => {
          const dimensionFilterKey: SelectedDimensionsType = selectedDimensionKeyFromDimensionType(
            key as DimensionFilterType
          )
          const header = renamingHeaders[key]

          const totalSelectedValues = isDirty
            ? _.difference(
                _.union(appliedDimensions[dimensionFilterKey], selectedFilters[dimensionFilterKey]),
                deletedFilters[dimensionFilterKey]
              )
            : appliedDimensions[dimensionFilterKey]

          return (
            <div className='dimension-filter-item-container mb-2 w-full' key={key}>
              <ExpandCollapseClear header={header} name={key}>
                <MultiSelect
                  options={convertDataToOptions(filterValue)}
                  defaultSelectedValues={totalSelectedValues}
                  onChange={(selected) => {
                    if (selected.length && selected.find((option) => option.value === 'all')) {
                      const options = convertDataToOptions(filterValue).sort((a, b) =>
                        a.label.localeCompare(b.label)
                      )
                      handleFilterChange(
                        dimensionFilterKey,
                        options.map((f) => f.value.toString())
                      )
                    } else {
                      handleFilterChange(
                        dimensionFilterKey,
                        selected.map((f) => f.value.toString())
                      )
                    }
                  }}
                />
              </ExpandCollapseClear>
            </div>
          )
        })}
    </div>
  )
}

export default DimensionExplorer
