import { ChangeEvent, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { createPortal } from 'react-dom'

import {
  Announcements,
  DragEndEvent,
  DragMoveEvent,
  DragOverEvent,
  DragOverlay,
  DragStartEvent,
  DropAnimation,
  Modifier,
  UniqueIdentifier,
  defaultDropAnimation,
  useDndContext,
  useDndMonitor
} from '@dnd-kit/core'
import { SortableContext, arrayMove, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'

import { useSessionStorage } from '@uidotdev/usehooks'
import { cn } from '@utils/style-utils'

import Button from '@components/core/button'
import { Input } from '@components/core/input'
import { Separator } from '@components/core/separator'
import { Text } from '@components/core/text'
import { ConfigureTrialBalanceContext } from '@components/financial/gl-mappings/components/working-hierarchy'
import { Icon, Search } from '@components/icons'

import { produce, setAutoFreeze } from 'immer'

import RootDropZone from './components/root-drop-zone'
import { SortableTreeItem } from './components/tree-item/sortable-tree-item'
import { TreeFormPopover } from './components/tree-item/tree-form-popover'
import { sortableTreeKeyboardCoordinates } from './keyboard-coordinates'
import type { FlattenedItem, SensorContext, TreeItem, TreeItems, TreeSource } from './types'
import { ActionTypeEnum, TreeItem as TreeItemType, TreeSourceEnum } from './types'
import useDefaultSensors from './useDefaultSensors'
import {
  buildTree,
  findItemDeep,
  flattenTree,
  getChildCount,
  getProjection,
  isDragEventType,
  removeChildrenOf
} from './utilities'

const dropAnimationConfig: DropAnimation = {
  keyframes({ transform }) {
    return [
      { opacity: 1, transform: CSS.Transform.toString(transform.initial) },
      {
        opacity: 0,
        transform: CSS.Transform.toString({
          ...transform.final,
          x: transform.final.x + 5,
          y: transform.final.y + 5
        })
      }
    ]
  },
  easing: 'ease-out',
  sideEffects({ active }) {
    active.node.animate([{ opacity: 0 }, { opacity: 1 }], {
      duration: defaultDropAnimation.duration,
      easing: defaultDropAnimation.easing
    })
  }
}

interface Props {
  source?: TreeSource
  collapsible?: boolean
  defaultItems?: TreeItems
  indentationWidth?: number
  indicator?: boolean
  editable?: boolean
  showEdit?: boolean
  sortable?: boolean
  onItemClick?: (id: UniqueIdentifier) => void
  onNodeEdit?: (nodeId: number, item: TreeItem) => void
  onNodeCreate?: ({ parentNodeId, item }: { parentNodeId?: number; item: TreeItem }) => void
  onNodeDelete?: (nodeId: number, setErrorMessage?: (message: string) => void) => void
  onDragEnd?: ({
    parentNodeId,
    sortedNodeIds
  }: {
    parentNodeId?: number
    sortedNodeIds: number[]
  }) => void
  selectedNodeId?: UniqueIdentifier
  treeItemSecondaryUtils?: (item: TreeItemType, source: TreeSource | undefined) => any
  persistedCollapsedIdsKey: string
  treeItemClasses?: (item: TreeItemType) => any
  showSearchInput?: boolean
  showConfigureTB?: boolean
  containerClassName?: string
  editOptions?: ('add' | 'edit' | 'delete')[]
  showAddHeadingButton?: boolean
}

export function SortableTree({
  source,
  collapsible,
  editable = false,
  showEdit = true,
  defaultItems = [],
  indicator = false,
  indentationWidth = 25,
  sortable = true,
  onItemClick,
  onNodeEdit,
  onNodeCreate,
  onNodeDelete,
  onDragEnd,
  selectedNodeId,
  treeItemSecondaryUtils,
  persistedCollapsedIdsKey,
  treeItemClasses,
  showSearchInput = false,
  containerClassName,
  editOptions = ['add', 'edit', 'delete'],
  showAddHeadingButton = true
}: Props) {
  const [persistedCollapsedIds, setPersistedCollapsedIds] = useSessionStorage<UniqueIdentifier[]>(
    persistedCollapsedIdsKey,
    []
  )
  const [items, setItems] = useState(defaultItems)
  const collapsedIds = useRef<UniqueIdentifier[]>([])
  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null)
  const [overId, setOverId] = useState<UniqueIdentifier | null>(null)
  const [offsetLeft, setOffsetLeft] = useState(0)
  const [currentPosition, setCurrentPosition] = useState<{
    parentId: UniqueIdentifier | null
    overId: UniqueIdentifier
  } | null>(null)
  const [quickFilter, setQuickFilter] = useState('')

  const handleQuickFilter = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setQuickFilter(event.target.value)
  }, [])

  const { showConfigureTBButton, editingTrialBalance, setEditingTrialBalance } = useContext(
    ConfigureTrialBalanceContext
  )

  const title = () => {
    switch (source) {
      case TreeSourceEnum.Page:
        return 'Initialize Page'
      default:
        return 'Add New Heading'
    }
  }

  const flattenedItems = useMemo(() => {
    const filterItems = (items: TreeItems, filter: string): TreeItems => {
      return items.reduce<TreeItems>((acc, item) => {
        if (item.label.toLowerCase().includes(filter.toLowerCase())) {
          acc.push({
            ...item,
            children: item.children ? [...item.children] : []
          })
        } else {
          const filteredChildren = filterItems(item.children || [], filter)
          if (filteredChildren.length > 0) {
            acc.push({
              ...item,
              children: filteredChildren
            })
          }
        }
        return acc
      }, [])
    }

    const filteredItems = filterItems(items, quickFilter)
    const flattenedTree = flattenTree(filteredItems)

    const collapsedItems = (flattenedTree || []).reduce<UniqueIdentifier[]>(
      (acc, { children, collapsed, id }) => (collapsed && children?.length ? [...acc, id] : acc),
      []
    )
    return removeChildrenOf(
      flattenedTree,
      activeId ? [activeId, ...collapsedItems] : collapsedItems
    )
  }, [activeId, items, quickFilter])

  const projected =
    activeId && overId
      ? getProjection(flattenedItems, activeId, overId, offsetLeft, indentationWidth)
      : null
  const sensorContext: SensorContext = useRef({
    items: flattenedItems,
    offset: offsetLeft
  })
  const [coordinateGetter] = useState(() =>
    sortableTreeKeyboardCoordinates(sensorContext, indicator, indentationWidth)
  )
  const sensors = useDefaultSensors(coordinateGetter)

  const sortedIds = useMemo(() => flattenedItems.map(({ id }) => id), [flattenedItems])
  const activeItem = activeId ? flattenedItems.find(({ id }) => id === activeId) : null

  useEffect(() => {
    sensorContext.current = {
      items: flattenedItems,
      offset: offsetLeft
    }
  }, [flattenedItems, offsetLeft, items])

  useEffect(() => {
    collapsedIds.current.forEach((id: UniqueIdentifier) => {
      const item = findItemDeep(defaultItems, id)
      if (item) {
        item.collapsed = true
      }
    })

    setItems(defaultItems)
  }, [defaultItems])

  useEffect(() => {
    setItems(
      produce((draft) => {
        setAutoFreeze(false)

        persistedCollapsedIds.forEach((id: UniqueIdentifier) => {
          const item = findItemDeep(draft, id)
          if (item) {
            item.collapsed = true
          }
        })
      })
    )
  }, [persistedCollapsedIds])

  useDndMonitor({
    onDragStart(event) {
      if (isDragEventType(event, 'sort')) {
        handleDragStart(event)
      }
    },
    onDragMove(event) {
      if (isDragEventType(event, 'sort')) {
        handleDragMove(event)
      }
    },
    onDragOver(event) {
      if (isDragEventType(event, 'sort')) {
        handleDragOver(event)
      }
    },
    onDragEnd(event) {
      if (isDragEventType(event, 'sort')) {
        handleDragEnd(event)
      }
    },
    onDragCancel(event) {
      if (isDragEventType(event, 'sort')) {
        handleDragCancel()
      }
    }
  })

  const announcements: Announcements = {
    onDragStart({ active }) {
      return `Picked up ${active.id}.`
    },
    onDragMove({ active, over }) {
      return getMovementAnnouncement('onDragMove', active.id, over?.id)
    },
    onDragOver({ active, over }) {
      return getMovementAnnouncement('onDragOver', active.id, over?.id)
    },
    onDragEnd({ active, over }) {
      return getMovementAnnouncement('onDragEnd', active.id, over?.id)
    },
    onDragCancel({ active }) {
      return `Moving was cancelled. ${active.id} was dropped in its original position.`
    }
  }

  const { active } = useDndContext()

  return (
    <div className={cn('flex h-full flex-col', containerClassName)}>
      {active &&
        active?.data?.current?.type !== 'sort' &&
        source === TreeSourceEnum.FinancialReport && <RootDropZone />}
      {(editable || showSearchInput) && (
        <div className='flex flex-col'>
          <div className='flex items-center justify-between pb-2 pt-0'>
            {showSearchInput && (
              <div className='mr-2 flex-1'>
                <Input
                  id='search'
                  className='h-8 w-full flex-1 border-grey-lighter hover:border-grey-light'
                  placeholder='search'
                  leadingIcon={<Icon icon={<Search />} />}
                  onChange={_.debounce(handleQuickFilter, 500)}
                />
              </div>
            )}

            {showConfigureTBButton && (
              <Button
                variant={editingTrialBalance ? 'primary' : 'outline'}
                className='mr-2 h-8 focus:shadow-none'
                onClick={() => {
                  setEditingTrialBalance(!editingTrialBalance)
                }}
              >
                Configure TB
              </Button>
            )}

            {showAddHeadingButton && (
              <TreeFormPopover
                source={source}
                onCreate={onNodeCreate}
                onEdit={onNodeEdit}
                action={ActionTypeEnum.createRootNode}
              >
                <Button variant='outline' className='h-8'>
                  {title()}
                </Button>
              </TreeFormPopover>
            )}
          </div>

          <div className='flex flex-col'>
            <div
              className={cn(
                'transition-all',
                editingTrialBalance ? 'h-12' : 'h-0 opacity-0 blur-sm'
              )}
            >
              <Separator className='my-2 w-full'></Separator>
              <div className='flex items-center justify-end'>
                <Text variant='body' className='mb-2 text-[14px] font-semibold text-black'>
                  Show On Trial Balance
                </Text>
              </div>
            </div>
          </div>
        </div>
      )}

      <SortableContext items={sortedIds} strategy={verticalListSortingStrategy}>
        <div className='flex flex-1 flex-col overflow-y-auto'>
          {flattenedItems.map((item) => {
            const { id, label, children, collapsed, depth } = item
            return (
              <SortableTreeItem
                item={item}
                source={source}
                key={id}
                id={id}
                value={label}
                depth={id === activeId && projected ? projected.depth : depth}
                indentationWidth={indentationWidth}
                identifier={item?.identifier}
                indicator={indicator}
                collapsed={Boolean(collapsed && children?.length)}
                onCollapse={collapsible && children?.length ? () => handleCollapse(id) : undefined}
                editable={editable}
                sortable={sortable}
                showEdit={showEdit}
                onNodeEdit={onNodeEdit}
                onNodeCreate={onNodeCreate}
                onNodeDelete={onNodeDelete}
                onItemClick={onItemClick}
                selectedNodeId={selectedNodeId}
                treeItemSecondaryUtils={treeItemSecondaryUtils}
                treeItemClasses={treeItemClasses}
                sensors={sensors}
                announcements={announcements}
                editOptions={editOptions}
              />
            )
          })}
          {activeId !== null &&
            createPortal(
              <DragOverlay
                dropAnimation={dropAnimationConfig}
                modifiers={indicator ? [adjustTranslate] : undefined}
              >
                {activeId && activeItem ? (
                  <SortableTreeItem
                    item={activeItem}
                    id={activeId}
                    depth={activeItem.depth}
                    clone
                    childCount={getChildCount(items, activeId) + 1}
                    value={activeItem.label}
                    indentationWidth={indentationWidth}
                    onItemClick={onItemClick}
                    selectedNodeId={selectedNodeId}
                    treeItemSecondaryUtils={treeItemSecondaryUtils}
                    treeItemClasses={treeItemClasses}
                  />
                ) : null}
              </DragOverlay>,
              document.body
            )}
        </div>
      </SortableContext>
    </div>
  )

  function handleDragStart({ active: { id: activeId } }: DragStartEvent) {
    setActiveId(activeId)
    setOverId(activeId)

    const activeItem = flattenedItems.find(({ id }) => id === activeId)

    if (activeItem) {
      setCurrentPosition({
        parentId: activeItem.parentId,
        overId: activeId
      })
    }

    document.body.style.setProperty('cursor', 'grabbing')
  }

  function handleDragMove({ delta }: DragMoveEvent) {
    setOffsetLeft(delta.x)
  }

  function handleDragOver({ over }: DragOverEvent) {
    setOverId(over?.id ?? null)
  }

  function handleDragEnd({ active, over }: DragEndEvent) {
    resetState()
    if (projected && over) {
      const { depth, parentId } = projected
      const clonedItems: FlattenedItem[] = JSON.parse(JSON.stringify(flattenTree(items)))
      const overIndex = clonedItems.findIndex(({ id }) => id === over.id)
      const activeIndex = clonedItems.findIndex(({ id }) => id === active.id)
      const activeTreeItem = clonedItems[activeIndex]

      clonedItems[activeIndex] = { ...activeTreeItem, depth, parentId }

      const sortedItems = arrayMove(clonedItems, activeIndex, overIndex)
      const newItems = buildTree(sortedItems)

      const sortedChildrenIds = findItemDeep(newItems, parentId as UniqueIdentifier)?.children.map(
        (item) => item.id
      )
      const rootItemIds = newItems.map((item) => item.id) // if root nodes are moved, sortedChildrenIds would be blank as there would be no parent

      // If onDragEnd query should re-fetch the latest server state of the tree from the API
      if (onDragEnd) {
        onDragEnd({
          parentNodeId: parentId as number,
          sortedNodeIds: (sortedChildrenIds as number[]) || (rootItemIds as number[])
        })
      } else {
        // when the component is used without server state
        setItems(newItems)
      }
    }
  }

  function handleDragCancel() {
    resetState()
  }

  function resetState() {
    setOverId(null)
    setActiveId(null)
    setOffsetLeft(0)
    setCurrentPosition(null)

    document.body.style.setProperty('cursor', '')
  }

  // NOTE: This component is intended to be used with API calls for add, edit and delete
  // on each API call, the latest state of the tree should be re-fetched, the API call implementation will be passed as params
  // In case we need to use the client only implementation  without having API calls, use these methods
  // function handleRemove(id: UniqueIdentifier) {
  //   setItems((items) => removeItem(items, id));
  // }

  // function handleEdit(id: UniqueIdentifier, newValue: string) {
  //   setItems(
  //     produce((draft) => {
  //       setAutoFreeze(false);
  //       let item = findItemDeep(draft, id);

  //       if (item) {
  //         item.label = newValue;
  //       }
  //     })
  //   );
  // }

  // function handleAdd(parentId: UniqueIdentifier, newItem: TreeItem) {
  //   setItems(
  //     produce((draft) => {
  //       setAutoFreeze(false);
  //       let parent = findItemDeep(draft, parentId);

  //       if (parent) {
  //         parent.children = [...parent.children, newItem];
  //       }
  //     })
  //   );
  // }

  function handleCollapse(id: UniqueIdentifier) {
    setItems(
      produce((draft) => {
        setAutoFreeze(false)
        const item = findItemDeep(draft, id)

        if (item) {
          item.collapsed = !item.collapsed
          collapsedIds.current = item.collapsed
            ? [...collapsedIds.current, id]
            : [..._.pull(collapsedIds.current, id)]
          setPersistedCollapsedIds([...collapsedIds.current])
        }
      })
    )
  }

  function getMovementAnnouncement(
    eventName: string,
    activeId: UniqueIdentifier,
    overId?: UniqueIdentifier
  ) {
    if (overId && projected) {
      if (eventName !== 'onDragEnd') {
        if (
          currentPosition &&
          projected.parentId === currentPosition.parentId &&
          overId === currentPosition.overId
        ) {
          return
        } else {
          setCurrentPosition({
            parentId: projected.parentId,
            overId
          })
        }
      }

      const clonedItems: FlattenedItem[] = JSON.parse(JSON.stringify(flattenTree(items)))
      const overIndex = clonedItems.findIndex(({ id }) => id === overId)
      const activeIndex = clonedItems.findIndex(({ id }) => id === activeId)
      const sortedItems = arrayMove(clonedItems, activeIndex, overIndex)

      const previousItem = sortedItems[overIndex - 1]

      let announcement
      const movedVerb = eventName === 'onDragEnd' ? 'dropped' : 'moved'
      const nestedVerb = eventName === 'onDragEnd' ? 'dropped' : 'nested'

      if (!previousItem) {
        const nextItem = sortedItems[overIndex + 1]
        announcement = `${activeId} was ${movedVerb} before ${nextItem.id}.`
      } else {
        if (projected.depth > previousItem.depth) {
          announcement = `${activeId} was ${nestedVerb} under ${previousItem.id}.`
        } else {
          let previousSibling: FlattenedItem | undefined = previousItem
          while (previousSibling && projected.depth < previousSibling.depth) {
            const parentId: UniqueIdentifier | null = previousSibling.parentId
            previousSibling = sortedItems.find(({ id }) => id === parentId)
          }

          if (previousSibling) {
            announcement = `${activeId} was ${movedVerb} after ${previousSibling.id}.`
          }
        }
      }

      return announcement
    }

    return
  }
}

const adjustTranslate: Modifier = ({ transform }) => {
  return {
    ...transform,
    y: transform.y - 25
  }
}
