import React from 'react'

import { Separator } from '@core/separator'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@core/tab'

import AgGridSearchInput from '@components/control-panel/ag-grid-search-input'

import { DataConnectionMode } from '@layout-components/general/data-connections/types'

import { ConnectedSourceSystemList } from './connected-source-system-list'
import { JobSchedules } from './shared/job-schedules'

export const ClientDataConnectionDashboard = () => {
  return (
    <Tabs id='businessSettings' defaultValue='dataMovement'>
      <div className='flex w-full justify-between pb-1'>
        <TabsList className='grid w-96 grid-cols-2 gap-2'>
          <TabsTrigger value='dataMovement'>Data Movements</TabsTrigger>
          <TabsTrigger value='jobSchedules'>Job Schedules</TabsTrigger>
        </TabsList>
      </div>
      <div className='w-full'>
        <Separator className='my-2' />
        <TabsContent value='dataMovement'>
          <div className='flex flex-col gap-2'>
            <div className='flex min-h-11 w-full items-center space-x-2'>
              <AgGridSearchInput />
            </div>
            <Separator className='w-full' />
            <ConnectedSourceSystemList />
          </div>
        </TabsContent>
        <TabsContent value='jobSchedules'>
          <JobSchedules mode={DataConnectionMode.CLIENT} />
        </TabsContent>
      </div>
    </Tabs>
  )
}
