import { useEffect, useState } from 'react'

import { ICellRendererParams } from 'ag-grid-community'

import LineWrapToggle from '@core/custom/line-wrap-toggle'
import ResizablePopover from '@core/custom/resizable-popover'

import { SQLEditor } from '@components/code-editors'

import { LogGroup, logError } from '@store/actions/log'
import { useDispatch } from '@store/index'

import { format as formatSql } from 'sql-formatter'

interface SQLCellRendererProps extends ICellRendererParams {
  title?: string
}

export const SQLCellRenderer = (params: SQLCellRendererProps) => {
  const [query, setQuery] = useState<string>('')
  const [enableLineWrapping, setEnableLineWrapping] = useState(false)

  const dispatch = useDispatch()

  useEffect(() => {
    try {
      const formattedSQL = formatSql(params.value || '', { language: 'postgresql' })
      setQuery(formattedSQL)
    } catch (error) {
      dispatch(
        logError({
          group: LogGroup.SQL_CELL_RENDERER,
          message: 'Error formatting SQL query',
          tags: {
            type: 'FORMAT_SQL',
            error: (error as Error)?.message || 'Unknown error',
            query: params.value
          }
        })
      )
      setQuery(params.value)
    }
  }, [params.value, dispatch])

  const toggleLineWrapping = () => {
    setEnableLineWrapping((prevState) => !prevState)
  }

  const headerTools = (
    <LineWrapToggle isEnabled={enableLineWrapping} onToggle={toggleLineWrapping} />
  )

  return (
    <ResizablePopover
      headerTools={headerTools}
      title={params.title || 'Python Code'}
      content={<SQLEditor value={query} readOnly enableLineWrapping={enableLineWrapping} />}
    >
      {query}
    </ResizablePopover>
  )
}
