import { ColDef } from 'ag-grid-community'

import AgGrid from '@components/ag-grid'

export interface PendingStreamRow {
  streamName: string
}

const PendingStreams = ({
  streamStatusesId,
  pendingStreamsData,
  gridWidth = '10%'
}: {
  streamStatusesId?: string | null
  pendingStreamsData: PendingStreamRow[]
  gridWidth: string
}) => {
  const pendingStreamsColumnDefs: ColDef[] = [
    {
      field: 'streamName',
      headerName: `Pending Streams ${streamStatusesId ? `(SSID: ${streamStatusesId})` : ''}`
    }
  ]

  return (
    <AgGrid
      className='ml-3'
      style={{ height: '100%', width: gridWidth }}
      rowData={pendingStreamsData}
      columnDefs={pendingStreamsColumnDefs}
      defaultColDef={{
        resizable: true,
        sortable: true,
        filter: true
      }}
      autoSizeStrategy={{ type: 'fitCellContents' }}
    />
  )
}

export default PendingStreams
