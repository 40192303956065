'use client'

import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import * as TabsPrimitive from '@radix-ui/react-tabs'
import { cn } from '@utils/style-utils'

import { RootState } from 'store/index'
import { initializeTab, selectActiveTab, setSelectedTab } from 'store/slices/active-tab'

interface TabsProps extends React.ComponentPropsWithoutRef<typeof TabsPrimitive.Root> {
  id: string
  defaultValue?: string
  orientation?: 'horizontal' | 'vertical'
}

const Tabs: React.FC<TabsProps> = ({
  id,
  defaultValue,
  orientation = 'horizontal',
  children,
  ...props
}) => {
  const dispatch = useDispatch()

  let firstTabValue: string | undefined = defaultValue

  React.Children.forEach(children, (child) => {
    if (!firstTabValue && React.isValidElement(child) && typeof child.props.value === 'string') {
      firstTabValue = child.props.value
    }
  })

  React.useEffect(() => {
    dispatch(initializeTab({ id, defaultValue: firstTabValue || '' }))
  }, [dispatch, id, firstTabValue])

  const selectedTab = useSelector((state: RootState) => selectActiveTab(state, id))

  const handleValueChange = (value: string) => {
    if (value !== selectedTab) {
      dispatch(setSelectedTab({ id, tab: value }))
    }
  }

  return (
    <TabsPrimitive.Root
      value={selectedTab || defaultValue}
      onValueChange={handleValueChange}
      {...props}
    >
      {React.Children.map(children, (child) =>
        React.isValidElement(child) && child.type === TabsList
          ? // @ts-ignore
            React.cloneElement(child, { orientation })
          : child
      )}
    </TabsPrimitive.Root>
  )
}

interface TabsListProps extends React.ComponentPropsWithoutRef<typeof TabsPrimitive.List> {
  orientation?: 'horizontal' | 'vertical'
}

const TabsList = React.forwardRef<React.ElementRef<typeof TabsPrimitive.List>, TabsListProps>(
  ({ orientation = 'horizontal', className, style, ...props }, ref) => {
    const newClassName = cn(
      'twp',
      orientation === 'horizontal'
        ? 'inline-flex h-6 items-center justify-center rounded-lg bg-grey-background py-px'
        : 'flex flex-col items-start justify-start space-y-2 w-1/4',
      className
    )

    const newStyle = { height: orientation === 'horizontal' ? 'auto' : 'auto', ...style }

    return <TabsPrimitive.List ref={ref} className={newClassName} {...props} style={newStyle} />
  }
)
TabsList.displayName = TabsPrimitive.List.displayName

const TabsTrigger = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Trigger>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.Trigger
    ref={ref}
    className={cn(
      // Base styles
      'twp inline-flex items-center justify-center whitespace-nowrap',
      'rounded-md border-solid px-4 py-1 font-medium',

      // State styles
      'transition-all',
      'disabled:pointer-events-none disabled:opacity-50',

      // Active state
      'data-[state=active]:border',
      'data-[state=active]:border-grey',
      'data-[state=active]:bg-white',
      'data-[state=active]:shadow-[2px_2px_0px_0px_rgba(0,0,0,0.03)]',

      // Focus styles
      'focus:outline-none focus-visible:ring-2 focus-visible:ring-primary focus-visible:ring-offset-2',

      className
    )}
    {...props}
  />
))
TabsTrigger.displayName = TabsPrimitive.Trigger.displayName

const TabsContent = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Content>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.Content
    ref={ref}
    className={cn(
      'twp focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2',
      className
    )}
    {...props}
  />
))
TabsContent.displayName = TabsPrimitive.Content.displayName

export { Tabs, TabsList, TabsTrigger, TabsContent }
